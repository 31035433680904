import TextBase from "../../common/text";
import {Colors} from "../../themes/Colors";
import {convertPxToRem} from "../../helper";
import { CheckCircleOutlined } from '@ant-design/icons'
import withNavigate from "../../component/WithNavigate";
import {Outlet} from "react-router-dom";
import React, {Component} from "react";
import {SVGImageComponentReact} from "../../themes/Images";

class StrippedLayout extends Component {

    render() {
        return (
            <section className='FlexContainer'>
                {/*<div>*/}
                {/*    <SVGImageComponentReact.LogoMove/>*/}
                {/*</div>*/}
                <div style={{marginTop: 50}}>
                    <CheckCircleOutlined style={{
                        fontSize: 65,
                        color: Colors.open_order
                    }} />
                </div>
                <div style={{marginTop: 20}}>
                    <TextBase
                        fontWeight={500}
                        size={convertPxToRem(30)}
                        color={Colors.grey}>
                        Email Successfully Verified
                    </TextBase>
                </div>
            </section>
        )
    }
}



export default (withNavigate(StrippedLayout))
