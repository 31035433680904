import { vendorAPIService } from '../services/vendorsAPIService';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGenerateDealerCode = (onSuccess) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => vendorAPIService.generateDealerCode(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);
      }
    }
  );

  return {
    generateDealerCode: mutateAsync,
    isGenerating: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};

export const useLockDealerCode = (onSuccess) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => vendorAPIService.lockDealerCode(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);
      }
    }
  );

  return {
    lockDealerCode: mutateAsync,
    isLockingCode: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};
