import React from 'react';
import { connect } from 'react-redux';
import { ROUTER_NAME } from '../../router/routers';
import LoginInput from '../../common/login-input';
import BasePage from '../base';

import {
  convertPxToRem,
  getValueFromObjectByKeys,
  MESSAGE_TYPE,
  messageManager
} from '../../helper';
import { UserAction } from '../../redux/action';
import { animate } from '../../utils/defined';
import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import ButtonGradient from '../../common/button-gradient';
import { motion } from 'framer-motion';
import { Col, Row } from 'antd';
import { css, StyleSheet } from 'aphrodite';
import { SVGImageComponentReact } from '../../themes/Images';
import InputError from '../../common/input-error/InputError.component';
import './resetPassword.style.css';
import LocalStorage from '../../defined/localStorage';
import WithRouter from '../../component/withRouter';
import WithNavigate from '../../component/WithNavigate';

const userFake = {
  username: 'admin',
  password: 'admin'
};

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20,
    marginTop: 20
  }
});

class ResetPassword extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      isLogin: false,
      sending: false,
      errors: {},
      showConfirmPassword: false
    };
  }

  componentDidMount() {
    const { token } = this.props;

    LocalStorage.setItem(LocalStorage.DEFINE_KEY.TMP_ACCESS_TOKEN, token);
  }

  onChangePassword = (value) => {
    this.setState({ password: value });
  };

  onConfirmPassword = (value) => {
    this.setState({ confirmPassword: value });
  };

  handlerResetPassword = async (event) => {
    const errors = this.state.errors;

    try {
      const { navigate, saveUserInfo, userInfo, setNewPassword, token } = this.props;

      const formData = {
        new_password1: this.state.password,
        new_password2: this.state.confirmPassword
      };
      const resReset = await setNewPassword(formData, token);

      this.setState({
        password: '',
        confirmPassword: '',
        errors: {}
      });

      LocalStorage.clear();

      messageManager({
        type: MESSAGE_TYPE.success,
        messageShow:
          getValueFromObjectByKeys(resReset, ['data', 'detail']) || 'New password has been set!.'
      });
      navigate(ROUTER_NAME.login);
    } catch (errors) {
      if (errors.hasOwnProperty('new_password1') || errors.hasOwnProperty('new_password2')) {
        this.setState({ errors });
      }
    }
  };

  renderContent() {
    const {
      email,
      password,
      confirmPassword,
      isLogin,
      loging,
      errors,
      sending,
      showPassword,
      showConfirmPassword
    } = this.state;

    const IconEyePassword = showPassword
      ? SVGImageComponentReact.IconEyeOffPassword
      : SVGImageComponentReact.IconEyePassword;

    const IconEyePasswordConfirm = showConfirmPassword
      ? SVGImageComponentReact.IconEyeOffPassword
      : SVGImageComponentReact.IconEyePassword;
    const { navigate } = this.props;
    return (
      <motion.div
        initial={animate.initial}
        animate={animate.animate}
        transition={animate.transition}>
        <div className={css(styles.flex)}>
          <div></div>
        </div>
        <div
          className="max-width-100"
          style={{
            paddingTop: convertPxToRem(100),
            paddingInline: convertPxToRem(20),
            paddingBottom: convertPxToRem(20)
          }}>
          <TextBase
            style={{ marginBottom: convertPxToRem(11) }}
            fontWeight={600}
            size={convertPxToRem(32)}
            color={Colors.black}>
            newPassword
          </TextBase>

          <div className="form-wrapper">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 40 }}>
              <Col span={24} className="row-margin">
                <div>
                  <TextBase
                    style={{ textAlign: 'left' }}
                    size={convertPxToRem(16)}
                    color={Colors.black}>
                    password
                  </TextBase>
                  <LoginInput
                    value={password}
                    onChange={this.onChangePassword}
                    placeholder="enterPassword"
                    type={showPassword ? 'text' : 'password'}
                    rightElement={
                      <IconEyePassword
                        className="cursor-pointer"
                        onClick={() => {
                          this.setState({ showPassword: !showPassword });
                        }}
                      />
                    }
                  />
                  <InputError error={errors.new_password1} />
                </div>
              </Col>

              {/* Confirm password */}
              <Col span={24} className="row-margin">
                <div className="max-width-100">
                  <TextBase
                    className="no-select"
                    style={{ textAlign: 'left' }}
                    size={convertPxToRem(16)}
                    color={Colors.black}>
                    confirmPassword
                  </TextBase>
                  <LoginInput
                    value={confirmPassword}
                    onChange={this.onConfirmPassword}
                    onKeyUp={this.keyUp}
                    placeholder="enterPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    rightElement={
                      <IconEyePasswordConfirm
                        className="cursor-pointer"
                        onClick={() => {
                          this.setState({ showConfirmPassword: !showConfirmPassword });
                        }}
                      />
                    }
                  />
                  <InputError error={errors.new_password2} />
                  <TextBase
                    style={{ marginTop: 30 }}
                    size={convertPxToRem(14)}
                    color={Colors.grey}
                    className="reset-password-format no-select">
                    passwordConstraint
                  </TextBase>
                </div>
              </Col>
            </Row>
          </div>
          <div
            style={{ marginBottom: 19, marginTop: 50 }}
            className="flex-center-all max-width-100">
            <ButtonGradient
              onClick={this.handlerResetPassword}
              textStyle={{ fontWeight: 700 }}
              title="save"
              loading={sending}
            />
          </div>
        </div>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo'])
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (params) => {
      return dispatch(UserAction.login(params));
    },
    setNewPassword: (params) => {
      return dispatch(UserAction.setNewPassword(params));
    },
    saveUserInfo: (data) => {
      return dispatch(UserAction.saveUserInfo(data));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithNavigate(WithRouter(ResetPassword)));
