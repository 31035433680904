import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import firebase from 'firebase/compat/app'

export const firebaseConfig = {
    apiKey: 'AIzaSyCnam8vKbPf9SHGEnfl_TfuK5TeTt-Ifwk',
    authDomain: 'move2orderp2.firebaseapp.com',
    projectId: 'move2orderp2',
    storageBucket: 'move2orderp2.appspot.com',
    messagingSenderId: '788831950489',
    appId: '1:788831950489:web:b78483896d8c10fa6a4a08',
    measurementId: 'G-5KK654V8MF',
    vapidKey:
        'BDKO2yo93v2e5jCDKonWLrz942l0GuyHJRSmmit8d0kJ3X5pdD_jXzj-JdfJ8zk7SI9CgsxOGBBGPjiEyvMJPPQ'
}
firebase.initializeApp(firebaseConfig);

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth(app)
export const collectionKeys = {
    notifications: 'notifications',
    quoteMessage: 'quote-message'
}

export const messaging = getMessaging(app)

export const getFirebaseToken = () =>
    getToken(messaging, {
        vapidKey: firebaseConfig.vapidKey
    })

// export const onForegroundMessage = onMessage
export const onForegroundMessage = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })
