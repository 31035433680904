import React from "react";

const FormBase = (props) => {
  const { children, onSubmit } = props;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit && onSubmit();
      }}
    >
      {children}
      <input className="input-submit-hide" type="submit" value="Submit" />
    </form>
  );
};
export default FormBase;
