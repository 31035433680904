import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    email: '',
    token: null,
    data: {}
};

const authmodelSlice = createSlice({
    name: 'authmodel',
    initialState,
    reducers: {
        signin: (state, action) => {
            const { data } = action.payload;
            state.data = data;
        },
        logoutReducer: (state) => {
            state.data = {};
        }
    }
});
export const { signin, logoutReducer } = authmodelSlice.actions;

export default authmodelSlice.reducer;
