import React from 'react';
import { css } from 'aphrodite';

import TextBase from '../text';
import { convertPxToRem } from '../../helper';
import { Colors } from '../../themes/Colors';

import VisaIcon from '../../assets/image/svg/payment/visa.svg';
import AmexIcon from '../../assets/image/svg/payment/amex.svg';
import DiscoverIcon from '../../assets/image/svg/payment/discover.svg';
import MastercardIcon from '../../assets/image/svg/payment/mastercard.svg';

export const CreditCard = ({ values = {}, selected = false }) => {
  const Icon =
    values?.brand === 'Visa'
      ? VisaIcon
      : values?.brand === 'MasterCard'
      ? MastercardIcon
      : values?.brand === 'American Express'
      ? AmexIcon
      : values?.brand === 'Discover'
      ? DiscoverIcon
      : null;
  return (
    <div
      style={{
        width: '100%',
        minHeight: '120px',
        borderRadius: '20px',
        padding: '20px 32px',
        // display: 'flex',
        // backgroundColor: '#161FFB',
        background: 'linear-gradient(225deg, #168DFB 0%, #161FFB 100%)',
        marginBottom: '20px',
        border: selected ? `4px solid ${Colors.primary}` : ''
      }}
      className="show-cursor-on-hover">
      {values?.brand?.length ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
          <img src={Icon} style={{ height: '30px' }} />
        </div>
      ) : null}
      <div>
        <TextBase
          style={{ marginBottom: '16px' }}
          fontWeight={600}
          size={convertPxToRem(24)}
          color={Colors.white}>
          {values?.number || '---- ---- ---- ---- ----'}
        </TextBase>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ marginRight: '20px' }}>
          <TextBase
            style={{ marginBottom: '6px' }}
            fontWeight={400}
            size={convertPxToRem(12)}
            color={Colors.white}>
            cardHolderName
          </TextBase>

          <TextBase
            style={{ marginBottom: '6px' }}
            fontWeight={600}
            size={convertPxToRem(16)}
            color={Colors.white}>
            {values?.card_holder || '---- ----'}
          </TextBase>
        </div>

        <div>
          <TextBase
            style={{ marginBottom: '6px' }}
            fontWeight={400}
            size={convertPxToRem(12)}
            color={Colors.white}>
            expiryDate
          </TextBase>

          <TextBase
            style={{ marginBottom: '6px' }}
            fontWeight={600}
            size={convertPxToRem(16)}
            color={Colors.white}>
            {values?.month_exp ? `${values?.month_exp}/${values?.year_exp}` : '--/--'}{' '}
          </TextBase>
        </div>
      </div>
    </div>
  );
};
