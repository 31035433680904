import { useEffect, useState } from 'react';
import { getFirebaseToken, onForegroundMessage } from '../firebase';
import { MESSAGE_TYPE, messageManager } from '../helper';
import { useDispatch } from 'react-redux';
import { UserAction } from '../redux/action';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { messaging } from '../firebase';

const PushNotifications = () => {
  // Firebase
  const isSupported = 'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window
  const [showNotificationBanner, setShowNotificationBanner] = useState(
    isSupported ? Notification.permission === 'default' : false
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // if (isSupported) {
    //   // Register the service worker
    //   navigator.serviceWorker.register('/firebase-messaging-sw.js')
    //     .then((registration) => {
    //       
    //     })
    //     .catch((error) => {
    //       console.error('Service Worker registration failed:', error);
    //     });
    // }

    onMessage(messaging, (payload) => {
      // 
      const {
        notification: { title, body }
      } = payload;
      messageManager({
        type: MESSAGE_TYPE.info,
        messageShow: title
      });
    });

    onForegroundMessage().then(payload => {
      // 
      const {
        notification: { title, body }
      } = payload;
      messageManager({
        type: MESSAGE_TYPE.info,
        messageShow: title
      });
    }).catch(err => {
      
    }
    );

  }, []);

  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', (event) => {
      // 

      const { title, body } = event.data.notification;

      messageManager({
        type: MESSAGE_TYPE.info,
        messageShow: title
      });

      // Show the notification using your preferred UI library or custom implementation
      // You can also handle different actions based on the notification data
    });
  }, []);

  useEffect(() => {
    handleGetFirebaseToken();
  }, []);

  const handleGetFirebaseToken = async () => {
    // 
    // 
    // getFirebaseToken()
    //   .then((firebaseToken) => {
    //     
    //     if (firebaseToken) {
    //       
    //       setShowNotificationBanner(true);
    //       registerDevice(firebaseToken);
    //     }
    //   })
    //   .catch((err) => console.error('An error occured while retrieving firebase token. ', err));

    try {
      const firebaseToken = await getFirebaseToken();
      // 
      if (firebaseToken) {
        // 
        setShowNotificationBanner(true);
        registerDevice(firebaseToken);
      }
    } catch (error) {
      
      if (error.code === 'messaging/permission-blocked') {
        setShowNotificationBanner(false);
      } else {
        // console.error('An error occurred while retrieving firebase token. ', error);
      }
    }

  };

  const registerDevice = (token) => {
    const formData = {
      registration_id: token,
      type: 'web'
    };
    // 
    dispatch(UserAction.registerDevice(formData));
  };
};

export default PushNotifications;
