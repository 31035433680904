import { request } from "../utils/request";
import {$axios, $axiosForm} from "../../../utils/axios.instance"


function getOrders(payload) {
  let url = `orders/?user__id=${payload?.user__id}&ordering=${payload?.ordering}`
  if (payload?.search) {
    url = url + `&search=${payload?.search}`
  }

  if (payload?.status) {
    url = url + `&quotes__status=${payload?.status}`
  }
  if (payload?.limit) {
    url = url + `&limit=${payload?.limit}`
  }
  if(payload?.page_size) {
    url = url + `&page_size=${payload?.page_size}`
  }
  if(payload?.page) {
    url = url + `&page=${payload?.page}`
  }
  return $axios
    .get(url)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      
      throw error
    })
}

function getOrder(payload) {
  return $axios
    .get(`orders/${payload.id}/`)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      
      throw error
    })
}

function createOrder(payload) {
  return $axiosForm
    .post(`orders/`, payload)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      
      throw error
    })
}

function updateOrder(payload) {
  return $axiosForm
    .patch(`orders-update/${payload.id}/`, payload.formData)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      
      throw error
    })
}

function deleteOrder(payload) {
  return $axios
    .delete(`orders/${payload.id}/`)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      
      throw error
    })
}
function deleteOrderImage(payload) {
  
  return $axios
    .delete(`order-delete-photos/${payload.id}/`, {
      data: { images: payload?.images }
    })
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      
      throw error
    })
}

function deleteQuote(payload) {
  return $axios
    .delete(`quotes/${payload.id}/`)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      
      throw error
    })
}

function getAttributes() {
  return $axios
    .get(`white-labels/create-order-form/`)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      
      throw error
    })
}

function getQuote(id) {
  return $axios
    .get(`quotes/${id}/`)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      
      throw error
    })
}

export const ordersAPIService = {
  getOrders,
  getOrder,
  updateOrder,
  createOrder,
  deleteOrder,
  deleteOrderImage,
  getOrders,
  deleteQuote,
  getAttributes,
  getQuote
}
