import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: [],
    error: [],
    total: 0,
}

const ordersSlice = createSlice({
    name: 'ordersList',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successOrdersReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
            state.total = action.payload.total;
        },
        failedOrdersReducer: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { successOrdersReducer, failedOrdersReducer, loaderListener } = ordersSlice.actions;

export default ordersSlice.reducer;