import { useMutation, useQuery } from '@tanstack/react-query';
import { paymentAPIService } from '../services/paymentAPIService';

export const useGetConnectedAccounts = (onError = () => {}, queryClient, enabled = true) => {
  const { data, isLoading, error, refetch, isFetched } = useQuery(
    ['useGetConnectedAccounts'],
    () => paymentAPIService.getConnectedAccount(),
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: enabled,
      keepPreviousData: false,
      retry: 1,
      onError: (e) => {
        onError();
        queryClient.setQueryData(['useGetConnectedAccounts'], null);
      }
    }
  );

  return {
    connectedAccount: data,
    isFetched,
    isLoadingConnectedAccount: isLoading,
    refetch,
    error
  };
};

export const useConnectStripe = (onSuccess = () => {}, onError = () => {}) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => paymentAPIService.connectStripe(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);
        onError(e);
      }
    }
  );

  return {
    connectStripe: mutateAsync,
    isConnecting: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};

export const useDisconnectStripe = (onSuccess = () => {}, onError = () => {}) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    () => paymentAPIService.disconnectStripe(),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);
        onError(e);
      }
    }
  );

  return {
    disconnectStripe: mutateAsync,
    isDisconnecting: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};

export const useGetPaymentMethods = (payload) => {
  const { data, isLoading, error, refetch, isFetched } = useQuery(
    ['useGetPaymentMethods'],
    () => paymentAPIService.getPaymentMethods(payload),
    {
      staleTime: 1000,
      cacheTime: 0
    },
    {
      onError: (e) => {
        console.error(e);
      },
      refetchInterval: 5000
    }
  );

  return {
    paymentMethods: data,
    isLoading,
    isFetched,
    refetchPaymentMethods: refetch,
    error
  };
};

export const useGetPaymentMethod = (payload) => {
  const { data, isLoading, error, refetch } = useQuery(
    ['useGetPaymentMethod'],
    () => paymentAPIService.getPaymentMethod(payload),
    {
      staleTime: 1000,
      cacheTime: 0
    },
    {
      onError: (e) => {
        console.error(e);
      },
      refetchInterval: 5000
    }
  );

  return {
    paymentMethod: data,
    isLoading,
    refetchPaymentMethod: refetch,
    error
  };
};

export const useAddPaymentMethod = (onSuccess) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => paymentAPIService.addPaymentMethod(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);
      }
    }
  );

  return {
    addPaymentMethod: mutateAsync,
    isAddingPaymentMethod: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};

export const useUpdatePaymentMethod = (onSuccess) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => paymentAPIService.updatePaymentMethod(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);
      }
    }
  );

  return {
    updatePaymentMethod: mutateAsync,
    isUpdatingPaymentMethod: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};

export const useDeletePaymentMethod = (onSuccess) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => paymentAPIService.deletePaymentMethod(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);
      }
    }
  );

  return {
    deletePaymentMethod: mutateAsync,
    isDeletingPaymentMethod: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};
