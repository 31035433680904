import React, { useEffect, useState } from 'react';
import { TextField, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const TextFieldContainer = styled('div')(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    'height': '50px !important'
  }
}));

const Search = ({ placeholder, setQuery, value = '', sx = {} }) => {
  const [searchQuery, setSearchQuery] = useState(value);
  const [searchTimeout, setSearchTimeout] = useState(null);

  useEffect(() => {
    setSearchQuery(value);
  }, [value]);

  //debounce : search after 500ms of typing
  const handleSearchChange = (e) => {
    clearInterval(searchTimeout);
    if (e.target.value.trimStart().length > 0) {
      setSearchTimeout(
        setTimeout(() => {
          setQuery(e.target.value.trimStart());
        }, 500)
      );
    }
    if (!e.target.value) {
      setSearchTimeout(
        setTimeout(() => {
          setQuery(e.target.value.trimStart());
        }, 500)
      );
    }
    setSearchQuery(e.target.value.trimStart());
  };

  return (
    <TextFieldContainer>
      <TextField
        style={{ background: 'white'}}
        size="small"
        placeholder={placeholder}
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: <SearchIcon />,
        }}
        sx={sx}
      />
    </TextFieldContainer>
  );
};

export default Search;
