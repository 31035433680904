/* eslint-disable no-shadow */
import { REQUEST_SUBTYPE } from "./ActionType";
import { myLog } from "../../helper";

const { REQUEST, SUCCESS, ERROR } = REQUEST_SUBTYPE;
let timeoutCloseLoading = null;

const clearTimeoutLoading = () => {
  if (timeoutCloseLoading) {
    clearTimeout(timeoutCloseLoading);
    timeoutCloseLoading = null;
  }
};
const setTimeoutCloseLoading = () => {
  clearTimeoutLoading();
  timeoutCloseLoading = setTimeout(() => {
    myLog("setTimeoutCloseLoading=>");
  }, 30000);
};

export const getAction = ({
  Actions = null,
  actionName = "",
  data = {},
  onAfter = (data) => {
    return data;
  },
  onBefore = (data) => {
    return data;
  },
}) => {
  if (Actions === null || actionName === "") {
    return null;
  }
  return async (dispatch, getState) => {
    onBefore && onBefore(dispatch, getState, data);
    dispatch(Actions[actionName](data));
    onAfter && onAfter(dispatch, getState, data);
  };
};

export const getActionAPI = ({
  Actions = null,
  visibleSpin = false,
  actionName = "",
  delayTime = 100,
  promiseApi = async () => {},
  arg = {},
  dispatchRedux = true,
  dispatchRequest = true,
  isTouch = false,
  isToast = false,
  handlerAfterDismissPopupMessage = () => {},
  onBeforeRequest = (arg) => {
    return arg;
  },
  onAfterRequest = (arg) => {
    return arg;
  },
  dispatchSuccess = true,
  onBeforeSuccess = (data) => {
    return data;
  },
  onAfterSuccess = (data) => {
    return data;
  },
  dispatchError = true,
  onBeforeError = (data) => {
    return data;
  },
  onAfterError = (data) => {
    return data;
  },
}) => {
  if (Actions === null || actionName === "") {
    return null;
  }
  return async (dispatch, getState) => {
    if (visibleSpin) {
      setTimeoutCloseLoading();
    }
    // TcoN.D-Request
    if (onBeforeRequest) {
      let argTmp = onBeforeRequest(dispatch, getState, {
        ...arg,
      });
      if (typeof argTmp === "object") {
        arg = argTmp;
      }
    }
    dispatchRedux &&
      dispatchRequest &&
      dispatch(
        Actions[actionName + REQUEST]({
          ...arg,
          isTouch,
          isToast,
        })
      );
    onAfterRequest && onAfterRequest(dispatch, getState, { ...arg });
    // TcoN.D-Request
    try {
      // TcoN.D-Success
      onBeforeSuccess && onBeforeSuccess(dispatch, getState);
      const data = await promiseApi()||{};
      if (arg) {
        data.tmpArg = arg;
      }
      myLog("onAfterSuccess2=>", handlerAfterDismissPopupMessage);
      dispatchRedux &&
        dispatchSuccess &&
        dispatch(
          Actions[actionName + SUCCESS]({
            ...data,
            isTouch,
            handlerAfterDismissPopupMessage,
          })
        );
      myLog("onAfterSuccess=>", onAfterSuccess);
      onAfterSuccess &&
        setTimeout(() => onAfterSuccess(dispatch, getState, data));
      // TcoN.D-Success
      if (visibleSpin) {
        clearTimeoutLoading();
        const funcCloseSpin = () => {
          handlerCSS && clearTimeout(handlerCSS);
        };
        let handlerCSS = setTimeout(funcCloseSpin, delayTime);
      }
      myLog("GetActionAPI", data);
      return data;
    } catch (err) {
      // TcoN.D-Error
      onBeforeError && onBeforeError(dispatch, getState);
      dispatchRedux &&
        dispatchError &&
        dispatch(
          Actions[actionName + ERROR]({
            ...err,
            isTouch,
            isToast,
            handlerAfterDismissPopupMessage,
          })
        );
      onAfterError && onAfterError(dispatch, getState, err);
      if (visibleSpin) {
        clearTimeoutLoading();
        const funcCloseSpin = () => {
          handlerCES && clearTimeout(handlerCES);
        };
        let handlerCES = setTimeout(funcCloseSpin, delayTime);
      }
      // TcoN.D-Error
      throw err;
    }
  };
};
