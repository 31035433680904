import React from 'react';
import { Input } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MESSAGE_TYPE, convertPxToRem, messageManager } from '../../helper';
import { Colors } from '../../themes/Colors';
import TextBase from '../text';
import CopyIcon from '../../assets/image/svg/copy.svg';
import RefreshIcon from '../../assets/image/svg/refresh.svg';
import { SVGImageComponentReact } from '../../themes/Images';
import ButtonGradient from '../button-gradient';

import './index.css';

export const DealerCopyCode = ({ value = '', btnText = 'Copy', onClick = () => {} }) => {
  const isCopy = btnText === 'Copy'||btnText === 'copy';

  return (
    <div
      style={{
        border: `1px solid${isCopy ? Colors.success : Colors.grey3}`,
        display: 'flex',
        borderRadius: '8px'
      }}>
      <Input
        value={value}
        style={{
          borderWidth: 0,
          textAlign: 'center',
          color: isCopy ? Colors.success : Colors.black,
          fontWeight: 600,
          height: '50px !important',
          fontSize: '24px !important'
        }}
        className={isCopy ? 'copy-dealer-code-input' : ''}
        disabled
      />

      {isCopy ? (
        <CopyToClipboard
          text={value}
          onCopy={() => {
            messageManager({
              messageShow: 'Copied',
              type: MESSAGE_TYPE.success
            });
          }}>
          <div
            className="dealer-code-copy-button"
            style={{
              width: '120px',
              backgroundColor: Colors.success,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '0px 8px 8px 0px',
              height: '50px',
              '&:hover': { cursor: 'pointer' }
            }}>
            <img src={isCopy ? CopyIcon : RefreshIcon} style={{ height: '24px', width: '24px' }} />

            <TextBase
              style={{ textAlign: 'center', marginLeft: '10px' }}
              fontWeight={600}
              size={convertPxToRem(16)}
              color={Colors.white}>
              {btnText}{' '}
            </TextBase>
          </div>
        </CopyToClipboard>
      ) : (
        <ButtonGradient
          onClick={onClick}
          icon={isCopy ? SVGImageComponentReact.CopyIcon : SVGImageComponentReact.RefreshIcon}
          textStyle={{ fontWeight: 600, fontSize: 18, margin: 0 }}
          iconStyle={{ marginRight: '5px' }}
          title={btnText}
          iconSize={24}
          backgroundColor={isCopy ? Colors.success : Colors.primary}
          style={{
            width: isCopy ? '150px' : '220px',
            borderRadius: '0px 6px 6px 0px',
            padding: '0px 10px'
          }}
          loading={false}
        />
      )}
    </div>
  );
};
