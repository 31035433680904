import backgroundApp from '../assets/image/background-app.png';
import { ReactComponent as IconNotification } from '../assets/image/svg/notification-icon.svg';
import { ReactComponent as IconUser } from '../assets/image/svg/icon_user.svg';
import { ReactComponent as IconLogout } from '../assets/image/svg/icon_logout.svg';
import { ReactComponent as IconApp } from '../assets/image/logo.svg';
import { ReactComponent as IconRightArrow } from '../assets/image/svg/right_arrow.svg';
import { ReactComponent as IconUpArrow } from '../assets/image/svg/up_arrow.svg';
import { ReactComponent as IconDownArrow } from '../assets/image/svg/down_arrow.svg';
import { ReactComponent as IconChecked } from '../assets/image/svg/checked.svg';
import { ReactComponent as IconLeftArrow } from '../assets/image/svg/left_arrow.svg';
import { ReactComponent as IconPlusYellow } from '../assets/image/svg/icon_plus.svg';
import { ReactComponent as IconRadioUnCheck } from '../assets/image/svg/icon_radio_uncheck.svg';
import { ReactComponent as IconRadioChecked } from '../assets/image/svg/icon_radio_checked.svg';
import { ReactComponent as IconUpload } from '../assets/image/svg/icon_upload.svg';
import { ReactComponent as IconDownload } from '../assets/image/svg/icon_download.svg';
import { ReactComponent as IconDelete } from '../assets/image/svg/icon_delete.svg';
import { ReactComponent as IconStepDone } from '../assets/image/svg/step_done.svg';
import { ReactComponent as IconDateSuffix } from '../assets/image/svg/icon_date_suffix.svg';
import { ReactComponent as IconPlay } from '../assets/image/svg/iconPlay.svg';
import { ReactComponent as LogoAppWhite } from '../assets/image/logo.svg';
import { ReactComponent as IconEdit } from '../assets/image/svg/icon_edit.svg';
import { ReactComponent as IconDot } from '../assets/image/svg/dot.svg';
import { ReactComponent as IconCurrentPlan } from '../assets/image/svg/icon_current_plan.svg';
import { ReactComponent as LogoMove } from '../assets/image/svg/logo_move.svg';
import { ReactComponent as CheckBox } from '../assets/image/svg/check_box.svg';
import { ReactComponent as CheckBoxUncheck } from '../assets/image/svg/check_box_uncheck.svg';
import { ReactComponent as IconDashBoard } from '../assets/image/svg/icon_dashboard.svg';
import { ReactComponent as LogoMoveMenu } from '../assets/image/svg/logo_move_menu.svg';
import { ReactComponent as IconMenuOrder } from '../assets/image/svg/icon_menu_order.svg';
import { ReactComponent as IconChart } from '../assets/image/svg/icon_chart.svg';
import { ReactComponent as IconQuotes } from '../assets/image/svg/icon_quotes.svg';
import { ReactComponent as IconSetting } from '../assets/image/svg/icon_setting.svg';
import { ReactComponent as IconDashBoardActive } from '../assets/image/svg/icon_dasboard_active.svg';
import { ReactComponent as IconChartActive } from '../assets/image/svg/icon_chart_active.svg';
import { ReactComponent as IconOrderActive } from '../assets/image/svg/icon_order_active.svg';
import { ReactComponent as IconQuotesActive } from '../assets/image/svg/icon_quotes_active.svg';
import { ReactComponent as IconMenuLogout } from '../assets/image/svg/icon_menu_logout.svg';
import { ReactComponent as IconArrowBack } from '../assets/image/svg/icon_arrow_back.svg';
import { ReactComponent as IconSettingSelected } from '../assets/image/svg/icon_setting_selected.svg';
import { ReactComponent as IconDownloadOrder } from '../assets/image/svg/icon_download_order.svg';
import { ReactComponent as IconArrowDown } from '../assets/image/svg/icon_arrow_down.svg';
import { ReactComponent as IconSortUp } from '../assets/image/svg/icon_sort_up.svg';
import { ReactComponent as IconSearch } from '../assets/image/svg/icon_search.svg';
import { ReactComponent as BgItemCardAnalytic } from '../assets/image/svg/bg_item_card_analytic.svg';
import { ReactComponent as IconArrowRight } from '../assets/image/svg/IconArrowRight.svg';
import { ReactComponent as SettingLogout } from '../assets/image/svg/setting_logout.svg';
import { ReactComponent as SettingPrivacy } from '../assets/image/svg/setting_privacy.svg';
import { ReactComponent as SettingProfile } from '../assets/image/svg/setting_profile.svg';
import { ReactComponent as SettingTerms } from '../assets/image/svg/setting_terms.svg';
import { ReactComponent as IconEditPen } from '../assets/image/svg/edit-icon.svg';
import { ReactComponent as IconDropDown } from '../assets/image/svg/dropdown.svg';
import { ReactComponent as IconEyePassword } from '../assets/image/svg/eye-opened.svg';
import { ReactComponent as IconEyeOffPassword } from '../assets/image/svg/eye-closed.svg';
import { ReactComponent as IconChevronRight } from '../assets/image/svg/chevron-right.svg';
import { ReactComponent as UserIcon } from '../assets/image/svg/user-icon.svg';
import { ReactComponent as EnvelopeIcon } from '../assets/image/svg/envelope-icon.svg';
import { ReactComponent as PhoneIcon } from '../assets/image/svg/phone-icon.svg';
import { ReactComponent as PinIcon } from '../assets/image/svg/pin-icon.svg';
import { ReactComponent as NotificationBell } from '../assets/image/svg/notification-bell.svg';
import { ReactComponent as TeamIconActive } from '../assets/image/svg/team_icon_active.svg';
import { ReactComponent as TeamIcon } from '../assets/image/svg/team-icon.svg';
import { ReactComponent as NoNotificationsIcon } from '../assets/image/svg/no_notifications.svg';
import { ReactComponent as SubscriptionActiveIcon } from '../assets/image/svg/wallet-active.svg';
import { ReactComponent as CopyIcon } from '../assets/image/svg/copy.svg';
import { ReactComponent as RefreshIcon } from '../assets/image/svg/refresh.svg';
import { ReactComponent as LockIcon } from '../assets/image/svg/lock.svg';
import { ReactComponent as SubscriptionIcon } from '../assets/image/svg/wallet.svg';
import { ReactComponent as PaymentActiveIcon } from '../assets/image/svg/payment-active.svg';
import { ReactComponent as PaymentIcon } from '../assets/image/svg/payment.svg';
import { ReactComponent as AppLogo } from '../assets/image/svg/app-logo.svg';
import { ReactComponent as StripeLogo } from '../assets/image/svg/app-logo.svg';
import { ReactComponent as Language } from '../assets/image/svg/language.svg';
import { ReactComponent as WhiteLableActive } from "../assets/image/svg/whiteLabelActive.svg";
import { ReactComponent as WhiteLable } from "../assets/image/svg/whiteLable.svg";
import { ReactComponent as IconNotes } from '../assets/image/svg/icon_notes.svg';
import { ReactComponent as IconNotesActive } from '../assets/image/svg/icon_notes_active.svg';
import { ReactComponent as IconChildAccounts } from '../assets/image/svg/icon_child_accounts.svg';
import { ReactComponent as IconChildAccountsActive } from '../assets/image/svg/icon_child_accounts_active.svg';
import { ReactComponent as IconDashBoardApp} from '../assets/image/svg/dashboard.svg';
import { ReactComponent as IconDashBoardActiveApp} from '../assets/image/svg/dashboard_active.svg';
import { ReactComponent as IconValueAcceptedApp} from '../assets/image/svg/valueaccepted.svg';
import { ReactComponent as IconGrowthArrowApp} from '../assets/image/svg/growtharrow.svg';

export const Images = { backgroundApp: { source: backgroundApp, ratio: 1 } };
export const SVGImageComponentReact = {
  IconEyeOffPassword,
  IconDropDown,
  IconEditPen,
  IconNotification,
  IconUser,
  IconLogout,
  IconApp,
  IconRightArrow,
  IconUpArrow,
  IconDownArrow,
  IconChecked,
  IconLeftArrow,
  IconPlusYellow,
  IconRadioUnCheck,
  IconRadioChecked,
  IconUpload,
  IconDelete,
  IconStepDone,
  IconDateSuffix,
  IconDownload,
  IconPlay,
  LogoAppWhite,
  IconEdit,
  IconDot,
  IconCurrentPlan,
  IconEyePassword,
  LogoMove,
  CheckBox,
  CheckBoxUncheck,
  IconDashBoard,
  LogoMoveMenu,
  IconMenuOrder,
  IconChart,
  IconQuotes,
  IconMenuLogout,
  IconSetting,
  IconArrowBack,
  IconSettingSelected,
  IconDownloadOrder,
  IconArrowDown,
  IconSortUp,
  IconDashBoardActive,
  IconChartActive,
  IconOrderActive,
  IconQuotesActive,
  IconSearch,
  BgItemCardAnalytic,
  IconArrowRight,
  SettingLogout,
  SettingPrivacy,
  SettingProfile,
  SettingTerms,
  IconChevronRight,
  UserIcon,
  EnvelopeIcon,
  PhoneIcon,
  PinIcon,
  NotificationBell,
  TeamIconActive,
  TeamIcon,
  NoNotificationsIcon,
  SubscriptionActiveIcon,
  SubscriptionIcon,
  PaymentActiveIcon,
  PaymentIcon,
  AppLogo,
  StripeLogo,
  CopyIcon,
  RefreshIcon,
  LockIcon,
  Language,
  WhiteLableActive,
  WhiteLable,
  IconNotes,
  IconNotesActive,
  IconChildAccounts,
  IconChildAccountsActive,
  IconDashBoardApp,
  IconDashBoardActiveApp,
  IconValueAcceptedApp,
  IconGrowthArrowApp
};
