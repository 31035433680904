import React, { useEffect, useRef, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextInput from '../../common/TextInput';
import FileUpload from '../../components/UploadFile';
import Button from '../../common/Button';
import Layout from '../../components/Layout';
import { CREATE_ORDER_VALUES, VIN_NAMES } from '../../container/utils/data';
import { validateOrderInputs } from '../../container/utils/validations';
import OrderPlacedModal from '../../components/OrderPlacedModal';
import { useNavigate, useParams } from 'react-router-dom';
import Utils from '../../../utils';
import { useDeleteOrder, useGetAttributes, useGetOrder, useUpdateOrder } from '../../container/hooks/useOrders';
import { useDispatch, useSelector } from 'react-redux';
import { fileToUrl, translate, urlToFile } from '../../../helper';
import LocalStorage from '../../../defined/localStorage';
import { UserAction } from '../../../redux/action';
import docUpload from '../../../assets/image/svg/docUpload.svg';
import Tooltip from './tooltip';
import Scanner from './Scanner';

const initialValues = CREATE_ORDER_VALUES.reduce((acc, curr) => {
    if (curr.type === 'file') {
        return acc;
    }
    acc[curr.name] = '';
    return acc;
}, {});

const requiredValues = CREATE_ORDER_VALUES.filter((e) => e.required).map((e) => e.name);

function UploadImage(props) {
    const { onChange } = props;
    return (
        <form>
            <label style={{}} htmlFor="capture-productimage">
                <img src={docUpload} style={{ width: '25px' }} alt="uploadproduct" />
            </label>
            <input
                onInput={onChange}
                type="file"
                accept="capture=camera,image/*"
                id="capture-productimage"
                multiple={true}
                style={{ display: 'none' }}
            />
        </form>
    );
}

function CreateOrder(props) {
    const { newData } = props;
    let orderValues = useSelector((state) => state?.User?.order_details);
    const whiteLabel = useSelector((state) => state.User?.branding);
    const { data: userDetail } = useSelector((state) => state.auth);
    const [files, setFiles] = useState([]);
    const [productFile, setProductFile] = useState([]);
    const [fileUrls, setFileUrls] = useState([]);
    const [values, setValues] = useState({ ...initialValues });
    const [validation, setValidation] = useState({ ...initialValues });
    const [open, setOpen] = useState(false);
    const [dynamicAttributes, setDynamicAttributes] = useState([]);
    const [config, setConfig] = useState({});
    const [deliveryType, setDeliveryType] = useState('');
    const navigate = useNavigate();
    const vinFieldRef = useRef(null);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { getOrderDetails } = useGetOrder(
        onGetOrder,
        onGetOrderError
    );
    const { deleteOrder, } = useDeleteOrder(onSuccessDelete);
    const { getAttributes, isGettingAttributes } = useGetAttributes(onSuccessGettingAttributes);
    const { updateOrder, } = useUpdateOrder(onSuccessUpdate);
    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const [scan, setScan] = useState(false);
    const endpointType = props.dt ? 'dealerTechnicianEndpoints' : 'userMobileEndpoints';
    const genOrderQuoteUrl = (id) => `/${props.dt ? 'dealer-technician' : 'customer'}/order-quote/${id}`;


    function onSuccessUpdate(data) {
        Utils.showAlert(1, data.message || 'Order Updated!!');
        navigate(genOrderQuoteUrl(id));
    }

    const onDelete = () => {
        deleteOrder({ id });
    };

    function onSuccessGettingAttributes(data) {
        let dynamicValues = data?.dynamic_attribute_names?.map((e) => ({ ...e, value: '' })) || [];
        if (!orderValues?.dynamic_attributes) {
            setDynamicAttributes(dynamicValues);
        }
        setConfig({
            backgroundColor: data?.background_color,
            currencyType: data?.currency_type
        });
        dispatch(UserAction.saveOrderConfigs());
    }

    const onChangeDynamicValues = (val, name) => {
        let updatedValues = dynamicAttributes.map((e) => (e.name === name ? { ...e, value: val } : e));
        setDynamicAttributes(updatedValues);
    };

    const handleFocus = () => {
        setTooltipOpen(true);

    };

    const handleBlur = () => {
        setTooltipOpen(false);
    };

    function onGetOrder(data) {
        let newData = Object.keys(initialValues).reduce((acc, cur) => {
            acc[cur] =
                cur === 'order_name'
                    ? data['name']
                    : cur === 'year'
                        ? data['make']
                        : cur === 'product_data'
                            ? data['vin']
                            : data[cur];
            return acc;
        }, {});
        let newDynamicAttributes = Object.keys(data?.dynamic_attributes||{}).map((e, i) => ({
            id: i,
            name: e,
            value: data?.dynamic_attributes?.[e]
        }));
        setDynamicAttributes(newDynamicAttributes);
        setValues(newData);
        setDeliveryType(data?.delivery_method);
        setFileUrls(data?.images || []);
    }

    function onGetOrderError(data) {
    }

    function onSuccessDelete(data) {
        Utils.showAlert(1, 'Order successfully deleted');
        setTimeout(() => {
            navigate(Utils.EndPoint[endpointType].createOrder);
        }, 500);
    }

    const handleClearImages = () => {
        dispatch(UserAction.resetOrderDetails());
    };

    const submitUpdatedOrder = (id) => {
        const orderDetails = {
            orderData: values,
            images: [...files, ...productFile],
            dynamic_attributes: dynamicAttributes?.reduce((acc, curr) => {
                acc[curr.name] = curr.value;
                return acc;
            }, {})
        };
        const formData = new FormData();

        for (let index = 0; index < orderDetails?.images.length; index++) {
            const itemImage = orderDetails?.images[index]?.file;

            try {
                formData.append(`images[${index}]image`, itemImage, itemImage.name);
            } catch (error) {
                formData.append(`images[${index}]image`, itemImage, itemImage.name);
            }
        }
        Object.keys(orderDetails.orderData).map((key) => {
            if (key === 'images') return;
            let value = orderDetails.orderData[key];
            if (key === 'quantity') {
                value = Number(value);
            }
            if (key === 'year') {
                formData.append('make', value);
            }
            if (key === 'order_name') {
                formData.append('name', value);
            }
            if (key === 'product_data') {
                formData.append('vin', value);
            }
            formData.append(key, value);
        });
        formData.append('dynamic_attributes', JSON.stringify(orderDetails.dynamic_attributes));

        updateOrder({ formData, id });
    };

    useEffect(() => {
        if (id) {
            getOrderDetails({ id: id });
        }
        if (!id) {
            getAttributes();
        }
    }, [id]);

    React.useEffect(() => {
        if (userDetail) {
            const {
                user: { profile }
            } = userDetail;
            setValues({
                ...values,
                shipping_address: `${profile.address}, ${profile.city} ${profile.state}, ${profile.zip_code}`
            });
        }
    }, [userDetail]);

    useEffect(() => {
        if (newData && !orderValues) {
            setValues({
                ...values,
                ...(newData?.orderData || {})
            });
            setFiles([...(newData.images || [])]);
            setDynamicAttributes([
                ...Object.keys(newData?.dynamic_attributes || {}).map((e) => ({
                    name: e,
                    value: newData?.dynamic_attributes?.[e]
                }))
            ]);
        }
    }, [newData]);

    useEffect(() => {
        if (orderValues && Object.keys(orderValues || {})?.length > 0) {
            handleSavedOrder(orderValues);
        }
    }, [orderValues]);

    function uploadFile(e, type) {
        let maxOffilesId = Math.max(...files.map((e) => e.id), 0);
        let updatedfiles = Array.from(e.target.files).map((file, i) => {
            let blob = file.slice(0, file.size, 'image/png');
            let type = file.name.split('.')[file.name.split('.').length - 1];
            let updatedFile = new File([blob], `${file.name}-product_image.${type}`, {
                type: 'image/png'
            });
            return { file: updatedFile, id: (maxOffilesId) + i + 1, type: 'content' };
        });
        setFiles([...files, ...updatedfiles], () => {
        });
    }

    const onCamUpload = (data) => {
        let maxOffilesId = Math.max(...files.map((e) => e.id), 0);
        setFiles([
            ...files,
            {
                file: data,
                id: (maxOffilesId) + 1,
                type: 'content'
            }
        ]);
    };

    const uploadProductFile = (e) => {
        let updatedfiles = Array.from(e.target.files).map((file, i) => ({
            file,
            id: i + 1,
            type: 'product'
        }));
        setProductFile(updatedfiles);
    };

    const onChange = (value, key) => {
        setValues({
            ...values,
            [key]: value
        });
    };

    const imageUrl = (url) => {
        return URL.createObjectURL(url.file);
    };

    const deleteFilled = (id, type) => {
        if (type === 'content') {
            let newFiles = files.filter((e) => e.id !== id);
            setFiles(newFiles);
        }

        if (type === 'product') {
            let newFiles = productFile.filter((e) => e.id !== id);
            setProductFile(newFiles);
        }
    };

    const submitOrder = async () => {
        const errors = validateOrderInputs(values, initialValues, requiredValues);

        if (Object.values(errors).filter((e) => e.length !== 0).length !== 0 || files.length === 0) {
            setValidation({
                ...errors,
                ...(files.length === 0 ? { file_upload: 'This field is mandatory' } : {})
            });
            return;
        }
        if (!deliveryType) {
            setValidation({ deliveryType: 'This field is mandatory' });
            return;
        }
        let mandatDynamic = dynamicAttributes.filter(e=>e.mandatory)
        if (mandatDynamic.length>0&&mandatDynamic.filter(e=>!e.value).length>0) {
            setValidation({ ...dynamicAttributes.reduce((acc, curr) => {
                if (curr.mandatory && !curr.value) {
                    acc[curr.name] = 'This field is mandatory';
                }
                return acc;
            }, {}) });
            return;
        }
        let fileData = [...files, ...productFile];
        let updatedFiles = [];
        for (let i = 0; i < fileData.length; i++) {
            let newFile = await fileToUrl(fileData[i]?.file);
            let obj = { ...fileData[i], file: newFile, name: fileData[i]?.file?.name };
            updatedFiles.push(obj);
        }
        values.delivery_method = deliveryType;
        const orderDetails = {
            orderData: values,
            images: updatedFiles,
            dynamic_attributes: dynamicAttributes?.reduce((acc, curr) => {
                acc[curr.name] = curr.value;
                return acc;
            }, {})
        };
        dispatch(UserAction.saveOrderDetails(orderDetails))
            .then(() => {
                navigate(Utils.EndPoint[endpointType].vendors, {
                    state: { ...orderDetails, isCreatingOrder: true, isOrdering: true }
                });
            });
    };

    const layoutProps = {
        ...(!id && props.backTo
            ? {
                backTo: () => {
                    props.backTo();
                    LocalStorage.removeItem('newOrder');
                    handleClearImages();
                }
            }
            : {})
    };
    const handleSavedOrder = async () => {
        if (!(orderValues?.orderData || orderValues?.images || orderValues?.dynamic_attributes)) return;
        let {
            orderData: {
                order_name,
                description,
                shipping_address,
                quantity,
                year,
                model,
                product_data,
                delivery_method
            } = {},
            images,
            dynamic_attributes
        } = orderValues || {};
        setValues({
            order_name: order_name || '',
            description: description || '',
            shipping_address: values?.shipping_address || shipping_address || '',
            quantity: quantity || '',
            year: year || '',
            model: model || '',
            product_data: product_data || ''
        });
        setDeliveryType(delivery_method);
        if (Object.keys(dynamic_attributes || {})?.length > 0) {
            let data = Object.keys(dynamic_attributes || {}).map((e) => ({
                name: e,
                value: dynamic_attributes[e]
            }));
            setDynamicAttributes(data);
        }
        let newImgs = [];

        for (let i = 0; i < images?.length; i++) {
            let newImg = await urlToFile(images[i].file, images[i].name);
            let obj = {
                file: newImg,
                id: images[i].id,
                type: images[i].type
            };
            newImgs.push(obj);
        }

        setFiles(newImgs);
    };

    const deliveryTypes = [
        {
            name: 'instore',
            label: 'inStorePickup'
        },
        {
            name: 'delivery',
            label: 'delivery'
        }
    ];

    const handleDeliveryTypeChange = (e) => {
        setDeliveryType(e.target.value);
    };

    const handlePaste = async () => {
        const text = await navigator.clipboard.readText();
        dynamicAttributes.forEach((attr) => {
            if (isVinName(attr?.name)) {
                attr.value = text;
            }
        });

        setDynamicAttributes([...dynamicAttributes]);
        setTooltipOpen(false);
    };

    const handleScan = () => {
        setScan(true);
        setTooltipOpen(false);
        vinFieldRef.current?.scrollIntoView({ behavior: 'auto' });
    };


    const handleScannerResponse = (data) => {
        const identifiedPhrases = data?.data?.identified_phrases
        setValues({
            ...values,
            vin: identifiedPhrases.length > 0 ? identifiedPhrases[0]?.text: ''
        })

        setDynamicAttributes([...dynamicAttributes]);
        setTooltipOpen(false);
    };

    const handleCloseCRM = () => {
        setScan(false);
    };

    const isVinName = (name) => VIN_NAMES.includes(name.toLowerCase());
    return (
        <Layout
            showNavigation
            showNotification
            title={id ? 'editOrder' : 'createOrder'}
            backTo={() => {
                navigate(-1);
                handleClearImages();
            }}
            isHomePage
            {...layoutProps}>
            <div
                style={{
                    padding: '20px 15px',
                    width: '100%',
                    backgroundColor:
                        config?.backgroundColor || '#FFFFFF',
                    marginTop: '-5%',
                    height: '105%',
                    overflowY: 'auto',
                    scrollBehavior: 'smooth'
                }}>
                {CREATE_ORDER_VALUES?.filter((e) => e?.name !== 'file_upload').map((value) => {
                    return value.type === 'text' || value.type === 'number' ? (
                        <React.Fragment key={value.name}>
                            {isVinName(value?.name) && (
                                <Tooltip
                                    handlePaste={handlePaste}
                                    onFocus={handleFocus}
                                    isVisible={isTooltipOpen}
                                    handleScan={handleScan}
                                />
                            )}
                            <TextInput
                                style={value.style}
                                disabled={isGettingAttributes}
                                rows={value.rows}
                                className={value.className}
                                label={translate(value.label)}
                                placeholder={translate(value.placeholder)}
                                name={value.name}
                                onChange={onChange}
                                value={values[value.name]}
                                key={value.name}
                                error={validation[value.name]}
                                required={value.required}
                                type={value.type}
                                onFocus={isVinName(value.name) ? handleFocus : null}
                                onBlur={isVinName(value.name) ? handleBlur : null}
                                ref={isVinName(value.name) ? vinFieldRef : null}
                            />
                            {isVinName(value.name) && scan && (
                                <Scanner
                                    setScan={setScan}
                                    handleScannerResponse={handleScannerResponse}
                                    handleClose={handleCloseCRM}
                                />
                            )}
                        </React.Fragment>
                    ) : value.type === 'file' ? (
                        <React.Fragment key={value.name}>
                            {id && (
                                <>
                                    {
                                        <div
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                color: '#000',
                                                marginBottom: '5px'
                                            }}
                                            className="text-input">
                                            {translate('images')}
                                        </div>
                                    }
                                    <div style={{ display: 'flex', overflowY: 'auto' }}>
                                        {fileUrls.map((data) => (
                                            <img
                                                src={data?.image}
                                                style={{
                                                    width: '80px',
                                                    height: '80px',
                                                    marginRight: '8px',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                            <FileUpload
                                label={translate(value.label)}
                                onChange={uploadFile}
                                onCamUpload={onCamUpload}
                                files={files}
                            />
                            {validation['file_upload'] && (
                                <p className="text-input-error">{validation['file_upload']}</p>
                            )}
                        </React.Fragment>
                    ) : value.type === 'text/file' ? (
                        <TextInput
                            style={value.style}
                            rows={value.rows}
                            className={value.className}
                            label={translate(value.label)}
                            placeholder={translate(value.placeholder)}
                            name={value.name}
                            onChange={onChange}
                            value={values[value.name]}
                            key={value.name}
                            error={validation[value.name]}
                            rightElement={<UploadImage onChange={uploadProductFile} />}
                        />
                    ) : (
                        <></>
                    );
                })}
                {dynamicAttributes?.map((attr) => (
                    <React.Fragment key={attr.id}>
                        {isVinName(attr?.name) && (
                            <Tooltip
                                handlePaste={handlePaste}
                                onFocus={handleFocus}
                                isVisible={isTooltipOpen}
                                handleScan={handleScan}
                            />
                        )}
                        <TextInput
                            key={attr.id}
                            style={attr.style}
                            disabled={isGettingAttributes}
                            rows={1}
                            className={attr.className}
                            label={translate(attr.name)}
                            placeholder={translate(attr.name)}
                            name={attr.name}
                            onChange={onChangeDynamicValues}
                            value={attr.value}
                            required={attr.mandatory}
                            error={validation[attr.name]}
                            onFocus={isVinName(attr.name) ? handleFocus : null}
                            onBlur={isVinName(attr.name) ? handleBlur : null}
                            ref={isVinName(attr.name) ? vinFieldRef : null}
                        />
                        {isVinName(attr?.name) && scan && (
                            <Scanner
                                setScan={setScan}
                                handleScannerResponse={handleScannerResponse}
                                handleClose={handleCloseCRM}
                            />
                        )}
                    </React.Fragment>
                ))}
                <FormControl style={{ marginTop: '20px' }}>
                    <FormLabel
                        style={{ fontSize: '14px', marginBottom: '5px' }}
                        id="demo-radio-buttons-group-label">
                        {translate('deliveryMethod')}*
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={deliveryType}
                        onChange={handleDeliveryTypeChange}
                        style={{
                            paddingLeft: '20px'
                        }}>
                        {deliveryTypes.map((type, i) => (
                            <FormControlLabel
                                key={type.name}
                                style={{ fontSize: '14px' }}
                                value={type.name}
                                control={<Radio disabled={id ? true : false} size="small" />}
                                label={translate(type.label)}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
                {validation['deliveryType'] && (
                    <p className="text-input-error">{validation['deliveryType']}</p>
                )}
                {CREATE_ORDER_VALUES?.filter((e) => e?.name === 'file_upload').map((value) => {
                    return (
                        <React.Fragment key={value.name}>
                            {id && (
                                <>
                                    {
                                        <div
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                color: '#000',
                                                marginBottom: '5px'
                                            }}
                                            className="text-input">
                                            {translate('images')}
                                        </div>
                                    }
                                    <div style={{ display: 'flex', overflowY: 'auto' }}>
                                        {fileUrls.map((data) => (
                                            <img
                                                src={data?.image}
                                                style={{
                                                    width: '80px',
                                                    height: '80px',
                                                    marginRight: '8px',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                            <FileUpload
                                label={translate(value.label)}
                                onChange={uploadFile}
                                onCamUpload={onCamUpload}
                                files={files}
                            />
                            {validation['file_upload'] && (
                                <p className="text-input-error">{validation['file_upload']}</p>
                            )}
                        </React.Fragment>
                    );
                })}
                {[...files, ...productFile].map((file, i) => {
                    let url = imageUrl(file);
                    return (
                        <div
                            style={{
                                width: '100%',
                                height: '75px',
                                border: '1px solid #D8D8D8',
                                borderRadius: '0.5rem',
                                padding: '10px',
                                position: 'relative',
                                display: 'flex',
                                marginTop: '20px',
                                backgroundColor: '#C8C8C8'
                            }}>
                            <img
                                src={url}
                                style={{
                                    width: '55px',
                                    height: '55px'
                                }}
                            />
                            <div
                                style={{
                                    width: 'calc(100% - 70px)',
                                    marginLeft: '15px',
                                    height: '55px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                <span>{file.file.name}</span>
                            </div>
                            <CloseOutlined
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px'
                                }}
                                onClick={() => deleteFilled(file.id, file.type)}
                            />
                        </div>
                    );
                })}
                {!id && (
                    <Button
                        onClick={submitOrder}
                        style={{ marginBottom: '10px' }}>
                        {translate('sendToLocation')}
                    </Button>
                )}
                {id && (
                    <Button
                        onClick={() => submitUpdatedOrder(id)}
                        style={{ marginBottom: '10px' }}>
                        {translate('saveOrder')}
                    </Button>
                )}
                {id && (
                    <Button
                        onClick={() => setOpen(true)}
                        style={{
                            marginBottom: '100px',
                            background: '#fff',
                            color: '#6B7280',
                            border: '1px solid #6B7280',
                            boxShadow: 'none'
                        }}>
                        {translate('deleteOrder')}
                    </Button>
                )}
            </div>
            <OrderPlacedModal
                open={open}
                onClose={() => setOpen(!open)}
                mode={'Warning'}
                handleSubmit={onDelete}
            />
        </Layout>
    );
}

export default CreateOrder;
