import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import { Button } from "antd";
import LocalStorage from "../../defined/localStorage";
import { translate } from "../../helper";

const brand = JSON.parse(LocalStorage.getItem('branding'))
const MIN_WIDTH = 92;

const ImageEditor = ({img, setCroppedImage, handleCloseEditor}) => {
    const whiteLabel = useSelector((state) => state.User?.branding);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [imgSrc, setImgSrc] = useState('')
    const [error, setError] = useState('')
    const inputRef = useRef()
    const imageRef = useRef()
    const canvasRef = useRef()

    useEffect(()=>{
        if (img) {
            setError('');
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const imageSrc = new Image();
                const imgurl = reader.result?.toString() || '';
                imageSrc.url = imgurl;
                imageSrc.addEventListener('load', (e) => {
                    const { naturalWidth, naturalHeight } = e.target;
                    if (naturalWidth < MIN_WIDTH || naturalHeight < MIN_WIDTH) {
                        setError(`Image dimensions must be at least ${MIN_WIDTH}px x ${MIN_WIDTH}px`);
                        setImgSrc('')
                        return;
                    }
                })
                setImgSrc(imgurl);
            });
            reader.readAsDataURL(img);
        }
    }, [img])

    const setCanvasPreview = (image, canvas, crop) => {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        const croppedImageURL = canvas.toDataURL('image/png'); // 'image/png' can be changed to 'image/jpeg' if needed
    
        setCroppedImage(croppedImageURL);
    }

    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        const cropWidth = (MIN_WIDTH / width) * 100;
        const crop = makeAspectCrop(
            {
                unit: "%",
                width: cropWidth,
            },
            1, 
            width,
            height
        )
        const centreCrop = centerCrop(crop, width, height)
        setCrop(centreCrop)
    }
    let pixelCrop = convertToPixelCrop(crop, imageRef?.current?.width, imageRef?.current?.height)
    // 
    return (
      <div className="container" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <ReactCrop 
            crop={crop}
            keepSelection
            aspect={1}
            minWidth={MIN_WIDTH}
            onChange={(pixelCrop, percentCrop)=>setCrop(percentCrop)}
        >
            <img ref={imageRef} src={imgSrc} alt="Crop" style={{maxHeight: "70vh", width: "auto"}} onLoad={onImageLoad} />
        </ReactCrop>
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px 0"
            }}
        >
            <Button
                onClick={()=>setCanvasPreview(
                    imageRef.current,
                    canvasRef.current,
                    convertToPixelCrop(crop, imageRef.current.width, imageRef.current.height)
                )} 
                style={{
                    borderRadius: "8px",
                    padding: "5px 20px",
                    marginLeft: "20px",
                    border: "none",
                    background: `${whiteLabel?.web_button_color||brand?.web_button_color||'rgba(248, 178, 24, 1)'}`,
                    boxShadow: "0px 4px 4px 0px rgba(185, 131, 9, 0.09)",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#fff",
                    // minWwidth: "180px",
                    textTransform: "uppercase",
                    width: "fit-content",
                    minWidth: "120px"
                }}

            >{translate('crop')}</Button>
             <Button
                onClick={()=>handleCloseEditor()} 
                style={{
                    borderRadius: "8px",
                    padding: "5px 20px",
                    marginLeft: "20px",
                    border: "none",
                    background: `${whiteLabel?.web_button_color||brand?.web_button_color||'rgba(248, 178, 24, 1)'}`,
                    boxShadow: "0px 4px 4px 0px rgba(185, 131, 9, 0.09)",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#fff",
                    textTransform: "uppercase",
                    width: "fit-content",
                    minWidth: "120px"
                }}

            >{translate('cancel')}</Button>

        </div>
        {
            crop && (
                <canvas 
                    ref={canvasRef}
                    style={{
                        border: "1px solid #fff",
                        objectFit: "contain",
                        width: pixelCrop.width,
                        height: pixelCrop.height,
                        display: "none"
                    }}
                />
            )
        }        
      </div>
    )
}

export default ImageEditor