import { Modal } from 'antd';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Colors } from '../../themes/Colors';
import ButtonGradient from '../button-gradient';

export const SuccessModal = ({
    open,
    onClose,
    data,
    role
}) => {
    return (
        <Modal visible={open} footer={<div />} centered closeIcon={<div />} style={{}} width={"600px"}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px"
                    }}
                >
                    <CheckCircleIcon 
                        style={{
                            color: Colors.primary,
                            fontSize: "60px"
                        }}
                    />
                </div>
                <div
                    style={{
                        color: Colors.primary,
                        fontSize: "24px",
                        textAlign: "center",
                        lineHeight: "30px",
                        fontWeight: 600
                    }}
                >
                    Congratulations
                </div>
                <div
                    style={{
                        color: "#383938",
                        fontSize: "16px",
                        textAlign: "center",
                        lineHeight: "24px",
                        fontWeight: 400
                    }}
                >
                    You can now access {role==="Child Account"?'Location':'Master'} Account feature
                </div>
                <table
                    style={{
                        width: "90%",
                        marginTop: "20px",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                >
                    <tr>
                        <td style={{padding: "20px 0", width: "50%"}}>Subscription</td>
                        <td style={{padding: "20px 0"}}>{data?.name}</td>
                    </tr>
                    {
                        data?.noOfChildAccount?<tr>
                            <td style={{padding: "20px 0", width: "50%"}}>Total Locations Paid</td>
                            <td style={{padding: "20px 0"}}>{data?.noOfChildAccount}</td>
                        </tr>:<></>
                    }
                    <tr>
                        <td style={{padding: "20px 0", width: "50%"}}>Amount</td>
                        <td style={{padding: "20px 0"}}>{`$ ${data?.noOfChildAccount ? data.amount * data?.noOfChildAccount: data?.amount}/${data?.interval}`}</td>
                    </tr>
                    <tr>
                        <td style={{padding: "20px 0", width: "50%"}}>Paid on</td>
                        <td style={{padding: "20px 0"}}>{data?.paid}</td>
                    </tr>
                </table>
                <ButtonGradient
                    title='OK'
                    style={{
                        width: "100%"
                    }}
                    onClick={onClose}
                />

            </div>
        </Modal>
    )
}