import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';

import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import ButtonGradient from '../../common/button-gradient';
import {
  MESSAGE_TYPE,
  convertPxToRem,
  getCreditCardType,
  messageManager,
  verifyCard
} from '../../helper';
import { SVGImageComponentReact } from '../../themes/Images';
import LoginInput from '../../common/login-input';
import { CardForm } from '../../common/card-form';
import { CreditCard } from '../../common/credit-card';
import { useAddPaymentMethod } from '../../hooks/usePayment';

import { styles } from './styles';

const VALUES = {
  card_holder: '',
  number: '',
  expiration_date: '',
  cvc: ''
};

function AddPaymentMethod() {
  const navigate = useNavigate();

  const { addPaymentMethod, isAddingPaymentMethod, isError, error } =
    useAddPaymentMethod(onSuccess);

  const [values, setValues] = useState(VALUES);
  const [validation, setValidation] = useState({});
  const [expError, setExpError] = useState('');
  const [brand, setBrand] = useState('');

  useEffect(() => {
    handleSetBrand();
  }, [values.number]);

  const handleSetBrand = () => {
    if (values.number) {
      const neww = getCreditCardType(values.number);
      setBrand(neww || '');
    }
  };

  const handleSubmit = () => {
    const exp_month = Number(values.expiration_date.split('/')[0]);
    const exp_year = Number(values.expiration_date.split('/')[1]);

    let errors = verifyCard({ ...values, expiration_date: { exp_year, exp_month } });

    

    if (!(Object.keys(errors).length === 0)) {
      setValidation(errors);
      return;
    }
    setValidation({});

    const card = {
      number: Number(values.number.replace(/\s/g, '')),
      exp_month: exp_month,
      exp_year: Number(exp_year),
      month_exp: exp_month,
      year_exp: Number(exp_year),
      cvc: Number(values.cvc),
      card_holder: values.card_holder,
      type: 'C'
    };

    addPaymentMethod(card);
  };

  function onSuccess(data) {
    messageManager({
      messageShow: 'Card successfully added',
      type: MESSAGE_TYPE.success
    });
    navigate(-1);
  }

  return (
    <div className={css(styles.container)}>
      <Row justify="space-between">
        <Col>
          <Row>
            <div
              style={{ marginRight: convertPxToRem(33) }}
              className="flex-row-center-center cursor-pointer"
              onClick={() => navigate(-1)}>
              <SVGImageComponentReact.IconArrowBack />
            </div>
            <TextBase fontWeight={600} size={convertPxToRem(24)} color={Colors.titlePage}>
              Add Card Details
            </TextBase>
          </Row>
        </Col>
      </Row>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}>
        <div
          style={{
            width: '60%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '32px'
          }}>
          <CardForm
            values={values}
            setValues={setValues}
            validation={validation}
            brand={brand}
            setValidation={setValidation}
          />

          {isError && (
            <TextBase fontWeight={400} size={convertPxToRem(14)} color={Colors.red}>
              {error?.response?.data?.detail || 'Error while adding card'}
            </TextBase>
          )}
          <ButtonGradient
            onClick={() => handleSubmit()}
            loading={isAddingPaymentMethod}
            textStyle={{ fontWeight: 700, fontSize: 16 }}
            title="PROCEED"
            style={{ marginTop: '18px', width: '100%' }}
          />
        </div>
      </div>
    </div>
  );
}

export default AddPaymentMethod;
