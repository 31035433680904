import React, { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { heightOfHeader } from '../../utils/defined';
import { convertPxToRem, reloadApp, updateBranding } from '../../helper';
import { Colors } from '../../themes/Colors';
import TextBase from '../../common/text';
import { translate } from '../../helper';
import APIManager from '../../connection/ApiManager';
import ThemeDesign from '../../component/ThemeDesign';
import ScreenDesign from '../../component/ScreenDesign';
import TemplateDesign from '../../component/TemplateDesign';
import Utils from '../../utils';
import { useSelector } from 'react-redux';
import LocalStorage from '../../defined/localStorage';
import countries from "../../utils/countries.json"
import Api from "../../utils/api";
import PreviewModal from "../../component/PreviewModal"
import { copyToClipboard } from '../../utils/CommonFunction';

const brand = JSON.parse(LocalStorage.getItem('branding'))


const styles = StyleSheet.create({
    container: {
        textAlign: 'left',
        whiteSpace: 'pre-line',
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'hidden',
        minHeight: `calc(100vh - ${heightOfHeader})`,
        paddingTop: convertPxToRem(20),
        paddingLeft: convertPxToRem(32),
        paddingRight: convertPxToRem(32),
        paddingBottom: convertPxToRem(10)
    }
});

const defaultColor = { r: "0", g: "0", b: "0", a: "1" }
const backgrounfDefaultColor = { r: "255", g: "255", b: "155", a: "1" }

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }} style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <Typography style={{ width: "80%", padding: "30px", backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.05)" }}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const defaultFields = [
    {
        label: translate("orderName"),
        require: true,
        rows: 1,
        default: true
    },
    {
        label: translate("description"),
        require: false,
        rows: 2,
        default: true
    },
    {
        label: translate('shippingAddress'),
        require: false,
        rows: 1,
        default: true
    },
    {
        label: translate("quantity"),
        require: false,
        rows: 1,
        default: true
    },
    {
        label: translate("numberScanner"),
        require: false,
        rows: 1,
        default: true
    }
]

function getUniqueObjectsByProperty(arr, prop) {
    const uniqueSet = new Set();
    return Array.from(arr.reduce((acc, obj) => {
        if (!uniqueSet.has(obj[prop])) {
            uniqueSet.add(obj[prop]);
            acc.push(obj);
        }
        return acc;
    }, []));
}

function WhiteLabel() {
    const whiteLabel = useSelector((state) => state.User?.branding);
    const [value, setValue] = useState(0);
    const [sideBannerColor, setSideBannerColor] = useState(defaultColor);
    const [topBannerColor, setTopBannerColor] = useState(defaultColor);
    const [backgroundColor, setBackgroundColor] = useState(backgrounfDefaultColor);
    const [buttonColor, setButtonColor] = useState(defaultColor);
    const [logo, setLogo] = useState()
    const [logoError, setLogoError] = useState('')
    const [lockForChild, setLockForChild] = useState(true)
    const [screenNames, setScreenNames] = useState({
        screen1: 'Make. Ordering. Very. Easy',
        screen2: 'Picture Perfect Ordering',
        screen3: 'Move'
    })
    const [screenFiles, setScreenFiles] = useState({
        screen1: '',
        screen2: '',
        screen3: ''
    })
    const [mobileTopBannerColor, setMobileTopBannerColor] = useState(defaultColor);
    const [mobileBackgroundColor, setMobileBackgroundColor] = useState(defaultColor);
    const [mobileButtonColor, setMobileButtonColor] = useState(defaultColor);
    const [mobileLogo, setMobileLogo] = useState()
    const [lockForMobChild, setLockForMobChild] = useState(true)
    const [emailLogo, setEmailLogo] = useState();
    const [emailTemplate, setEmailTemplate] = useState({
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        contact_number: '',
        admin_email: ''
    })
    const [emailBackgroundColor, setEmailBackgroundColor] = useState(defaultColor)
    const [orderBackgroundColor, setOrderBackgroundColor] = useState(backgrounfDefaultColor)
    const [orerForm, setOrderForm] = useState(defaultFields)
    const [orderType, setOrderType] = useState('')
    const [currencyType, setCurrencyType] = useState('')
    const [loading, setLoading] = useState(false)
    const [showPreview, setShowPreview] = useState({
        status: false,
        type: ''
    })
    const [sendingLink, setSendingLink] = useState(false)
    let currencies = [...getUniqueObjectsByProperty(countries, 'currency')]

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const setDefault = () => {
        getWhitelabelData(value)
        window.scrollTo(0, 0);
    }

    const setWelcomeScreenDefault = () => {
        getWhitelabelData(value)
        window.scrollTo(0, 0);
    }

    const setEmailTeplateDefault = () => {
        if (value === 2) {
            getEmailTemplate()
        }
        if (value === 3) {
            getDynamicAttributeData()
        }
    }

    useEffect(() => {
        document.title = 'White Labeling';
    }, []);

    const rgbaToHex = (color) => {
        const rgba = Object.values(color);
        let hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
            .toString(16)
            .slice(1)}`;

        return hex;
    };

    const saveThemeChanges = async () => {
        try {

            const formData = new FormData()
            if (value === 0) {
                if (!logo) {
                    setLogoError("Please upload a logo!!")
                    Utils.showAlert(2, 'Please upload a logo!!')
                    return
                }
                setLoading(true)
                if (logo instanceof File) {
                    formData.append('web_logo', logo)
                }
                formData.append('web_top_banner_color', rgbaToHex(topBannerColor))
                formData.append('web_side_banner_color', rgbaToHex(sideBannerColor))
                formData.append('web_background_color', rgbaToHex(backgroundColor))
                formData.append('web_button_color', rgbaToHex(buttonColor))
                formData.append('is_branding', lockForChild)
            }
            if (value === 1) {
                if (!mobileLogo) {
                    setLogoError("Please upload a logo!!")
                    return
                }
                formData.append('mobile_screen_1_text', screenNames.screen1)
                if (screenFiles.screen1 instanceof File) {
                    formData.append('mobile_screen_1_image', screenFiles.screen1)
                }
                formData.append('mobile_screen_2_text', screenNames.screen2)
                if (screenFiles.screen2 instanceof File) {
                    formData.append('mobile_screen_2_image', screenFiles.screen2)
                }
                formData.append('mobile_screen_3_text', screenNames.screen3)
                if (screenFiles.screen3 instanceof File) {
                    formData.append('mobile_screen_3_image', screenFiles.screen3)
                }
                if (mobileLogo instanceof File) {
                    formData.append('mobile_logo', mobileLogo)
                }
                formData.append('mobile_top_banner_color', rgbaToHex(mobileTopBannerColor))
                formData.append('mobile_background_color', rgbaToHex(backgrounfDefaultColor))
                formData.append('mobile_button_color', rgbaToHex(mobileButtonColor))
                formData.append('is_branding', lockForChild)
            }

            const resOrder = await APIManager.getInstance().updateTheme(formData);

            await updateBranding()
            Utils.showAlert(1, 'Successfully updated!!!')
            LocalStorage.removeItem('branding')
            setLoading(false)
            setShowPreview({status: false, type: ''})
            if (value === 0) {
                setTimeout(() => {
                    window.location.reload();
                    window.location.reload();
                }, 1000)
            }
        } catch (err) {
            console.log(err)
            let errProperty = Object.keys(err)[0]
            let errMessage = err[errProperty]?.[0]
            setLoading(false)
            Utils.showAlert(2, `${errProperty} - ${errMessage}` || 'Something went wrong')
        }
    }

    const sendSetUpLink = async () => {
        setSendingLink(true)
        try {
            let res = await APIManager.getInstance().sendSetUpLink();
            if (res?.data?.message) {
                Utils.showAlert(1, res?.data?.message || 'Successfully link send!')
                setSendingLink(false)
            }
        } catch (err) {
            console.error(err)
            Utils.showAlert(2, err?.data || 'Something went wrong')
            setSendingLink(false)
        }
    }

    const saveOrderForm = async () => {
        let orderforData = {
            "background_color": "#fff",
            "currency_type": currencyType,
            "dynamic_attribute_names": orerForm.filter(data => !defaultFields.filter(e=>!e.removabale).map(e => e.label).includes(data.label)).map(e => ({ name: e.label, "mandatory": e.mandatory }))
        }

        await Api.postApiCall('white-labels/create-order-form/', JSON.stringify(orderforData))
        Utils.showAlert(1, 'Successfully updated!!!')
        setTimeout(() => {
            getDynamicAttributeData()
            setValue(3)
            setShowPreview({status: false, type: ''})
        }, 1000)
        return
    }

    const saveTemplate = async () => {
        try {
            if (value === 2 ) {

            }
            const formData = new FormData()
            formData.append('address_line_1', emailTemplate.address_line_1)
            formData.append('address_line_2', emailTemplate.address_line_2)
            formData.append('country', emailTemplate.country)
            formData.append('city', emailTemplate.city)
            formData.append('state', emailTemplate.state)
            formData.append('zip_code', emailTemplate.zipcode)
            formData.append('contact_number', emailTemplate.contact_number)
            formData.append('background_color', rgbaToHex(emailBackgroundColor))
            formData.append('admin_email', emailTemplate.admin_email)
            if (emailLogo instanceof File) {
                formData.append('template_logo', emailLogo)
            }
            let data = {
                "address_line_1": emailTemplate.address_line_1,
                "address_line_2": emailTemplate.address_line_2,
                "country": emailTemplate.country,
                "city": emailTemplate.city,
                "state": emailTemplate.state,
                "zip_code": emailTemplate.zipcode,
                "contact_number": emailTemplate.contact_number,
                "background_color": rgbaToHex(emailBackgroundColor),
                "admin_email": emailTemplate.admin_email,
                "template_logo": emailLogo
            }

            const resOrder = await APIManager.getInstance().updateEmailTemplate(formData)
            if (resOrder?.data?.id) {
                Utils.showAlert(1, 'Successfully updated!!!')
                setTimeout(() => {
                    getDynamicAttributeData()
                    getEmailTemplate()
                    setValue(2)
                    setShowPreview({status: false, type: ''})
                }, 1000)
            } else {
                throw new Error()
            }

        } catch (err) {
            Utils.showAlert(2, err?.message || 'Something went wrong')
        }
    }

    function hexToRGB(hex, alpha) {

        if (!hex) {
            return null;
        }
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        return { r, g, b, a: "1" };

    }

    const getDynamicAttributeData = async () => {
        const resOrder = await APIManager.getInstance().getDynamicAttributes();
        setOrderBackgroundColor("#fff")
        setCurrencyType(resOrder?.data?.currency_type || currencyType)
        let orderFormIds = orerForm?.map(e => e?.id)?.filter(e => e) || []
        let data = resOrder?.data?.dynamic_attribute_names.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.name === value.name
            ))
        )
        setOrderForm([...defaultFields.filter(e=>!data?.map(q=>q?.name).includes(e.label)), ...(data?.map(e => ({ ...e, label: e.name })) || [])])
    }

    async function getEmailTemplate() {
        const resOrder = await APIManager.getInstance().gateEmailTemplate()
        setEmailTemplate({
            address_line_1: resOrder?.data?.address_line_1 || '',
            address_line_2: resOrder?.data?.address_line_2 || '',
            city: resOrder?.data?.city || '',
            state: resOrder?.data?.state || '',
            country: resOrder?.data?.country || '',
            zipcode: resOrder?.data?.zip_code || '',
            contact_number: resOrder?.data?.contact_number || '',
            admin_email: resOrder?.data?.admin_email || ''
        })
        setEmailBackgroundColor(hexToRGB(resOrder?.data?.background_color) || "#000")
        setEmailLogo(resOrder?.data?.template_logo || null)
    }

    async function getWhitelabelData(val) {
        const resOrder = await APIManager.getInstance().getWhitelabel();
        const data = resOrder?.data || {}
        if (val===0) {
            setSideBannerColor(hexToRGB(data?.web_side_banner_color) || defaultColor)
            setTopBannerColor(hexToRGB(data?.web_top_banner_color) || defaultColor)
            setBackgroundColor(hexToRGB(data?.web_background_color) || backgrounfDefaultColor)
            setButtonColor(hexToRGB(data?.web_button_color) || defaultColor)
            setLogo(data?.web_logo || null)
        }
        if (val===1) {
            setScreenNames({
                screen1: data?.mobile_screen_1_text || 'Make. Ordering. Very. Easy',
                screen2: data?.mobile_screen_2_text || 'Picture Perfect Ordering',
                screen3: data?.mobile_screen_3_text || 'Move'
            })
            setScreenFiles({
                screen1: data?.mobile_screen_1_image || '',
                screen2: data?.mobile_screen_2_image || '',
                screen3: data?.mobile_screen_3_image || ''
            })
            setMobileTopBannerColor(hexToRGB(data?.mobile_top_banner_color) || defaultColor)
            setMobileBackgroundColor(hexToRGB(data?.mobile_background_color) || backgrounfDefaultColor)
            setMobileButtonColor(hexToRGB(data?.mobile_button_color) || defaultColor)
            setMobileLogo(data?.mobile_logo || null)
        }
        if (!val) {
            setSideBannerColor(hexToRGB(data?.web_side_banner_color) || defaultColor)
            setTopBannerColor(hexToRGB(data?.web_top_banner_color) || defaultColor)
            setBackgroundColor(hexToRGB(data?.web_background_color) || backgrounfDefaultColor)
            setButtonColor(hexToRGB(data?.web_button_color) || defaultColor)
            setLogo(data?.web_logo || null)
            setScreenNames({
                screen1: data?.mobile_screen_1_text || 'Make. Ordering. Very. Easy',
                screen2: data?.mobile_screen_2_text || 'Picture Perfect Ordering',
                screen3: data?.mobile_screen_3_text || 'Move'
            })
            setScreenFiles({
                screen1: data?.mobile_screen_1_image || '',
                screen2: data?.mobile_screen_2_image || '',
                screen3: data?.mobile_screen_3_image || ''
            })
            setMobileTopBannerColor(hexToRGB(data?.mobile_top_banner_color) || defaultColor)
            setMobileBackgroundColor(hexToRGB(data?.mobile_background_color) || backgrounfDefaultColor)
            setMobileButtonColor(hexToRGB(data?.mobile_button_color) || defaultColor)
            setMobileLogo(data?.mobile_logo || null)
        }
        setLockForChild(data?.is_branding)
    }

    useEffect(() => {
        getWhitelabelData()
        getDynamicAttributeData()
        getEmailTemplate()
    }, [])

    const addFIeld = () => {
        const maxId = orerForm.reduce((prev, current) => {if (prev < current.id)  {prev = current.id} return prev}, 1)
        setOrderForm([...orerForm, { id: maxId + 1, mandatory: false }])
    }

    const onLabelChange = (id, val) => {
        let newFOrm = orerForm.map(order => {
            if (order?.id === id) {
                order.label = val
            }
            return order
        })
        setOrderForm(newFOrm)
    }
    const deleteLabel = (id) => {
        let newFOrm = orerForm.filter(e => e.id !== id)
        setOrderForm(newFOrm)
    }
    const mandatoryFieldChange = (id) => {
        const newOrderForm = orerForm.map(e=>{
            if (e.id===id) {
                e.mandatory = !e.mandatory
            }
            return e
        })
        setOrderForm(newOrderForm)
    }

    const handleClosePreview = () => {
        setShowPreview({status: false, type: ''})
    }

    const checkPreview = () => {
        if (value===0) {
            if (!logo) {
                setLogoError("Please upload a logo!!")
                Utils.showAlert(2, 'Please upload a logo!!')
                return
            }
            setShowPreview({status: true, type: 'web'})
        }
        if (value === 1) {
            if (!mobileLogo) {
                setLogoError("Please upload a logo!!")
                return
            }
            setShowPreview({status: true, type: 'mob'})
        }
        if (value === 2) {
            setShowPreview({status: true, type: 'email'})
        }
        if (value === 3) {
            setShowPreview({status: true, type: 'order'})
        }
    }

    const savePreviewData = () => {
        if (value===0) {
            saveThemeChanges()
        }
        if (value===1) {
            saveThemeChanges()
        }
        if (value===2) {
            saveTemplate()
        }
        if (value===3) {
            saveOrderForm()
        }

    }

    const domain = window.location.host
    const domainList = [
        {
            url: `https://${ domain }/admin/login/?next=/admin/`,
            label: 'Admin URL'
        },
        {
            url: `https://${ domain }/customer`,
            label: 'Customer Application URL'
        },
        {
            url: `https://${ domain }/dealer-technician`,
            label: 'Dealer Technician URL'
        },
        {
            url: `https://${ domain }/login`,
            label: 'White Label Application URL'
        }
    ]

    return (
        <div style={{ backgroundColor: "#fff" }} className={css(styles.container)}>
            <div className="flex-row-center">
                <TextBase
                    fontWeight={600}
                    size={convertPxToRem(25)}
                    color={Colors.titlePage}
                    style={{ marginBottom: 60 }}
                >
                    whiteLabeling
                </TextBase>
            </div>
            <Box sx={{}}>
                <Tabs TabIndicatorProps={{ style: { background: '#F8B218' } }} value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab TabIndicatorProps={{ style: { background: '#F8B218' } }} style={{ borderBottom: value === 0 ? "1px solid #F8B218" : "1px solid #C8C8C8", color: value === 0 ? "#F8B218" : "#C8C8C8", fontSize: "16px", fontWeight: "500", lineHeight: '21px', textTransform: "capitalize" }} label={translate("whiteLabelWeb")} {...a11yProps(0)} />
                    <Tab TabIndicatorProps={{ style: { background: '#F8B218' } }} style={{ borderBottom: value === 1 ? "1px solid #F8B218" : "1px solid #C8C8C8", color: value === 1 ? "#F8B218" : "#C8C8C8", fontSize: "16px", fontWeight: "500", lineHeight: '21px', textTransform: "capitalize" }} label={translate("whiteLabelMobile")} {...a11yProps(1)} />
                    <Tab TabIndicatorProps={{ style: { background: '#F8B218' } }} style={{ borderBottom: value === 2 ? "1px solid #F8B218" : "1px solid #C8C8C8", color: value === 2 ? "#F8B218" : "#C8C8C8", fontSize: "16px", fontWeight: "500", lineHeight: '21px', textTransform: "capitalize" }} label={translate("emailTemplateOrderForm")} {...a11yProps(2)} />
                    <Tab TabIndicatorProps={{ style: { background: '#F8B218' } }} style={{ borderBottom: value === 3 ? "1px solid #F8B218" : "1px solid #C8C8C8", color: value === 3 ? "#F8B218" : "#C8C8C8", fontSize: "16px", fontWeight: "500", lineHeight: '21px', textTransform: "capitalize" }} label={translate("orderForm")} {...a11yProps(3)} />
                </Tabs>
                <CustomTabPanel value={value} index={0}>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "left",
                            backgroundColor: "#f3f4f6",
                            marginTop: "20px",
                        }}
                    >
                        <Box
                            style={{
                                backgroundColor: "#f3f4f6",
                                width: "50%",
                                height: "fit-content",
                                padding: "20px",
                                minHeight: "200px",
                                display: "flex",
                            }}
                        >
                            <ul
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                }}
                            >
                                {domainList.map((i, index) => (
                                    <li
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#000",
                                            fontSize: "16px",
                                        }}
                                    >
                                        <a style={{color: "#000"}} href={i.url}>{i.label}</a>
                                        <div
                                            style={{cursor: "pointer", marginLeft: "10px"}}
                                            onClick={() => copyToClipboard(i.url)}
                                            >
                                            <FileCopyIcon className={'copy-action'} sx={{ fontSize: 16 }}/>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </Box>
                    </div>
                    <ThemeDesign
                        header={true}
                        topBanner={true}
                        sideBanner={true}
                        button={true}
                        onSubmit={saveThemeChanges}
                        onPreview={checkPreview}
                        onClosePreview={handleClosePreview}
                        sideBannerColor={sideBannerColor}
                        topBannerColor={topBannerColor}
                        backgroundColor={backgroundColor}
                        buttonColor={buttonColor}
                        logo={logo}
                        setSideBannerColor={setSideBannerColor}
                        setTopBannerColor={setTopBannerColor}
                        setBackgroundColor={setBackgroundColor}
                        setButtonColor={setButtonColor}
                        setLogo={setLogo}
                        onCancel={setDefault}
                        active={lockForChild}
                        setActive={setLockForChild}
                        logoError={logoError}
                        setLogoError={setLogoError}
                        loading={loading}
                        send={sendSetUpLink}
                        isSending={sendingLink}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <ScreenDesign
                        screenNames={screenNames}
                        setScreenNames={setScreenNames}
                        screenFiles={screenFiles}
                        setScreenFiles={setScreenFiles}
                        onSubmit={saveThemeChanges}
                        onPreview={checkPreview}
                        topBannerColor={mobileTopBannerColor}
                        setTopBannerColor={setMobileTopBannerColor}
                        backgroundColor={mobileBackgroundColor}
                        setBackgroundColor={setMobileBackgroundColor}
                        buttonColor={mobileButtonColor}
                        setButtonColor={setMobileButtonColor}
                        logo={mobileLogo}
                        setLogo={setMobileLogo}
                        onCancel={setWelcomeScreenDefault}
                        active={lockForMobChild}
                        setActive={setLockForMobChild}
                        logoError={logoError}
                        setLogoError={setLogoError}
                        loading={loading}
                        send={sendSetUpLink}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <TemplateDesign
                        tab="emailtemplate"
                        emailLogo={emailLogo}
                        setEmailLogo={setEmailLogo}
                        onPreview={checkPreview}
                        emailTemplate={emailTemplate}
                        setEmailTemplate={setEmailTemplate}
                        emailBackgroundColor={emailBackgroundColor}
                        setEmailBackgroundColor={setEmailBackgroundColor}
                        orderBackgroundColor={orderBackgroundColor}
                        setOrderBackgroundColor={setOrderBackgroundColor}
                        onSubmit={saveTemplate}
                        onCancel={setEmailTeplateDefault}
                        orderForm={orerForm}
                        setOrderForm={setOrderForm}
                        addFIeld={addFIeld}
                        onLabelChange={onLabelChange}
                        orderType={orderType}
                        setOrderType={setOrderType}
                        currencyType={currencyType}
                        setCurrencyType={setCurrencyType}
                        currencies={currencies}
                        deleteLabel={deleteLabel}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <TemplateDesign
                        tab="orderForm"
                        emailLogo={emailLogo}
                        setEmailLogo={setEmailLogo}
                        onPreview={checkPreview}
                        emailTemplate={emailTemplate}
                        setEmailTemplate={setEmailTemplate}
                        emailBackgroundColor={emailBackgroundColor}
                        setEmailBackgroundColor={setEmailBackgroundColor}
                        orderBackgroundColor={orderBackgroundColor}
                        setOrderBackgroundColor={setOrderBackgroundColor}
                        onSubmit={saveOrderForm}
                        onCancel={setEmailTeplateDefault}
                        orderForm={orerForm}
                        setOrderForm={setOrderForm}
                        addFIeld={addFIeld}
                        onLabelChange={onLabelChange}
                        orderType={orderType}
                        setOrderType={setOrderType}
                        currencyType={currencyType}
                        setCurrencyType={setCurrencyType}
                        currencies={currencies}
                        deleteLabel={deleteLabel}
                        mandatoryFieldChange={mandatoryFieldChange}
                    />
                </CustomTabPanel>
            </Box>
            <PreviewModal
                isOpen={showPreview.status}
                type={showPreview.type}
                onClose={handleClosePreview}
                webTheme={{
                    sideBannerColor,
                    topBannerColor,
                    backgroundColor,
                    buttonColor,
                    logo
                }}
                mobTheme={{
                    screenNames,
                    screenFiles,
                    mobileTopBannerColor,
                    mobileBackgroundColor,
                    mobileButtonColor,
                    mobileLogo
                }}
                orderForm={orerForm}
                orderBackgroundColor={orderBackgroundColor}
                emailData={{
                    emailLogo,
                    emailTemplate,
                    emailBackgroundColor,
                }}
                onSubmit={savePreviewData}
                loading={loading}
            />
        </div>
    )
}

export default WhiteLabel
