import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './spinner.css';
import App from './App';
import './I18';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

serviceWorkerRegistration.register({ type: 'text/javascript' });
