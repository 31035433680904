import React from 'react'
import { CircularProgress, styled } from '@mui/material'

const LoaderContainer = styled('div')(({}) => ({
    'backgroundColor': 'rgba(0, 0, 0, 0.5)',
    'position': 'fixed',
    'zIndex': '1300',
    'right': '0',
    'bottom': '0',
    'top': '0',
    'left': '0',
    '& .loaderprogress': {
      'position': 'absolute',
      'top': '50%',
      'transform': 'translate(-50%, -50%)',
      'left': '50%',
      'textAlign': 'center',
      'zIndex': '9999',
    },
    '& .MuiCircularProgress-colorPrimary': {
      'color': '#fff',
    }
}));

const Loader = () => {
  return (
    <LoaderContainer>
      <div className="loaderprogress">
        <CircularProgress />
      </div>
    </LoaderContainer>
  )
}

export default Loader
