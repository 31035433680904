import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: [],
    error: [],
    total: 0,
    pageNo: 0,
    limit: 0,
    totalPage: 0
}

const vendorsListSlice = createSlice({
    name: 'vendorsList',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successVendorsListReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
            state.total = action.payload.total;
            state.limit = action.payload.limit;
            state.pageNo = action.payload.pageNo;
            state.totalPage = action.payload.totalPage;
        },
        failedVendorsListReducer: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { successVendorsListReducer, failedVendorsListReducer, loaderListener } = vendorsListSlice.actions;

export default vendorsListSlice.reducer;