import React from 'react';
import { css } from 'aphrodite';

import TextBase from '../text';
import { convertPxToRem, translate } from '../../helper';
import { Colors } from '../../themes/Colors';

import AddCircleIcon from '../../assets/image/png/add.png';

export const NewCardButton = ({ onClick = () => {} }) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: '100%',
        minHeight: '120px',
        borderRadius: '20px',
        padding: '32px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'linear-gradient(225deg, #168DFB 0%, #161FFB 100%)'
      }}>
      <img src={AddCircleIcon} style={{ height: '36px', width: '36px', marginBottom: '16px' }} />

      <TextBase
        style={{ textAlign: 'center', marginBottom: '16px' }}
        fontWeight={600}
        size={convertPxToRem(16)}
        color={Colors.white}>
        addNewCard
      </TextBase>
    </div>
  );
};
