import { useNavigate, useLocation } from "react-router-dom";

const withNavigate = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const history = useNavigate();
    const location = useLocation();
    return (
      <Component
        history={history}
        location={location}
        navigate={navigate}
        {...props}
      />
    );
  };

  return Wrapper;
};

export default withNavigate;
