export const CREATE_ORDER_VALUES = [
    {
        name: 'order_name',
        label: 'orderName',
        placeholder: 'enterOrderName',
        rows: 1,
        style: {},
        type: 'text',
        className: '',
        required: true
    },
    {
        name: 'description',
        label: 'description',
        placeholder: 'description',
        rows: 4,
        style: { paddingTop: "10px" },
        type: 'text',
        className: 'text-area',
        required: true
    },
    {
        name: 'shipping_address',
        label: 'shippingAddress',
        rows: 1,
        style: {},
        type: 'text',
        className: '',
        required: true
    },
    {
        name: 'quantity',
        label: 'quantity',
        placeholder: 'enterQuantity',
        rows: 1,
        style: {},
        type: 'number',
        className: '',
        required: true
    },
    {
        name: 'vin',
        label: 'numberScanner',
        placeholder: 'EnterValue',
        rows: 1,
        style: {},  
        type: 'text',
        className: ''
    },
    {
        name: 'file_upload',
        label: 'addImage',
        placeholder: 'file-upload',
        rows: 1,
        style: {},
        type: 'file',
        className: '',
        required: true
    }
]

export const VIN_NAMES =  ['vin', 'vin number', 'identification number', 'vin #', 'vin#', 'serial number', 's/n', 'vin no:', 'identification no:', 'serial no:']