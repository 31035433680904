import { imageResize } from '../helper'

const manifest = {
    'short_name': '',
    'name': '',
    'icons': [
        {
            'sizes': '72x72',
            'type': 'image/png',
            'purpose': 'maskable any'
        },
        {
            'sizes': '96x96',
            'type': 'image/png',
            'purpose': 'maskable any'
        },
        {
            'sizes': '128x128',
            'type': 'image/png',
            'purpose': 'maskable any'
        },
        {
            'sizes': '144x144',
            'type': 'image/png',
            'purpose': 'maskable any'
        },
        {
            'sizes': '152x152',
            'type': 'image/png',
            'purpose': 'maskable any'
        },
        {
            'sizes': '192x192',
            'type': 'image/png',
            'purpose': 'maskable any'
        },
        {
            'sizes': '384x384',
            'type': 'image/png',
            'purpose': 'maskable any'
        },
        {
            'sizes': '512x512',
            'type': 'image/png',
            'purpose': 'maskable any'
        }
    ],
    'start_url': '/customer',
    'display': 'standalone',
    'theme_color': '#000000',
    'background_color': '#ffffff',
    'orientation': 'any',
    'features': [
        'Cross Platform',
        'fast',
        'simple'
    ],
    'categories': [
        'utilities'
    ]
}


export async function manifetConfiguration(brand) {
    // const brand = useSelector((state) => state?.User?.branding)
    const isMobileUser = window.location.pathname.includes('customer') || window.location.pathname.includes('dealer');
    const isUser = window.location.pathname.includes('customer');
    const isDealer = window.location.pathname.includes('dealer');
    if (brand && Object.keys(brand).length > 0) {
        let favicon = document.querySelector('link[rel="icon"]')
        if (!favicon) {
            favicon = document.createElement('link')
            favicon.setAttribute('rel', 'icon')
            document.querySelector('head').appendChild(favicon)
        }
        let appleIconElement = document.querySelector('link[rel="apple-touch-icon"]')
        if (!appleIconElement) {
            appleIconElement = document.createElement('link')
            appleIconElement.setAttribute('rel', 'apple-touch-icon')
            document.querySelector('head').appendChild(appleIconElement)
        }
        let appleTitleElem = document.querySelector('meta[name="description"]')
        if (!appleTitleElem) {
            appleTitleElem = document.createElement('meta')
            appleTitleElem.setAttribute('name', 'description')
            document.querySelector('head').appendChild(appleTitleElem)
        }
        let applename = document.querySelector('meta[name="apple-mobile-web-app-title"]')
        if (!applename) {
            applename = document.createElement('meta')
            applename.setAttribute('name', 'apple-mobile-web-app-title')
        }
        applename.setAttribute('content', brand?.location_name)

        let androidmeta = document.querySelector('meta[name="theme-color"]')
        if (!androidmeta) {
            androidmeta = document.createElement('meta')
            androidmeta.setAttribute('name', 'theme-color')
            document.querySelector('head').appendChild(androidmeta)
        }
        if (isMobileUser) {
            androidmeta.setAttribute('content', brand?.mobile_top_banner_color)
        } else {
            androidmeta.setAttribute('content', brand?.web_background_color)
        }

        const head = document.querySelector('head')
        if (brand?.location_name) {
            head.appendChild(applename)
        }
        let body = document.querySelector('body')
        let name = brand?.location_name || ''
        appleTitleElem?.setAttribute('content', brand?.location_name)

        if (isMobileUser) {
            favicon.href = brand?.mobile_logo
            document.title = name
            let iconElement = document.querySelector('link[rel="icon"]')
            iconElement?.setAttribute('href', brand?.mobile_logo)
            appleIconElement?.setAttribute('href', brand?.mobile_logo)
            body.style.backgroundColor = brand?.mobile_top_banner_color
        } else {
            favicon.href = brand?.web_logo
            document.title = name
            appleIconElement?.setAttribute('href', brand?.web_logo)
            body.style.backgroundColor = '#fff'
        }

        let resizedUrl = isMobileUser ? await imageResize(brand?.mobile_logo) : await imageResize(brand?.web_logo)

        manifest.name = brand?.location_name
        manifest.short_name = brand?.location_name
        manifest.icons[0].src = resizedUrl[0]?.url
        manifest.icons[1].src = resizedUrl[1]?.url
        manifest.icons[2].src = resizedUrl[2]?.url
        manifest.icons[3].src = resizedUrl[3]?.url
        manifest.icons[4].src = resizedUrl[4]?.url
        manifest.icons[5].src = resizedUrl[5]?.url
        manifest.icons[6].src = resizedUrl[6]?.url
        manifest.icons[7].src = resizedUrl[7]?.url
        manifest.theme_color = brand?.mobile_top_banner_color
        manifest.start_url = `${window.location.origin}${isUser ? '/customer' : isDealer ? '/dealer-technician': '/'}`
        manifest.scope = `${window.location.origin}${isUser ? '/customer' : isDealer ? '/dealer-technician': '/'}`
        const stringManifest = JSON.stringify(manifest)
        let content = encodeURIComponent(stringManifest)
        let url = 'data:application/json;charset=utf-8,' + content
        let element = document.querySelector('link[rel="manifest"]')
        if (!element) {
            element = document.createElement('link')
            element.setAttribute('rel', 'manifest')
            element.setAttribute('id', 'manifest-sheet')
            document?.head?.appendChild(element)
        }

        element?.setAttribute('href', url)
    } else {
        manifest.start_url = `${window.location.origin}${isUser ? '/customer' : isDealer ? '/dealer-technician': '/'}`
        manifest.scope = `${window.location.origin}${isUser ? '/customer' : isDealer ? '/dealer-technician': '/'}`
        const stringManifest = JSON.stringify(manifest)
        let content = encodeURIComponent(stringManifest)
        let url = 'data:application/json;charset=utf-8,' + content
        let element = document.querySelector('link[rel="manifest"]')
        if (!element) {
            element = document.createElement('link')
            element.setAttribute('rel', 'manifest')
            element.setAttribute('id', 'manifest-sheet')
            document?.head?.appendChild(element)
        }

        element?.setAttribute('href', url)
    }
    return brand
}
