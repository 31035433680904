import React from 'react';
import { InputLabel, MenuItem, Select, styled } from '@mui/material';

const SelectContainer = styled('div')(({ theme }) => ({
  'display': 'flex',
  'justifyContent': 'center',
  'width': '100%',
  '& .MuiInputBase-root': {
    'width': '100%',
    'borderRadius': '8px'
  },
  '& .MuiSelect-select': {
    'padding': '8px 10px',
    'backgroundColor': '#F8F8F8',
  },
}));

const Label = styled(InputLabel)(({ theme }) => ({
  'fontSize': '16px',
  'fontWeight': '600',
  'marginBottom': '7px',
  'color': '#000',
  'lineHeight': 'normal'
}));

const SelectStyled = ({ label, options, className, errorText, id, ...props }) => {
  return (
    <>
      {label && (
        <Label htmlFor={id} className='field-label'>{label}</Label>
      )}
      <SelectContainer>
        <Select
          displayEmpty
          className={className}
          {...props}
        >
          {
            options.map(option => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))
          }
        </Select>
      </SelectContainer>
      {errorText}
    </>
  )
};

export default SelectStyled;
