import React, { forwardRef } from 'react';
import { useTranslation } from "react-i18next"
import styles from '../../styles/login.module.css';
import { StyleSheet, css } from 'aphrodite';
import { Colors } from '../../themes/Colors';

const styleComp = StyleSheet.create({
  rightElement: {
    position: 'absolute',
    right: '2rem',
    height: '100%',
    top: 0,
    paddingTop: '0.5rem',
    display: 'flex',
    alignItems: 'center'
  },
  rightElementNoPadding: {
    position: 'absolute',
    right: '0',
    height: '100%',
    top: 0,
    paddingTop: '0.5rem',
    display: 'flex',
    alignItems: 'center'
  }
});

const LoginInput = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const {
    placeholder,
    type = 'string',
    value,
    onChange,
    style = {},
    className = '',
    rightElement,
    rightStyle = {},
    noPaddingRight = false,
    ...otherProps
  } = props;
  let rightElemStyle = {
    position: 'absolute',
    right: '2rem',
    height: '100%',
    top: 0,
    paddingTop: '0.5rem',
    display: 'flex',
    alignItems: 'center'
  }

  const rightElementNoPadding = {
    position: 'absolute',
    right: '0',
    height: '100%',
    top: 0,
    paddingTop: '0.5rem',
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <div style={{ position: 'relative' }}>
      <input
        {...props}
        placeholder={t(placeholder)}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        value={value}
        type={type}
        className={styles.loginInput + ' ' + className}
        style={{ ...style, color: Colors.black }}
        autoComplete="off"
        ref={ref}
        {...otherProps}
      />
      {rightElement ? (
        <div
          style={{...(noPaddingRight ? rightElementNoPadding: rightElemStyle),  ...rightStyle, }}
          >
          {rightElement}
        </div>
      ) : null}
    </div>
  );
});

export default LoginInput;
