import { useEffect, useState } from 'react';
import { Menu, Pagination } from 'antd';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import BusinessIcon from '@mui/icons-material/Business';
import { useLocation, useNavigate } from 'react-router-dom';
import { Userpilot } from 'userpilot'
import TextInput from '../../common/inputs/TextInput';
import VendorProfile from '../../components/VendorProfile';
import LocationIcon from '../../assets/location_sign.png';
import Layout from '../../components/Layout';
import { useDeleteDealer, useGetDealers } from '../../container/hooks/useDealers';
import logo from '../../assets/jones_logo.png';
import Button from '../../common/Button';
import AddDealer from '../../components/AddDealer';
import Utils from '../../../utils';
import EmptyVendors from '../EmptyVendors';
import { translate, urlToFile } from '../../../helper';
import LocalStorage from '../../../defined/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '../../../themes/Colors';
import { useCreateOrder, useUpdateOrder } from '../../container/hooks/useOrders';
import OrderPlacedModal from '../../components/OrderPlacedModal';
import { UserAction } from '../../../redux/action';

const brand = JSON.parse(LocalStorage.getItem('branding'));

function Vendors(props) {
    const location = useLocation();
    const {
        isOrdering = false
    } = location.state || {};
    const newOrder = location.state;
    const whiteLabel = useSelector((state) => state.User?.branding);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isViewProfile, setIsViewPrifile] = useState(false);
    const [profile, setProfile] = useState({});
    const [showAddDealerModal, setShowAddDealerModal] = useState(false);
    const [orderBy, setOrderBy] = useState('address');
    const [searchInput, setSearchInput] = useState('');
    const [dealers, setDealers] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalVendors, setTotalVendors] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedID, setSelectedID] = useState([]);
    const [finalData, setFinalData] = useState({});
    const [isOrderPlaced, setIsOrderPlaced] = useState(false);
    const [orderResponse, setOrderResponse] = useState('');
    const [vendorSelectError, setVendorSelectError] = useState('');
    const endpointType = props.dt ? 'dealerTechnicianEndpoints' : 'userMobileEndpoints';

    const { getDealers, reset, error, isSuccess, isError } = useGetDealers(getDealersData, errorGetDealerData);
    const { createOrder, isCreatingOrder: isCreatingOrderLoading, isOrderError, orderError, resetOrder } =
        useCreateOrder(onCreateOrder, onCreateOrderError);
    const { updateOrder, isUpdatingOrder } = useUpdateOrder(onSuccessUpdate);

    const { deleteDealer, isDeletingDealer } = useDeleteDealer(
        onSuccessDelete,
        onErrorDelete
    );

    function onCreateOrder(data) {
        setFinalData(data);
        Userpilot.track('Order created');
        setIsOrderPlaced(true);
        setOrderResponse('success');
    }

    function onCreateOrderError(data) {
        setIsOrderPlaced(true);
        setOrderResponse('error');
    }

    function onSuccessUpdate() {
        navigate(Utils.EndPoint[endpointType].createOrder);
        LocalStorage.removeItem('newOrder');
        handleClearImages();
    }

    function onSuccessDelete(data) {
        setIsViewPrifile(false);
        Utils.showAlert(1, data.message || 'Dealer deleted successfully!!');
    }

    function onErrorDelete(data) {
        Utils.showAlert(2, data.message || 'Something went wrong!!');
    }

    const vendorId = dealers && dealers[0]?.vendor?.id;

    function getDealersData(data) {
        setDealers(data?.data || []);
        setTotalVendors(data?.page?.total_records || 0);
        setIsLoading(false);
    }

    function errorGetDealerData(data) {
        Utils.showAlert(1, data.message || 'Something went wrong!!');
        setIsLoading(false);
    }

    const handleClearImages = () => {
        dispatch(UserAction.resetOrderDetails());
    };

    const handleAddDealer = () => {
        setShowAddDealerModal(!showAddDealerModal);
        if (showAddDealerModal === true) {
            setIsLoading(true);
            getDealers({
                search: '',
                page: page,
                page_size: pageSize
            });
        }
    };

    const searchDealer = (value) => {
        setSearchInput(value);
    };

    const viewProfile = (id) => {
        let selectedProfile = dealers.filter(e => e.id === id);
        setProfile(selectedProfile);
        setIsViewPrifile(true);
    };

    const closeProfile = () => {
        setIsViewPrifile(false);
    };

    const handleOrderPlacedModal = (type) => {
        setIsOrderPlaced(!isOrderPlaced);
        if (isOrderPlaced) {
            if (type != 'err') {
                setSelectedID([]);
                LocalStorage.removeItem('newOrder');
                handleClearImages();
                navigate(Utils.EndPoint[endpointType].createOrder, { replace: true });
            }
        }
    };

    const handleSubmit = async (id) => {
        if (isCreatingOrderLoading) {
            return;
        }
        setVendorSelectError('');
        const formData = new FormData();
        for (let index = 0; index < newOrder?.images?.length; index++) {
            let newImg = await urlToFile(newOrder?.images[index]?.file, newOrder?.images[index]?.name);
            try {
                formData.append(`images[${index}]image`, newImg, newOrder?.images[index]?.name);
            } catch (error) {
                formData.append(`images[${index}]image`, newImg, newOrder?.images[index]?.name);
            }
        }
        Object.keys(newOrder.orderData).map(key => {
            if (key === 'images') return;
            let value = newOrder.orderData[key];
            if (key === 'quantity') {
                value = Number(value);
            }
            if (key === 'year') {
                formData.append('make', value);
            }
            if (key === 'order_name') {
                formData.append('name', value);
            }
            if (key === 'product_data') {
                formData.append('vin', value);
            }
            formData.append(key, value);
        });
        formData.append('dynamic_attributes', JSON.stringify(newOrder.dynamic_attributes));
        selectedID.map(vendor => {
            formData.append(`vendors`, vendor);
        });
        if (!id) {
            createOrder(formData);
        } else {
            updateOrder({ formData, id });
        }
    };

    const submitOrder = () => {
        if (selectedID?.length == 0) {
            setVendorSelectError('Please select a location');
            return;
        }
        handleSubmit(vendorId);
    };

    const orderOptions = (
        <Menu
            className="dropdown-logout"
            items={[
                {
                    label: translate('orderByLocation'),
                    key: '1',
                    onClick: () => {
                        setTimeout(() => {
                            setOrderBy('address');
                        }, 500);
                    },
                    className: 'item-dropdown-user menu-text'
                },
                {
                    label: translate('orderByName'),
                    key: '2',
                    onClick: () => {
                        setTimeout(() => {
                            setOrderBy('business_name');
                        }, 500);
                    },
                    className: 'item-dropdown-user menu-text'
                }
            ]}
        />
    );

    const handleDeleteVendor = (id) => {
        deleteDealer({ id });
    };

    useEffect(() => {
        setIsLoading(true);
        getDealers({
            search: searchInput,
            page: page,
            page_size: pageSize
        });
    }, [searchInput, page, pageSize]);

    const layoutProps = {
        ...(isOrdering && props?.backTo ? { backTo: () => props?.backTo() } : {})
    };
    return (
        <Layout showProfileIcon={!isOrdering} showNavigation={isOrdering} {...layoutProps} showNotification
            title={isOrdering ? 'selectLocation' : 'locations'}>
            <div
                style={{
                    width: '100%',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '22px 22px 0 0',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%'
                }}
            >

                <div className="filter-vendors" style={{ padding: '15px 20px 0 20px', height: '12%' }}>
                    <TextInput
                        rows={1}
                        margin={true}
                        value={searchInput}
                        onChange={({ target: { value } }) => searchDealer(value)}
                        placeholder={translate('searchLocation')}
                        className="filter-area"
                        outerStyle={{
                            margin: 0,
                            padding: '0px 20px 0 20px'
                        }}
                    />
                </div>
                {
                    !isLoading && dealers?.length > 0 ? (
                        <>
                            <div className="more-options" style={{
                                paddingRight: 30,
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                height: '6%',
                                margin: 0,
                                paddingTop: '10px 0'
                            }}>

                                <Pagination
                                    size="small"
                                    simple={{ readOnly: true }}
                                    total={totalVendors}
                                    current={page}
                                    onChange={setPage}
                                    showSizeChanger={true}
                                    pageSize={pageSize}

                                />

                            </div>
                            <div className="vendor-list" style={{
                                height: !isOrdering ? '82%' : '66%',
                                overflow: 'auto',
                                overflowX: 'hidden',
                                padding: '20px 20px'
                            }}>
                                {
                                    dealers?.map((vendor) => (
                                        <div key={vendor.id} className="vendor-profile-data">
                                            <div className="vendor-board">
                                                <div className="vendor-data">
                                                    <div className="vendor-profile">
                                                        {
                                                            vendor?.logo ? <img
                                                                onClick={() => isOrdering ? setSelectedID([vendor.id]) : viewProfile(vendor.id)}
                                                                src={vendor?.logo ? vendor.logo : logo} style={{
                                                                    width: '100px',
                                                                    height: '100px',
                                                                    objectFit: 'cover'
                                                                }} /> : <BusinessIcon
                                                                onClick={() => isOrdering ? setSelectedID([vendor.id]) : viewProfile(vendor.id)}
                                                                style={{ fontSize: '70px', color: Colors.primary }} />
                                                        }
                                                    </div>
                                                    <div className="vendor-info">
                                                        <div
                                                            onClick={() => isOrdering ? setSelectedID([vendor.id]) : viewProfile(vendor.id)}
                                                            className="vendor-label">{vendor.business_name || 'N/A'}</div>
                                                        {
                                                            vendor.description?.length > 0 ? <div
                                                                className="vendor-desc">{`${vendor.description?.substring(0, 100)}${vendor.description?.length > 100 ? '...' : ''}`}</div> :
                                                                <div style={{ height: '45px' }} />
                                                        }
                                                    </div>
                                                    {isOrdering && <div style={{ width: '20px' }} />}
                                                    <div className="vendor-select"
                                                        style={{ position: 'absolute', top: 0, right: '15px' }}>
                                                        {isOrdering ? <Checkbox
                                                            icon={<RadioButtonUncheckedIcon />}
                                                            checkedIcon={<CheckCircleIcon />}
                                                            checked={selectedID?.includes(vendor.id)}
                                                            sx={{
                                                                color: '#E5E7EB',
                                                                '&.Mui-checked': {
                                                                    color: '#10B981'
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedID([vendor.id]);
                                                            }}
                                                        /> : <></>}
                                                    </div>
                                                </div>
                                                <div className="vendor-address">
                                                    <img src={LocationIcon} />
                                                    <div style={{ fontSize: '11px' }}>{vendor.address}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {isOrdering && <div style={{
                                position: 'unset',
                                bottom: 120,
                                height: '12%',
                                width: '90%',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                {vendorSelectError ? <div style={{
                                    color: 'red',
                                    width: '100%',
                                }}>{vendorSelectError}</div> :
                                    <div style={{ width: '100%', marginTop: '1rem', padding: '10px' }} />}
                                <Button style={{ padding: '25px', marginTop: '0rem' }} onClick={submitOrder}>
                                    {isCreatingOrderLoading && <CircularProgress
                                        style={{ width: '15px', height: '15px', marginRight: '10px' }} />}
                                    {translate('sendToLocation')}
                                </Button>
                            </div>}
                        </>
                    ) : (
                        isLoading ? (
                            <CircularProgress />
                        ) : (
                            <EmptyVendors handleAddDealer={handleAddDealer} />
                        )
                    )
                }
            </div>
            <AddDealer
                open={showAddDealerModal}
                onClose={handleAddDealer}
            />
            <VendorProfile
                open={isViewProfile}
                data={profile}
                className="vendor-modal"
                close={closeProfile}
                onSubmit={handleDeleteVendor}
            />
            <OrderPlacedModal
                open={isOrderPlaced}
                onClose={handleOrderPlacedModal}
                mode={orderResponse}
                data={{
                    po: finalData?.id,
                    order: finalData?.name,
                    created: finalData?.created_at?.split(' ')[0]
                }}
            />
        </Layout>
    );
}

export default Vendors;
