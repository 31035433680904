import React from "react";
import styles from "../../styles/login.module.css";
const FormArea = (props) => {
  const {
    placeholder,
    type = "string",
    value,
    onChange,
    style = {},
    className = "",
    rightElement,
    containerStyle = {},
    ...otherProps
  } = props;
  return (
    <div style={{ position: "relative", ...containerStyle }}>
      <textarea
        placeholder={placeholder}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        value={value}
        type={type}
        className={styles.formArea + " " + className}
        style={style}
        {...otherProps}
      />
    </div>
  );
};
export default FormArea;
