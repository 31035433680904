import { buildQueryString } from "../../../utils/CommonFunction";
import Utils from "../../../utils"
import { failedOrderListReducer, loaderListener, successOrderListReducer } from '../Slice/orderListSlice';

export const fetchOrderListApiCall = (values, dispatch) => {

    const {
        pageNo,
        limit,
        searchKey,
        vendors__id,
        ordering,
    } = values;

    let dataToSend = {
        search: searchKey,
        ordering,
        vendors__id,
    }

    if(pageNo) {
        dataToSend.page = pageNo;
    }
    if(limit) {
        dataToSend.page_size = limit;
    }
    // Define an array of keys to check for falsy values
    const keysToDelete = ['search', 'ordering'];

    // Iterate through the keys and delete them if they are falsy in dataToSend
    keysToDelete.forEach(key => {
        if (!dataToSend[key]) {
            delete dataToSend[key];
        }
    });

    dispatch(
        loaderListener({
            loading: true
        })
    );

    const result = buildQueryString(dataToSend);
    Utils.api.getApiCall(
        `${Utils.EndPoint.vendorEndpoints.orderApi}`,
        Object.keys(dataToSend).length == 0 ? '' : `?${result}`,
        (respData) => {
            const { data } = respData;
            dispatch(
                loaderListener({
                    loading: false
                })
            );

            dispatch(
                successOrderListReducer({
                    data: data?.data || data,
                    total: data?.page?.total_records || data.length,
                    loading: false
                })
            );
        },
        (error) => {
            Utils.showAlert(2, error.message);
            dispatch(failedOrderListReducer(error.detail));
        }
    );

}