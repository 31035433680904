import React, { memo, useRef } from 'react'
import { Avatar, Badge, IconButton, Stack, styled, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Utils from '../../utils'
import MainLogo from '../../../src/assets/image/png/Logotheme.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getValueFromObjectByKeys, translate } from '../../helper'
import LocalStorage from '../../defined/localStorage'
import { UserAction } from '../../redux/action'
import withNavigate from '../../component/WithNavigate'
import ImageZoom from '../../common/image-zoom'
import bellring from '../../assets/image/svg/notification.svg'
import ReconnectingWebSocket from 'reconnecting-websocket'
import { userDetailApiCall } from '../modules/UserProfile/Action'

const brand = JSON.parse(LocalStorage.getItem('branding'))

const StyledHeader = styled('div')(({ theme, isLarge, authscreen, showAddOrder }) => {
    const whiteLabel = useSelector((state) => state.User?.branding)
    return ({
        'backgroundColor': authscreen ? '#fff': whiteLabel?.mobile_top_banner_color || brand?.mobile_top_banner_color || '#ffd22f',
        'padding': authscreen ? '30px 0': '0px 0px 10px 0px',
        'textTransform': 'uppercase',
        'display': authscreen? 'block':'flex',
        'alignItems': 'flex-end',
        'justifyContent': 'center !important',
        'height': authscreen? 'auto': showAddOrder? '15%': isLarge ? '18%' : '10%',
        'position': authscreen ? 'relative': 'unset',
        'minHeight': '80px',
        '& .imageContainer': {
            'backgroundImage': 'url("../../Images/signupTop.png")',
            'backgroundPosition': 'center',
            'backgroundRepeat': 'no-repeat',
            'backgroundSize': 'cover',
            'display': 'flex',
            'position': 'relative',
            'height': '100%',
        },
        '& button': {
            'color': '#fff'
        },
        '& .MuiBadge-badge': {
            'right': '1px !important',
            'background': 'red',
            'fontWeight': '700'
        }
    })
})

function Logo() {
    return (
        <svg style={{width: "40px", height: "40px"}} focusable="false" aria-hidden="true" viewBox="0 0 49 49" data-testid="AddCircleIcon" class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv">
            <style type="text/css">
                {
                    "\n.st0 {\nopacity: 0.3;\nfill-rule: evenodd;\nclip-rule: evenodd;\nfill: #FFFFFF;\n}\n.st1 {\nfill-rule: evenodd;\nclip-rule: evenodd;\nfill: rgba(255, 255, 2550.3);\n}\n"
                }
            </style>
            <circle id="Circle" class="st0" cx="24.5" cy="24.5" r="24.5"></circle>
            <path id="Plus" class="st1" d="M36.6,25.5H25.2v11.1h-1.7V25.5H12.4v-1.7h11.1V12.4h1.7v11.4h11.4V25.5z"></path>
        </svg>
    )
}

function MobileHeader ({
    title,
    showCentreProfile,
    noAuth,
    showNotification,
    showProfileIcon,
    showNavigation,
    backTo,
    notificationsData,
    getNotifications,
    getNotificationsCount,
    showLargeHeader = false,
    centreProfile,
    withoutname,
    authscreen,
    showAddOrder
}) {
    const { data: authData } = useSelector(state => state.auth)
    const { data: userDetail, loading } = useSelector(state => state.user_detail)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const whiteLabel = useSelector((state) => state.User?.branding)
    const location = useLocation()
    const { pathname: activeURL } = location
    const dt = activeURL.includes('/dealer')
    const endpointType = dt ? 'dealerTechnicianEndpoints' : 'userMobileEndpoints'
    const wsNotifications = useRef(null)
    React.useEffect(() => {
        if (!authData.token) {
            return
        }

        wsNotifications.current = new ReconnectingWebSocket(
            `wss://${Utils.constants.API_URL.replace('https://', '')}ws/home/notifications/?token=${authData.token}`
        )


        // wsNotifications.current.addEventListener('message', (e) => {
        //     if(e.type === 'open'){
        //         getNotificationsCount()
        //         if(window.location.pathname.includes('user-notification')){
        //             getNotifications()
        //         }
        //     }
        // })

        if (authData.token) {
            getNotificationsCount()
            userDetailApiCall(dispatch, authData)
        }
    }, [authData.token])

    return (
        <StyledHeader authscreen={authscreen} isLarge={showLargeHeader} showAddOrder={showAddOrder}>
            {
                noAuth ? (
                    <div className={`imageContainer ${authscreen?'auth-image-container':''}`}>
                        <Avatar
                            alt="Profile Image"
                            sx={{ width: 60, height: 60, borderRadius: '5px' }}
                            src={whiteLabel?.mobile_logo || brand?.mobile_logo || MainLogo}
                        />
                    </div>
                ) : (

                    <div 
                        style={{ 
                            width: '100%', 
                            height: '85%', 
                            marginBottom: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }} 
                    >
                        <Stack
                            style={{ 
                                width: '90%', 
                                height: showAddOrder? '50%':'100%', 
                            }} 
                            direction="row"
                            justifyContent={'space-between'}
                            alignItems={'center'} 
                            margin={'0 20px'}
                        >
                            {
                                showProfileIcon ? (
                                    <ImageZoom
                                        imageSrc={userDetail?.profile_image}
                                        altSrc={userDetail?.profile_image}
                                        fullScreen
                                    >
                                        <Avatar
                                            alt="Profile Image"
                                            sx={{ width: 40, height: 40, border: '1px solid white', borderRadius: '50px' }}
                                            src={userDetail?.profile_image}
                                        />
                                    </ImageZoom>
                                ): !showNavigation ? <div style={{width: "40px"}} />: <></>
                            }
                            {
                                showNavigation && (
                                    <IconButton
                                        onClick={() => {
                                            if (backTo) {
                                                backTo()
                                            } else {
                                                navigate(-1)
                                            }
                                        }}
                                    >
                                        <ArrowBackIosIcon/>
                                    </IconButton>
                                )
                            }
                            {
                                !showProfileIcon && !showNavigation && !showCentreProfile && centreProfile && <div style={{ width: '40px' }}/>
                            }
                            {
                                title && (
                                    <Typography color="#fff" fontSize="18px" fontWeight={'600'}
                                                sx={{ textTransform: 'uppercase' }}>{translate(title)}</Typography>
                                )
                            }
                            {
                                showCentreProfile && (
                                    <ImageZoom
                                        imageSrc={userDetail?.profile_image}
                                        altSrc={userDetail?.profile_image}
                                        fullScreen
                                    >
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            marginBottom: !withoutname ? '0px' : '5px'
                                        }}>
                                            <Avatar
                                                alt="Profile Image"
                                                sx={{
                                                    width: withoutname ? 55 : 75,
                                                    height: withoutname ? 55 : 75,
                                                    border: '1px solid white',
                                                    borderRadius: '50px',
                                                    marginBottom: !withoutname ? '12px' : '0px'
                                                }}
                                                src={userDetail?.profile_image}
                                            />
                                            {!withoutname && <Typography marginTop={'2px'} color={'white'} lineHeight={'15.9px'}
                                                                        fontWeight={'600'}
                                                                        variant="span">{userDetail?.full_name}</Typography>}
                                        </div>
                                    </ImageZoom>
                                )
                            }
                            {
                                showNotification && (
                                    <IconButton onClick={() => navigate(Utils.EndPoint[endpointType].userNotification)}>
                                        <Badge
                                            badgeContent={notificationsData.unread_count}>
                                            <img style={{ width: '30px', height: '30px' }} src={bellring}/>
                                        </Badge>
                                    </IconButton>

                                )
                            }
                            {
                                !showNotification && (
                                    <IconButton>
                                        <ArrowBackIosIcon style={{ visibility: 'hidden' }}/>
                                    </IconButton>
                                )
                            }
                        </Stack>
                        {
                            showAddOrder && (<div 
                                class="MuiStack-root css-plusicon"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                    cursor: "pointer"
                                }}
                                onClick={() => navigate(Utils.EndPoint[endpointType].addNewOrder)}
                            >
                                <Logo/>
                                <div style={{marginLeft: "10px"}} class="css-addorder">
                                    <h3 style={{margin: 0, fontSize: "16px", color: "#fff", textTransform: "capitalize"}}>Add Order</h3>
                                    <p style={{margin: 0, fontSize: "14px", color: "#fff", textTransform: "capitalize", fontWeight: "200"}}>Start a new order...</p>
                                </div>
                            </div>)
                        }
                    </div>
                )
            }

        </StyledHeader>
    )
}

const mapStateToProps = (state) => {
    return {
        notificationsData: getValueFromObjectByKeys(state, ['User', 'notifications'])
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getNotifications: () => dispatch(UserAction.getNotifications()),
        getNotificationsCount: () => dispatch(UserAction.getUnreadNotifCount()),
    }
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(withNavigate(MobileHeader)))
