import { translate } from '../helper';

export const unitType = {
    metric: 'metric',
    imperial: 'imperial'
};

export const quotesStatus = {
    SENT: 'SENT',
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
    NS: 'NS'
};
export const orderStatusMap = {
    PROCESSING: 'PROCESSING',
    DELIVERED: 'DELIVERED',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
    NS: 'NS'
};