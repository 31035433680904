import React from 'react';
import { TextField, styled } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { translate } from '../../helper';

const StyledInput = styled(TextField)(({ theme, style }) => ({
    '& .MuiOutlinedInput-root': {
        'borderRadius': '8px',
        'backgroundColor': '#F8F8F8',
        'padding': '0px',
        '& :disabled': {
            'color': 'rgba(0, 0, 0, 0.21)'
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        'borderColor': '#C8C8C8'
    },
    '& .MuiFormHelperText-root': {
        'marginLeft': '0px',
        'borderRadius': 'inherit',
    },
    '& input': {
        'padding': '8px 10px',
        'height': style ? style.height: 'inherit'
    }
}));

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
    'boxSizing': 'border-box',
    'background': '#F8F8F8',
    'width': '100%',
    'padding': '8px 12px',
    'borderRadius': '8px',
    'borderColor': '#C8C8C8'
}));

export const Label = styled('label')(({ theme }) => ({
    'fontSize': '16px',
    'fontWeight': '400',
    'marginBottom': '5px',
    'color': '#000',
    'lineHeight': 'normal',
    'maxWidth': '270px',
    'flex': '0 0 270px',
}));

export default function TextInput({
    label,
    placeholder,
    value,
    onChange,
    id,
    name,
    onBlur,
    onFocus,
    onClick,
    error,
    type,
    asTextArea,
    rows,
    disabled,
    className,
    InputProps,
    fullWidth,
    helperText,
    minRows,
    style={},
    ...rest
}) {
    const MainComponent = asTextArea ? StyledTextArea : StyledInput;
    return (
        <>
            {label && (
                <Label htmlFor={id} className='field-label'>{label}</Label>
            )}
            <MainComponent
                className={className}
                data-testid={rest['data-testid']}
                placeholder={translate(placeholder)}
                value={value}
                onChange={onChange}
                id={id}
                name={name}
                onBlur={onBlur}
                onFocus={onFocus}
                onClick={onClick}
                error={error}
                type={type}
                disabled={disabled}
                InputProps={InputProps}
                fullWidth={fullWidth}
                helperText={translate(helperText)}
                minRows={minRows}
                style={style}
            />
        </>
    );
}
