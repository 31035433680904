import { buildQueryString } from "../../../utils/CommonFunction";
import Utils from "../../../utils"
import { failedOrderDetailReducer, loaderListener, successOrderDetailReducer } from '../Slice/orderDetailSlice';
import { orderStatusMap } from "../../../defined";

export const fetchOrderDetailApiCall = (dispatch, orderId) => {
  let dataToSend = {};

  dispatch(
    loaderListener({
      loading: true,
    })
  )
  const result = buildQueryString(dataToSend);

  Utils.api.getApiCall(
    `${Utils.EndPoint.vendorEndpoints.orderApi}${orderId}/`,
    Object.keys(dataToSend).length == 0 ? '' : `?${result}`,
    (respData) => {
      const { data } = respData
      dispatch(
        successOrderDetailReducer({
          data: data,
        })
      )
      dispatch(
        loaderListener({
          loading: false,
        })
      )
    },
    (error) => {
      Utils.showAlert(2, error.detail)
      dispatch(failedOrderDetailReducer(error.detail));
    }
  )
}

export const updateOrderApiCall = (orderId, values, setSubmitting, refetch) => {
  const dataToSend = values;

  Utils.api.patchApiCall(
    `${Utils.EndPoint.vendorEndpoints.orderUpdate}${orderId}/`,
    dataToSend,
    (respData) => {
      const { data } = respData;
      Utils.showAlert(1, 'Quote Sent')
      setSubmitting(false);
      refetch();
    },
    (error) => {

      const { data } = error
      Utils.showAlert(2, data.message || data?.non_field_errors[0])
      setSubmitting(false);
    }
  )
}

export const createQuoteApiCall = (values, orderDetail, setSubmitting, refetch) => {
  const dataToSend = values;

  Utils.api.postApiCall(
    `${Utils.EndPoint.vendorEndpoints.quoteApi}`,
    dataToSend,
    (respData) => {
      const { data } = respData
      const { id, quotes = [] } = orderDetail;
      const newQuotesData = quotes.map((x) => x.id);
      newQuotesData.push(data?.id);
      updateOrderApiCall(orderDetail.id,
        {
          quotes: newQuotesData,
          status:
            orderDetail?.status !== orderStatusMap.NS ? orderDetail?.status : orderStatusMap.PENDING
        },
        setSubmitting, refetch);
    },
    (error) => {

      const { data } = error
      Utils.showAlert(2, data.message || data?.non_field_errors[0])
      setSubmitting(false);
    }
  )
}

export const updateQuoteApiCall = (quoteId, values, setSubmitting, refetch) => {
  const dataToSend = values;

  Utils.api.patchApiCall(
    `${Utils.EndPoint.vendorEndpoints.quoteApi}${quoteId}/`,
    dataToSend,
    (respData) => {
      const { data } = respData
      Utils.showAlert(1, 'Quote Updated')
      setSubmitting(false);
      refetch(data);
    },
    (error) => {

      const { data } = error
      Utils.showAlert(2, data.message || data?.non_field_errors[0]);
      setSubmitting(false);
    }
  )
}

export const updateWarrantyApiCall = (orderId, values, setLoading, refetch) => {
  const dataToSend = values;
  setLoading(true);
  Utils.api.postApiCall(
    `${Utils.EndPoint.vendorEndpoints.updateWarratyStatus}${orderId}/`,
    dataToSend,
    (respData) => {
      const { data } = respData
      setLoading(false);
      refetch(data);
    },
    (error) => {

      const { data } = error
      Utils.showAlert(2, data.message || data?.non_field_errors[0])
      setLoading(false);
    }
  )
}
