export const Colors = {
  black: '#000000',
  primary: '#FBB516',
  grey: '#6B7280',
  mainColor: '#FBB516',
  pageBackground: '#F9F9F9',
  greyBg: '#F3F4F6',
  grey2: '#202224',
  grey3: '#C8C8C8',
  grey4: '#F8F8F8',
  grey5: '#383938',
  success: '#10B981',
  stripe: '#635BFF',

  activeMenu: '#FFD700',
  white: '#FFFFFF',
  txtBlackButton: '#21211D',
  blackTitle: '#272727',
  transparent: 'transparent',
  black80: 'rgba(0, 0, 0, 0.8)',
  black30: '#0000004D',
  bgOverlayVideo: 'rgba(3, 3, 3, 0.2)',
  borderLine: '#3030304D',
  borderSelect: '#D6D6D6',
  bgSubSelect: '#EDF12080',
  backTitle: '#2c2c2c',
  borderOptionGroup: '#D1D1D1',
  bgOptionGroup: '#EAEAEA',
  white20: '#ffffff33',
  white55: '#ffffff55',
  titleItemSchedule: '#2F2F2B',
  descItemSchedule: '#2D2D2D',
  noteVideoHelpIntroduction: '#1F2231',
  bgApp: '#F5F5F5',
  borderButton: '#3E3F40',
  red: '#FF2020',
  link: '#0000ff',
  gray6: '#F2F2F2',
  gray2: '#4F4F4F',
  gray3: '#4F4F4F',
  titleSignIn: '#202224',
  titleSignIn80: '#202224CC',
  titleSignIn60: '#20222499',
  mainColor_14: '#F8B21824',
  borderCheckbox: '#A3A3A3',
  bgSider: '#191919',
  content_page: '#F9F9F9',
  gray300: '#D1D5DB',
  txt_sort: '#374151',
  quote_send: '#2F80ED',
  quote_send_14: '#2F80ED24',
  open_order: '#10B981',
  border_pagination: '#E5E7EB',
  gray4: '#BDBDBD',
  titleTable: '#6B7280',
  titlePage: '#111827',
  titleFieldPopup: '#ADADAD',
  txtSearch: '#435A70',
  titleChart: '#6F6F6F',
  txtFilterChart: '#939393',
  neutral50: '#DDDDDD',
  borderBoxChart: '#D7DCE0',
  subTitleChart: '#353F50',
  titleTableChart: '#677684'
};
