import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { copyToClipboard } from '../../utils/CommonFunction'
import FileCopyIcon from '@mui/icons-material/FileCopy'

const InfoCard = (props) => {
    return (
        <Box style={props.style} className={'order-details'}>
            {
                props.info.map(i => {
                    if (!i) return null
                    return (
                        <React.Fragment key={i.label}>
                            <Typography fontWeight={'600'} className="label">{i.label}</Typography>
                            <Typography fontWeight={'600'}
                                className="value" {...i?.valueTypoProps}>{i.value || i.defaultValue}
                            </Typography>
                            {
                                i?.copyLabel && i.value
                                && <Button
                                    className={'action'}
                                    onClick={() => copyToClipboard(i.value)}>
                                    <FileCopyIcon style={{ color: 'black' }} sx={{ fontSize: 14 }}/>
                                </Button>
                            }
                        </React.Fragment>
                    )
                })
            }
        </Box>
    )
}

export default InfoCard
