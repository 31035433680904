import { styled } from '@mui/material'

const PageBox = styled('div')(({ theme }) => ({
    'height': '100%',
    '& .title ': {
        'backgroundColor': 'white',
        'padding': '20px 0px 20px 0px',
        'fontSize': '18px',
        'fontWeight': '500',
        'overflowY': 'hidden',
        'textAlign': 'center',
        'borderRadius': '22px 22px 0px 0px',
        'textTransform': 'uppercase',
        'height': '10%',
    },
}));

export default PageBox;
