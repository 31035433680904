import React from 'react';
import Switch from 'react-switch';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import TextBase from '../text';
import { convertPxToRem } from '../../helper';
import { Colors } from '../../themes/Colors';
import SaveIcon from '../../assets/image/svg/save-25.svg';

import MonthlyIcon from '../../assets/image/svg/monthly_icon.svg';
import YearlyIcon from '../../assets/image/svg/yearly_icon.svg';
import ButtonGradient from '../button-gradient';
import { yellow } from '@mui/material/colors';
import { formatCurrency } from '../../utils/CommonFunction';

dayjs.extend(LocalizedFormat);

export const SubscriptionCard = ({
  title = '',
  subTitle = '',
  subscription = {},
  trial = [],
  onChange = () => {},
  isYearly = true,
  terms,
  pricelist=[],
  childAccount=0,
  onPurchase=()=>{},
  payForAllChildAcount=true,
  showEmpty=true,
  style={},
  subContent=<></>
}) => {
  const trialObj = trial?.find((item) => item?.subscription_price === subscription?.id);
  
  let period = subscription?.recurring_interval
  return (
    <div style={{ width: '70%', maxWidth: "800px", ...style }}>
      {!showEmpty&&<div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'center' }}>
        <TextBase
          style={{ opacity: isYearly ? 0.5 : 1 }}
          fontWeight={500}
          size={convertPxToRem(14)}
          color={Colors.black}>
          payMonthly
        </TextBase>
        <div className="react-switch" style={{ margin: '0px 20px' }}>
          <Switch
            checked={isYearly}
            onColor={Colors.primary}
            offColor="#E5E7EB"
            checkedHandleIcon={<div />}
            inputMode="none"
            onChange={onChange}
            uncheckedIcon={<div />}
            checkedIcon={<div />}
            disabled={pricelist?.length<=1}
          />
        </div>

        <div style={{ position: 'relative' }}>
          <TextBase
            style={{ opacity: isYearly ? 1 : 0.5 }}
            fontWeight={500}
            size={convertPxToRem(14)}
            color={Colors.black}>
            payYearly
          </TextBase>

          {isYearly && (
            <div
              style={{
                height: '100px',
                width: '100px',
                position: 'absolute',
                top: '-60px',
                right: '-100px',
                // backgroundColor: "yellow"
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: '100px',
                  width: '100px',
                  // backgroundColor: "red"
                }}
              >
                <span
                  style={{
                    display: "flex",
                    fontSize: "12px",
                    position: "absolute",
                    bottom: "65px",
                    transform: "rotate(-12deg)",
                    left: "2px",
                    fontWeight: 500
                  }}
                >
                  Save {Number(subscription?.discount)?.toFixed(2)?.replace(/\.00$/, '')}%
                </span>
                <img
                  src={SaveIcon}
                  style={{
                    height: '70px',
                    width: '70px',
                    position: "absolute",
                    bottom: '25px'
                  }}
                />
              </div>
            </div>
            
          )}
        </div>
      </div>}

      {trialObj?.is_trial_active ? (
        <div
          style={{
            display: 'flex',
            marginBottom: '20px',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <TextBase
            style={{ opacity: 1 }}
            fontWeight={600}
            size={convertPxToRem(24)}
            color={Colors.black}>
            getFreeTrial
          </TextBase>
          <TextBase
            style={{ opacity: 1, textAlign: 'center' }}
            fontWeight={400}
            size={convertPxToRem(16)}
            color={Colors.black}>
            lockCodeStatement{'\n '}
            {dayjs()
              .add(trialObj?.trial_period_days || 0, 'day')
              .format('MMM D, YYYY')}
          </TextBase>
        </div>
      ) : null}

      <div
        style={{
          minHeight: '200px',
          backgroundColor: '#000000',
          border: ' 6px solid #FBB516',
          borderRadius: '12px',
          padding: '32px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <img
          src={isYearly ? YearlyIcon : MonthlyIcon}
          style={{ height: '60px', width: '60px', marginBottom: '16px' }}
        />

        <TextBase
          style={{ textAlign: 'center', marginBottom: '16px' }}
          fontWeight={600}
          size={convertPxToRem(32)}
          color={Colors.white}>
          {title}
        </TextBase>

        <div
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <TextBase style={{}} fontWeight={600} size={convertPxToRem(24)} color={Colors.primary}>
            $
          </TextBase>

          <TextBase style={{}} fontWeight={600} size={convertPxToRem(56)} color={Colors.white}>
            {childAccount?formatCurrency(Number(subscription?.price)*childAccount): formatCurrency(subscription?.price)}
          </TextBase>
          
          <TextBase style={{}} fontWeight={600} size={convertPxToRem(24)} color={Colors.white55}>
            {`/${period}`}
          </TextBase>
        </div>

        <TextBase
          style={{ textAlign: 'center', marginBottom: '16px' }}
          fontWeight={400}
          size={convertPxToRem(24)}
          color={Colors.primary}>
          {subTitle}
        </TextBase>
        <div style={{color: "#fff", textAlign: "center", marginBottom: "20px"}}>
          {subContent}
        </div>
        <div
          style={{
            height: '2px',
            alignSelf: 'stretch',
            opacity: 0.15,
            background: '#FFF',
            marginBottom: '16px'
          }}
        />
        {
          terms.length > 0 && <ul>
            { 
              terms.length===1 ? <TextBase
                fontWeight={400}
                size={convertPxToRem(12)}
                color={Colors.white}
              >
                {terms[0]}
              </TextBase>: terms.map(term=> (
                <li
                  style={{
                    color: "#fff"
                  }}
                >
                  <TextBase
                    fontWeight={400}
                    size={convertPxToRem(12)}
                    color={Colors.white}
                  >
                    {term}
                  </TextBase>
                </li>
              ))
            }
          </ul>
        }
        
        {/* <TextBase
          style={{ marginTop: '16px' }}
          fontWeight={400}
          size={convertPxToRem(12)}
          color={Colors.greyBg}>
          automaticalyRenewsuntilCancelled
        </TextBase> */}
      </div>
      <ButtonGradient 
        style={{
          marginTop: "50px",
          width: "100%"
        }}
        title={'PURCHASE'}
        disabled={!payForAllChildAcount}
        onClick={onPurchase}
      />
    </div>
  );
};
