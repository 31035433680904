import { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import { useAddDealer } from "../../container/hooks/useDealers"
import Utils from '../../../utils';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    p: 4,
};

export default function AddDealerModal(props) {
    const {
        open,
        onClose
    } = props
    const [dealerCode, setDealerCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const {addDealer, reset, isAddingDealer, isSuccess, data, isError, addDealerError} = useAddDealer(getDealerData, errorFetDeaterData)


    const handleSumbit = () => {
        let test = /^[A-Za-z0-9]*$/.test(dealerCode);
        
        if (test) {
            addDealer({dealer_code:dealerCode})
            setCodeError(false)
        } else {
            setCodeError(true)
        }
        // 
    }
    function getDealerData(data) {
        
        onClose()
    }

    function errorFetDeaterData(data) {
        // 
        Utils.showAlert(2, data.message||Object.values(data)?.[0]?.[0]||'Something went wrong')
    }

    const handleDealerChange = (value) => {
        setDealerCode(value)
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <h2
                    style={{
                        textAlign: 'center',
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '36px'
                    }}
                >
                    Add dealer
                </h2>
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '24px',
                    }}
                >
                    Enter the dealer code in the field below to add them to your dealer list.
                </div>
                <TextInput 
                    placeholder='Enter dealer code'
                    style={{
                        marginTop: "21px",
                    }}
                    value={dealerCode}
                    onChange={handleDealerChange}
                />
                {codeError&&<p style={{textAlign: "center", color: "#FF2020"}}>please enter valid code</p>}
                <Button onClick={handleSumbit} style={{marginTop: "2rem"}}>ADD</Button>
            </Box>
        </Modal>
    )
}