import React from 'react';
import { CircularProgress, Dialog, DialogActions, DialogContent, Grid, styled } from '@mui/material';
import { sendMessageApiCall } from '../../common/chat/Action';
import { Form as FormikForm, Formik } from 'formik';
import { messageSchema } from '../../schema/vendorSchema';
import { Label } from '../../common/form/TextInput';
import { translate } from '../../helper';
import { TextField } from '../../common/form/input';
import { FormActionButton } from './components';
import CloseIcon from '@mui/icons-material/Close';

const ReplyChatWrapper = styled('div')(({ theme }) => ({
  '& .MuiDialogContent-root': {
        'backgroundColor': '#F3F4F6',
        'width': '600px',
        'padding': '10px',
        'borderRadius': '10px',
        'marginBottom': '10px'
    },
    '& .detals': {
        'width': '30%'
    }
}));
const ReplyChatModal = ({ isOpen, onClose, orderDetail, user, vendorId }) => {

  const handleSubmit = (values, actions) => {
    sendMessageApiCall(
      {
        ...values,
        vendor_id: vendorId,
        order_id: orderDetail.id
      },
      actions.setSubmitting,
      () => {
        onClose();
      }
    );
  };


  return (

    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '600px'  // Set your width here
          }
        }
      }}
    >
      <ReplyChatWrapper>
        <DialogContent>
          <Formik
            initialValues={{
              body: ''
            }}
            onSubmit={handleSubmit}
            validationSchema={messageSchema}
          >
            {({ isSubmitting, values, errors, touched }) => (

              <FormikForm>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Label>{translate('reply')}</Label>
                  </Grid>
                  <Grid style={{textAlign: "right"}} item xs={4}>
                    <CloseIcon onClick={onClose} style={{fontSize: "20px"}} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={values.body}
                      type="text"
                      id="body"
                      name="body"
                      fullWidth
                      asTextArea
                      minRows={8}
                      placeholder={translate('enterReply')}
                      error={Boolean(touched.body && errors.body)}
                      helperText={touched.body && errors.body}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <DialogActions>
                    <FormActionButton
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={24}
                                                        color={'inherit'} /> : translate('sendMessage')}
                    </FormActionButton>
                  </DialogActions>
                </Grid>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
      </ReplyChatWrapper>
    </Dialog>

  );
};

export default ReplyChatModal;