import Utils from "../../../../utils"
import { loaderListner, successUserDetailReducer } from "../Slice/userProfileSlice"
import { signin } from "../../Auth/Slice/authSlice"

export const userDetailApiCall = (dispatch, authData, callback=(d) => {}) => {
    dispatch(
        loaderListner({
            loading: true,
        })
    )

    Utils.api.getApiCall(
        Utils.EndPoint.userMobileEndpoints.profileApi,
        ``,
        (respData) => {
            const { data } = respData
            dispatch(signin({ data: {...authData, user: {...authData.user, profile: data}} }))
            // Utils.showAlert(1, data.message)
            dispatch(
                successUserDetailReducer({
                    data: data
                })
            )
            dispatch(
                loaderListner({
                    loading: false,
                })
            )
            callback(data);
        },
        (error) => {
            const { data } = error
            Utils.showAlert(2, error.message)
        }
    )
}

export const updateUserApiCall = (values, setSubmitting, dispatch, refetch) => {
    dispatch(
        loaderListner({
            loading: true,
        })
    );  
    Utils.api.patchApiCallForm(
        `${Utils.EndPoint.userMobileEndpoints.profileApi}`,
        values,
        (respData) => {
            const { data } = respData
            Utils.showAlert(1, "Profile Updated Successfully")
            setSubmitting(false)
            dispatch(
                loaderListner({
                    loading: false,
                })
            );
            
            // login({username})
            // getProfile()
            // dispatch(signin({ data: data }))
            refetch();
        },
        (error) => {
            dispatch(loaderListner({ loading: false }))
            const { data } = error
            
            Utils.showAlert(2, 'Something went Wrong')
        }
    )
}

export const sendOTPAPI = (values, callback) => {
    Utils.api.postApiCall(
        `${Utils.EndPoint.userMobileEndpoints.sendOTPAPI}`,
        values,
        (respData) => {
            const { data } = respData
            Utils.showAlert(1, "OTP sent successfully");
            callback();
        },
        (error) => {
            const { data } = error
            Utils.showAlert(2, data.phone_number[0] || 'Something went Wrong')
        }
    )
}

export const verifyOTPAPI = (values, callback) => {
    Utils.api.postApiCall(
        `${Utils.EndPoint.userMobileEndpoints.verifyOTPAPI}`,
        values,
        (respData) => {
            const { data } = respData
            Utils.showAlert(1, "OTP has been verified")
            callback();
        },
        (error) => {
            const { data } = error
            Utils.showAlert(2, data?.otp[0] || 'Something went Wrong')
        }
    )
}

export function getProfile(values) {
    Utils.api.getApiCall(
        `/accounts/user-profile/`,
        '',
        async (respData) => {
            const data = await respData.json()
            
            // Utils.showAlert(1, "OTP has been verified")
        },
        (error) => {
            // const { data } = error
            // Utils.showAlert(2, 'Something went Wrong')
        }
    )
}