import './App.css';
import RouteNavigation from './router';
import { Provider } from 'react-redux';
import './themes/colors.css';
import ConfigStore from './redux/store';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import nProgress from 'nprogress';
import { UserAction } from './redux/action';
import PushNotifications from './component/PushNotifications';

import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@mui/material';

const { store, persistor } = ConfigStore();

export const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    store.dispatch(UserAction.saveBranding());
    const idHeader = 'header-private';
    const elProgress = document.getElementById(idHeader);
    if (elProgress) {
      nProgress.configure({
        parent: idHeader
      });
    }
    const { width, height } = window.screen;
    const htmlTag = document.getElementsByTagName('html')[0];
    const baseFontSize = 16;
    htmlTag.style.fontSize = Math.min(baseFontSize * (width / 1440), baseFontSize * (height / 900));
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: 'poppins'
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ToastContainer />
        <QueryClientProvider client={queryClient}>
          <div className="App">
            <PushNotifications />
            <RouteNavigation />
          </div>
        </QueryClientProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
