import Config from '../Config'
import { unitType } from '../defined'
import LocalStorage from '../defined/localStorage'
import queryString from 'query-string'
import { message } from 'antd'
import numeral from 'numeral'
import _ from 'lodash'
import moment from 'moment'
import { ROUTER_NAME } from '../router/routers'
import dayjs from 'dayjs'
import i18next from 'i18next'

import countries from '../utils/countries.json'
import us_states from '../utils/states.json'
import canada_states from '../utils/canada-states.json'
import UserAction from '../redux/action/user/index'
import { Colors } from '../themes/Colors'

export const getLabelUnitTemp = (unit) => {
    return unit === unitType.metric ? '°C' : '°F'
}
export const getLabelUnitSpeed = (unit) => {
    return unit === unitType.metric ? 'm/s' : 'mile/h'
}
export const isLogin = () => {
    return LocalStorage.getItem(LocalStorage.DEFINE_KEY.user) ? true : false
}
export const getUserData = () => {
    return isLogin() ? JSON.parse(LocalStorage.getItem(LocalStorage.DEFINE_KEY.user)) : null
}

export function convertPxToRem(px = 16, isUnit = true, rem = 16) {
    return isUnit ? px / rem + 'rem' : px / rem
}

export const getValueFromObjectByKeys = (obj = {}, keys = [], defaultValue = undefined) => {
    const length = keys.length
    if (!obj || length === 0 || Object.keys(obj).length === 0) {
        return defaultValue
    }
    let output = obj
    for (let i = 0; i < length; i++) {
        output = output[keys[i]]
        if (output === 0) {
            return 0
        }
        if (output === false) {
            return false
        }
        if (!output) {
            return defaultValue
        }
    }
    if (output === 0) {
        return 0
    }
    if (output === false) {
        return false
    }
    if (!output) {
        return defaultValue
    }
    return output || defaultValue
}

const serialize = (obj) => {
    var str = []
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
        }
    return str.join('&')
}
export const convertObjectToQuery = (obj, prefix, isAddStartQuery = true) => {
    var str = [],
        p
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + '[' + p + ']' : p,
                v = obj[p]
            str.push(
                v !== null && typeof v === 'object'
                    ? serialize({ v, k })
                    : encodeURIComponent(k) + '=' + encodeURIComponent(v)
            )
        }
    }
    return isAddStartQuery ? '?' + str.join('&') : str.join('&')
}

export function myLog(...arg) {
    if (!Config.debug) {
    }
}

export const parseSearchUrl = (href = window.location.href) => {
    let search = href.indexOf('?') !== -1 ? href.split('?')[1] : false
    return search
}

export const parseSearchUrlToObject = (href = window.location.href) => {
    try {
        let search = href.indexOf('?') !== -1 ? href.split('?')[1] : false
        if (search) {
            search = search.replace('+', '%2B')
            return queryString.parse(search)
        }
        return {}
    } catch (error) {
        return {}
    }
}

export const checkFieldExistsInObject = ({ object, key } = { object: {}, key: '' }) => {
    if (typeof object !== 'object') {
        return false
    } else if (object.length !== undefined) {
        return false
    }
    if (key && key.toString) {
        key = key.toString()
    } else if (key && typeof key !== 'string') {
        return false
    } else {
        return false
    }
    let isExists = false
    Object.keys(object).map((keyInObject) => {
        if (keyInObject === key) {
            isExists = true
        }
        return 0
    })
    return isExists
}
export const pushStateToUrl = (params, navigate) => {
    let queryUrl = ''
    if (
        params &&
        Object.keys(params).length === 1 &&
        checkFieldExistsInObject({
            object: params,
            key: 'page'
        }) &&
        parseSearchUrl()
    ) {
        params = { ...parseSearchUrlToObject(), ...params }
    }
    Object.keys(params).map((key, index) => {
        if (params[key] === '' || params[key] === undefined) return
        let connect = index ? '&' : ''
        queryUrl += connect + key + '=' + params[key]
        return 0
    })
    navigate({ search: queryUrl })
}
export const objectToQuerySearch = (object) => {
    let queryUrl = ''
    Object.keys(object).map((key, index) => {
        let connect = index ? '&' : ''
        queryUrl += connect + key + '=' + object[key]
        return 0
    })
    return queryUrl
}
export const MESSAGE_TYPE = {
    info: 'info',
    warning: 'warning',
    error: 'error',
    success: 'success'
}
export const messageManager = ({ type = MESSAGE_TYPE.info, messageShow = '', duration = 5 }) => {
    switch (type) {
        case MESSAGE_TYPE.info:
            message.info(messageShow || 'Info', duration)
            break
        case MESSAGE_TYPE.error:
            message.error(messageShow || 'Error', duration)
            return messageShow
            break
        case MESSAGE_TYPE.warning:
            message.warning(messageShow || 'Warning', duration)
            break
        case MESSAGE_TYPE.success:
            message.success(messageShow || 'Success', duration)
            break
        default:
            message.info(messageShow || 'Message', duration)
            break
    }
}
export const CreditformatNumber = (value) => {
    return numeral(value).format('0,0')
}

export const deepCopyObject = (obj = {}) => {
    return JSON.parse(JSON.stringify(obj))
}

export function shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
}

export const formatTimeText = (time = moment(), toFormat = 'HH:mm A') => {
    const startToday = moment().startOf('day').valueOf()
    const endToday = moment().endOf('day').valueOf()
    const startTomorrow = moment().subtract(1, 'day').startOf('day').valueOf()
    const endTomorrow = moment().subtract(1, 'day').endOf('day').valueOf()
    const valueTime = moment(time).valueOf()
    if (valueTime < endTomorrow && valueTime > startTomorrow) {
        return 'yesterday'
    }
    if (valueTime >= startToday && valueTime <= endToday) {
        return moment(time).format(toFormat)
    }
    return moment(time).format('MMM D,YYYY')
}

export const getUserNameShow = (userInfo = {}) => {
    return getValueFromObjectByKeys(userInfo, ['profile', 'user', 'name'], '')
}
export const getUserProfileByKey = (userInfo = {}, key) => {
    return getValueFromObjectByKeys(userInfo, ['profile', 'user', key], '')
}
export const getUserByKey = (userInfo = {}, key) => {
    return getValueFromObjectByKeys(userInfo, ['user', key], '')
}
export const getUserId = (userInfo = {}) => {
    return getValueFromObjectByKeys(userInfo, ['user', 'id'], '')
}

export const formatImageUrl = (url = '') => {
    return url // url ? url.split("?")[0] : "";
}
export const navigateToContactUs = (navigate) => {
    if (!navigate) return
    if (LocalStorage.getItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN)) {
        navigate(ROUTER_NAME.contactUsPrivate)
    } else {
        navigate(ROUTER_NAME.contactUs)
    }
}
export const getLinkContactUs = () => {
    if (LocalStorage.getItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN)) {
        return ROUTER_NAME.contactUsPrivate
    } else {
        return ROUTER_NAME.contactUs
    }
}

export const reloadApp = () => {
    LocalStorage.clear()
    window.location.reload()
}

export const updateBranding = async () => {
    try {
        const domain = window.location.origin
        if (domain === 'https://move2-llc-move-3295-staging.botics.co') {
            return
        }
        UserAction.saveBranding()
    } catch {
    }
}

export const createFormData = (
    formData = new FormData(),
    key,
    data,
    listKeyFiles = [],
    realKey
) => {
    myLog('createFormData=>1', key, data)
    if (realKey && listKeyFiles.includes(realKey)) {
        formData.append(key, data)
    } else if (data === Object(data) || Array.isArray(data)) {
        for (let i in data) {
            myLog('createFormData=>', i, data, data[i])
            createFormData(
                formData,
                key + (Array.isArray(data) ? '[' + i + ']' : i),
                data[i],
                listKeyFiles,
                i
            )
        }
    } else {
        formData.append(key, data)
    }
}

export const confirmBase = ({ message, onOk, onCancel }) => {
    let text = message || 'Please confirm action?'
    if (window.confirm(text) === true) {
        onOk && onOk()
    } else {
        onCancel && onCancel()
    }
}

export const getFileNameFromUrl = (url) => {
    return url.indexOf('?') !== -1
        ? url.split('?')[0].substring(url.lastIndexOf('/') + 1)
        : url.substring(url.lastIndexOf('/') + 1)
}

export function compare(a, b) {
    if (a.name < b.name) {
        return -1
    }
    if (a.name > b.name) {
        return 1
    }
    return 0
}

export const renderCountries = () => {
    const usObject = countries.find((country) => country?.name === 'United States')
    const countriesSorted = countries
        .filter((country) => country?.name !== 'United States')
        .sort(compare)
    countriesSorted.unshift(usObject)
    return countriesSorted.map((country) => {
        return {
            key: country.countryCode,
            title: country.name
        }
    })
}

export const renderCountriesPhoneCode = () => {
    const usObject = countries.find((country) => country?.name === 'United States')
    const countriesSorted = countries
        .filter((country) => country?.name !== 'United States' && Number(country?.phone) !== 1)
        .sort(compare)
    countriesSorted.unshift(usObject)
    return countriesSorted
        ?.filter((item) => item?.phone?.length > 0 && item?.phone?.length < 5)
        ?.map((country) => {
            return {
                key: country.phone,
                short: `${country.countryCodeAlpha3} +${country.phone}`,
                title: `${country.name} +${country.phone}`
            }
        })
}

export const WHITELABEL_WEB_ROLES = [
    { key: 'SW', value: 'SW', label: 'Service Writer' },
    { key: 'OP', value: 'OP', label: 'Order Processor' }
]
export const WHITE_LABEL_WEB_ROLES_LABEL = {
    'SW': 'Service Writer',
    'OP': 'Order Processor'
}
export const WHITE_LABEL_WEB_ROLES_VALUES = {
    'Service Writer': 'SW',
    'Order Processor': 'OP'
}
export const renderStates = (type) => {
    let statesSorted = [
        {
            key: 'N/A',
            title: 'N/A'
        }
    ]
    if (type === 'US') {
        statesSorted = us_states.sort(compare)
    } else if (type === 'CA') {
        statesSorted = canada_states.sort(compare)
    }
    return statesSorted.map((state) => {
        return {
            key: state.abbreviation,
            title: state.name
        }
    })
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

export function getCreditCardType(creditCardNumber) {
    var patterns = {
        Visa: /^4/,
        MasterCard: /^5[1-5]/,
        'American Express': /^3[47]/,
        Discover: /^6(?:011|5)/
    }

    for (var cardType in patterns) {
        if (creditCardNumber.match(patterns[cardType])) {
            return cardType
        }
    }

    return 'Unknown'
}

export function getCreditCardTypeString(brandName) {
    const brand =
        brandName === 'visa'
            ? 'Visa'
            : brandName === 'mastercard'
                ? 'MasterCard'
                : brandName === 'American Express'
                    ? 'Amex'
                    : brandName === 'discover'
                        ? 'Discover'
                        : null

    return brand || 'Unknown'
}

export const limitLength = (string = '', maxLength) => string?.substr(0, maxLength)

export const removeNonNumber = (string = '') => string.replace(/[^\d]/g, '')
export const removeLeadingSpaces = (string = '') => string.replace(/^\s+/g, '')

export const formatExpiry = (expiry) => {
    const sanitized = limitLength(removeNonNumber(expiry), 4)
    if (sanitized.match(/^[2-9]$/)) {
        return `0${sanitized}`
    }
    if (sanitized.length > 2) {
        return `${sanitized.substr(0, 2)}/${sanitized.substr(2, sanitized.length)}`
    }
    return sanitized
}

export const formatCreditNumber = (number) => {
    const numberSanitized = removeNonNumber(number)
    const maxLength = 19
    const lengthSanitized = limitLength(numberSanitized, maxLength)
    return addSpacingToCreditCardNumber(lengthSanitized)
}

function addSpacingToCreditCardNumber(creditCardNumber) {
    creditCardNumber = creditCardNumber.replace(/\s+/g, '').replace(/-/g, '')

    var cardType = getCreditCardType(creditCardNumber)

    var spacingPattern
    switch (cardType) {
        case 'Visa':
            spacingPattern = [4, 4, 4, 4]
            break
        case 'MasterCard':
            spacingPattern = [4, 4, 4, 4]
            break
        case 'American Express':
            spacingPattern = [4, 6, 5]
            break
        case 'Discover':
            spacingPattern = [4, 4, 4, 4]
            break
        default:
            return creditCardNumber
    }

    var formattedNumber = ''
    var currentPosition = 0
    for (var i = 0; i < spacingPattern.length; i++) {
        var segmentLength = spacingPattern[i]
        var segment = creditCardNumber.substr(currentPosition, segmentLength)
        formattedNumber += segment + ' '
        currentPosition += segmentLength
    }

    return formattedNumber.trim()
}

export const verifyCard = (values) => {
    let errors = {}

    for (const [key, value] of Object.entries(values)) {
        if (key === 'cvc') {
            if (value?.length < 3 || !Number(value))
                errors = {
                    ...errors,
                    ...{ [key]: `Invalid CVV` }
                }
        } else if (key === 'card_holder') {
            if (value?.length > 15) {
                errors = {
                    ...errors,
                    ...{ [key]: `Card holder name should not be more than 15 characters` }
                }
            } else if (value?.length < 1) {
                errors = {
                    ...errors,
                    ...{ [key]: `Invalid ${key.replace(/_/g, ' ')}` }
                }
            }
        } else if (key === 'number') {
            if (!isValidCard(value) || value?.length < 1) {
                errors = {
                    ...errors,
                    ...{ [key]: `Invalid card number` }
                }
            }
        } else if (key === 'expiration_date') {
            if (!value?.exp_year || !value?.exp_month) {
                errors = {
                    ...errors,
                    ...{ [key]: `Invalid expiry date` }
                }
            } else if (dayjs().year() > Number('20' + value?.exp_year)) {
                errors = {
                    ...errors,
                    ...{ [key]: `Invalid expiry date` }
                }
            } else if (dayjs().year() === Number('20' + value?.exp_year)) {
                if (dayjs().month() + 1 > value?.exp_month) {
                    errors = {
                        ...errors,
                        ...{ [key]: `Invalid expiry date` }
                    }
                }
            }
        } else if (value?.length < 1) {
            errors = {
                ...errors,
                ...{ [key]: `Invalid ${key.replace(/_/g, ' ')}` }
            }
        }
    }
    return errors
}

function isValidCard(cardNumber) {
    let nCheck = 0,
        bEven = false
    const sCardNum = String(cardNumber).replace(/\D/g, '')

    for (let n = sCardNum.length - 1; n >= 0; n--) {
        let cDigit = sCardNum.charAt(n),
            nDigit = parseInt(cDigit, 10)

        if (bEven && (nDigit *= 2) > 9) {
            nDigit -= 9
        }

        nCheck += nDigit
        bEven = !bEven
    }

    return nCheck % 10 === 0
}

export const translate = (data) => {
    return i18next.t(data)
}

export const REGEX = { password: /(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,8}/ }

export function fileToUrl(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
            resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
    })
}

export async function urlToFile(url, filename) {
    const response = await fetch(url)
    const blob = await response.blob()
    return new File([blob], filename)
}

/**
 * @param orderDetails - quote object
 * @param {boolean} isSW - is logged in user a service writer
 * @returns {string} status - status of an order
 */
export const handleStatus = (orderDetails, isSW) => {
    const { quotes } = orderDetails
    const singleQuote = quotes && quotes[0]
    const quoteStatus = singleQuote?.status

    if (orderDetails.status === 'SERVICE ACCEPTED') {
        return orderDetails.status
    } else if (!quotes || quotes.length === 0) {
        return orderDetails.status
    } else {
        return quoteStatus
    }
}

/**
 * @param {string} status - status of the quote
 * @param {boolean} isSW - is logged in user a service writer
 * @returns {string} - returns the status label of the quote
 */

export const filterQuoteStatus = (status, isSW = false) => {
    switch (status) {
        case 'INPROGRESS':
            return 'In Progress'
        case 'SENT':
            return isSW ? 'Action Required' : 'Quote Sent'
        case 'ACCEPTED':
            return 'Paid'
        case 'NS':
            return 'Open order'
        case 'SERVICE ACCEPTED':
            return 'SERVICE ACCEPTED'
        default:
            return 'Open order'
    }
}

export const filterQuoteStatusColor = (status, isSW) => {
    switch (status) {
        case 'SENT':
            return isSW ? Colors.red : Colors.primary
        case 'ACCEPTED':
            return Colors.open_order
        case 'SERVICE ACCEPTED':
            return Colors.open_order
        case 'NS':
            return Colors.red
        default:
            return Colors.red
    }
}

export const resizeImage = (blob, sizes) => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        const objectUrl = URL.createObjectURL(blob)
        img.src = objectUrl
        img.onload = () => {
            const resizedImagesPromises = sizes.map(
                (size) =>
                    new Promise((resolve) => {
                        const canvas = document.createElement('canvas')
                        const ctx = canvas.getContext('2d')
                        canvas.width = size
                        canvas.height = size
                        ctx.drawImage(img, 0, 0, size, size)
                        canvas.toBlob(
                            (resizedBlob) => {
                                const resizedUrl = URL.createObjectURL(resizedBlob)
                                resolve({ size, url: resizedUrl })
                            },
                            'image/jpeg',
                            0.95
                        )
                    })
            )

            Promise.all(resizedImagesPromises)
                .then((results) => {
                    resolve(results)
                    URL.revokeObjectURL(objectUrl)
                })
                .catch((error) => {
                })
        }

        img.onerror = (err) => {
            URL.revokeObjectURL(objectUrl)
        }
    })
}

export function imageResize(imageUrl) {
    return new Promise((resolve) => {
        const domain =
            window.location.hostname !== 'localhost'
                ? window.location.origin
                : 'https://www.shoredealsnow.com'
        fetch(`${domain}/api/v1/white-label-partner/fetch-image/?url='${btoa(imageUrl)}'`, {
            method: 'GET'
        })
            .then((response) => response.blob())
            .then((blob) => {
                let urls = resizeImage(blob, [72, 96, 128, 144, 152, 192, 384, 512])
                resolve(urls)
            })
    })
}

export const getStatus = (status, isDT = false) => {
    switch (status) {
        case 'INPROGRESS':
            return 'In Progress'
        case 'SENT':
            return isDT ? 'Pending' : 'Action Required'
        case 'ACCEPTED':
            return 'Accepted Delivery'
        case 'NS':
            return 'Pending'
        default:
            return 'Pending'
    }
}

export const getStatusColor = (status, isDT = false) => {
    switch (status) {
        case 'SENT':
            return isDT ? Colors.primary : '#E21414'
        case 'ACCEPTED':
            return Colors.open_order
        case 'NS':
            return Colors.primary
        default:
            return Colors.primary
    }
}

export const getStatusBackground = (status, isDT = false) => {
    switch (status) {
        case 'SENT':
            return isDT ? 'rgba(251, 181, 22, 0.1)' : 'rgba(254, 226, 226, 1)'
        case 'ACCEPTED':
            return 'rgba(209, 250, 229, 1)'
        case 'NS':
            return 'rgba(251, 181, 22, 0.1)'
        default:
            return 'rgba(251, 181, 22, 0.1)'
    }
}

export const getRoleLabel = (role) => {
    if (Object.keys(WHITE_LABEL_WEB_ROLES_LABEL).includes(role)) {
        return WHITE_LABEL_WEB_ROLES_LABEL[role]
    }
    return role
}

export const getRoleValue = (role) => {

    if (Object.keys(WHITE_LABEL_WEB_ROLES_VALUES).includes(role)) {
        return WHITE_LABEL_WEB_ROLES_VALUES[role]
    }
    return role
}
