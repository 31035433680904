import { convertPxToRem } from '../helper';
import { Modal } from 'antd';
import TextBase from '../common/text';
import { Colors } from '../themes/Colors';
import React, { useEffect, useRef, useState } from 'react';
import LoginInput from '../common/login-input';
import { useDispatch } from 'react-redux';
import { UserAction } from '../redux/action';
import InputError from '../common/input-error/InputError.component';
import moment from 'moment';

const OPTModal = (props) => {
  const [otp1, setOtp1] = useState('');
  const [otp2, setOtp2] = useState('');
  const [otp3, setOtp3] = useState('');
  const [otp4, setOtp4] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [timeLeft, setTimeLeft] = useState(59);
  const dispatch = useDispatch();

  const handleNext = async (lastOtp) => {
    const payloadUser = {
      otp: otp1 + otp2 + otp3 + lastOtp,
      phone_number: '+' + props.phoneCode + props.phone
    };

    const payloadVendor = {
      otp: otp1 + otp2 + otp3 + lastOtp,
      phone_number: '+' + props.phoneCode + props.phone
    };

    try {
      // Verufy dealer or user mobile number
      if (props.type === 'user') {
        // 
        await dispatch(UserAction.verifyPhoneNumberUser(payloadUser));
      } else {
        // 
        await dispatch(UserAction.verifyPhoneNumber(payloadVendor));
      }

      setOtp1('');
      setOtp2('');
      setOtp3('');
      setOtp4('');

      props.closeModal();

      props.fetchData();
    } catch (errors) {
      // 
      setFormErrors(errors);
    }
  };

  useEffect(() => {
    if (props.showOTP) {
      const interval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
      if (timeLeft === 0) {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [timeLeft, props.showOTP]);

  const formattedTimeLeft = moment.utc(timeLeft, 'seconds').format('mm:ss', { trim: false });

  const otpOneRef = useRef(null);
  const otpTwoRef = useRef(null);
  const otpThreeRef = useRef(null);
  const otpFourRef = useRef(null);

  const clearForm = () => {
    setOtp1('');
    setOtp2('');
    setOtp3('');
    setOtp4('');
  };

  return (
    <Modal
      onCancel={() => {
        props.closeModal();
        clearForm();
      }}
      visible={props.showOTP}
      footer={null}
      width={375}
      bodyStyle={{
        paddingInline: convertPxToRem(35),
        borderRadius: convertPxToRem(12)
      }}
      closable={false}>
      <div
        style={{
          textAlign: 'center',
          marginBottom: convertPxToRem(10),
          paddingTop: convertPxToRem(10)
        }}>
        <TextBase
          size={convertPxToRem(24)}
          color={Colors.grey2}
          fontWeight={600}
          style={{ marginBottom: 20 }}>
          verification
        </TextBase>
        <TextBase
          size={convertPxToRem(14)}
          color={Colors.black}
          fontWeight={400}
          style={{ marginBottom: 30 }}>
          codeAlert{' '}
          <strong>
            +{props.phoneCode}
            {props.phone}
          </strong>
        </TextBase>
      </div>

      {/* OTP Form */}

      <div className="otp-form">
        <LoginInput
          value={otp1}
          onChange={(value) => setOtp1(value)}
          style={{ textAlign: 'center', padding: 3, width: 44, height: 44 }}
          maxLength="1"
          onKeyUp={() => otpTwoRef.current.focus()}
        />

        <LoginInput
          ref={otpTwoRef}
          value={otp2}
          onChange={(value) => setOtp2(value)}
          style={{ textAlign: 'center', padding: 3, width: 44, height: 44 }}
          maxLength="1"
          onKeyUp={() => otpThreeRef.current.focus()}
        />

        <LoginInput
          ref={otpThreeRef}
          value={otp3}
          onChange={(value) => setOtp3(value)}
          style={{ textAlign: 'center', padding: 3, width: 44, height: 44 }}
          maxLength="1"
          onKeyUp={() => otpFourRef.current.focus()}
        />

        <LoginInput
          ref={otpFourRef}
          value={otp4}
          onChange={(value) => {
            setOtp4(value);
            setTimeout(() => {
              handleNext(value);
            }, 600);
          }}
          style={{ textAlign: 'center', padding: 3, width: 44, height: 44 }}
          maxLength="1"
          onKeyUp={() => otpFourRef.current.blur()}
        />
      </div>
      <InputError error={formErrors.error} />

      {/* OTP Form */}

      <div style={{ marginBottom: 19, marginTop: 20 }} className="flex space-between">
        <TextBase
          size={convertPxToRem(14)}
          color={Colors.black}
          fontWeight={400}
          style={{ marginBottom: 30 }}>
          codeNotRecievedWarning
        </TextBase>

        <div>
          <TextBase
            onClick={() => {
              if (timeLeft === 0) {
                props.getSMSCode();
                setTimeLeft(59);
              }
            }}
            size={convertPxToRem(14)}
            color={timeLeft === 0 ? Colors.primary : Colors.grey}
            fontWeight={400}
            style={{ marginBottom: 5, cursor: 'pointer' }}>
            resend
          </TextBase>
          <TextBase size={convertPxToRem(14)} color={Colors.black} fontWeight={400}>
            ( {formattedTimeLeft} )
          </TextBase>
        </div>
      </div>
    </Modal>
  );
};

export default OPTModal;
