import React from 'react';
import TextBase from '../text';
import { Colors } from '../../themes/Colors';
import { convertPxToRem } from '../../helper';

export const SubscriptionHeader = ({
  showChange = false,
  btnText = 'cancel',
  leftTitle = 'subscription',
  subscription = {},
  onClickRight = () => { },
  onReactivate = () => { },
  onClickLeft = () => { },
  onActivate = () => { },
  isLoading = false
}) => {

  const currentPeriodEnd = subscription?.subscription?.current_period_end;
  const isCancelled = new Date(currentPeriodEnd) < new Date() || subscription?.subscription?.cancel_at_period_end;
  const isExpired = new Date(currentPeriodEnd) < new Date() && !subscription?.subscription?.canceled_at;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px'
      }}>
      <div style={{ display: 'flex' }}>
        {showChange && (
          <TextBase style={{}} fontWeight={400} size={convertPxToRem(18)} color={Colors.titlePage}>
            type
          </TextBase>
        )}

        <TextBase
          style={{ marginLeft: '6px' }}
          fontWeight={600}
          size={convertPxToRem(18)}
          color={Colors.titlePage}>
          {leftTitle}
        </TextBase>
      </div>
      <div
        style={{
          flex: 1,
          height: '1px',
          marginLeft: '20px',
          backgroundColor: '#E5E7EB'
        }}
      />

      <div style={{ display: 'flex' }}>
        <>
          {
            isExpired ? (
              <div
                style={{ marginLeft: '20px' }}
                className="show-cursor-on-hover"
                onClick={onActivate}>
                <TextBase
                  style={{ color: Colors.primary }}
                  fontWeight={600}
                  size={convertPxToRem(18)}
                  color={Colors.titlePage}>
                  activateSubscription
                </TextBase>
              </div>
            ) : 
            isCancelled ? (
            <div
              style={{ marginLeft: '20px' }}
              className="show-cursor-on-hover"
              onClick={onReactivate}>
              <TextBase
                style={{ color: Colors.primary }}
                fontWeight={600}
                size={convertPxToRem(18)}
                color={Colors.titlePage}>
                resumeMembership
              </TextBase>
            </div>
          ) : (
            <div
              style={{ marginLeft: '20px' }}
              className="show-cursor-on-hover"
              onClick={onClickRight}>
              <TextBase
                style={{ color: Colors.primary }}
                fontWeight={600}
                size={convertPxToRem(18)}
                color={Colors.titlePage}>
                {btnText}
              </TextBase>
            </div>
          )}
        </>
        {}
      </div>
    </div>
  );
};
