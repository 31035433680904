import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import emptyDealer from '../../assets/emptyDealer.png'
import Button from '../../common/Button';
import { translate } from '../../../helper';

function EmptyVendors(props) {
    const {
        handleAddDealer
    } = props
    return (
        <div
            style={{
                height: "75vh", 
                position: "relative", 
                width: "100%",
                // backgroundColor: "#ddd",
                textAlign: "center"
            }}
        >
            <div
                style={{
                    height: "70%",
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}
            >
                {/* <img 
                    src={emptyDealer}
                    alt="empty-order.png"
                    style={{
                        width: "230px",
                        height: "220px",
                        marginTop: "70px"
                    }}
                /> */}
                <p
                    style={{
                        fontWeight: '600',
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#383938"
                    }}
                >
                    {translate('noDealers')}
                </p>
                {/* <Button onClick={handleAddDealer} style={{background: "#fff", color: "#000", boxShadow: '0px 4px 20px 0px #02114517'}} ><AddCircleOutlineIcon style={{marginRight: "5px"}} />Add Dealer</Button> */}
            </div>
        </div>
    )
}

export default EmptyVendors