import React from 'react'
import { sendMessageApiCall } from '../../common/chat/Action'
import Modal from '../../common/model'
import { Box, CircularProgress, DialogActions, Grid, styled, Typography } from '@mui/material'
import { translate } from '../../helper'
import { Form as FormikForm, Formik } from 'formik'
import { messageSchema } from '../../schema/vendorSchema'
import { Label } from '../../common/form/TextInput'
import { TextField } from '../../common/form/input'
import { FormActionButton } from './components'

const ThreadWrapper = styled('div')(({ theme }) => ({
    '& .orderdetails': {
        'backgroundColor': '#F3F4F6',
        'width': '100%',
        'padding': '10px',
        'borderRadius': '10px',
        'marginBottom': '10px'
    },
    '& .detals': {
        'width': '30%'
    }
}))
const StartThreadModal = ({ isOpen, onClose, orderDetail, vendorId }) => {

    const handleSubmit = (values, actions) => {
        sendMessageApiCall(
            {
                ...values,
                vendor_id: vendorId,
                order_id: orderDetail.id
            },
            actions.setSubmitting,
            () => {
                onClose()
            }
        )
    }
    return (

        <Modal
            title={'Start Thread'}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ThreadWrapper>
                <Box className="orderdetails">
                    <Box marginBottom={'10px'} display={'flex'}>
                        <Typography className="detals">{translate('customerPo')}#:</Typography>
                        <Typography>{orderDetail?.id}</Typography>
                    </Box>
                    <Box marginBottom={'10px'} className="" display={'flex'}>
                        <Typography className="detals">{translate('quantity')}:</Typography>
                        <Typography>{orderDetail?.quantity || 0}</Typography>
                    </Box>
                    <Box marginBottom={'10px'} display={'flex'}>
                        <Typography className="detals">{translate('address')}:</Typography>
                        <Typography>{orderDetail?.shipping_address || 'N/A'}</Typography>
                    </Box>
                </Box>
                <Formik
                    initialValues={{
                        body: ''
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={messageSchema}
                >
                    {({ isSubmitting, values, resetForm, errors, touched }) => (
                        <FormikForm>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Label>{translate('reply')}</Label>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={values.body}
                                        type="text"
                                        id="body"
                                        name="body"
                                        error={Boolean(touched.body && errors.body)}
                                        helperText={touched.body && errors.body}
                                        fullWidth
                                        asTextArea
                                        minRows={10}
                                        style={{marginTop: '10px'}}
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={12}>
                                <DialogActions>
                                    <FormActionButton
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ?
                                            <CircularProgress size={24} color={'inherit'}/> : translate('send')}
                                    </FormActionButton>
                                </DialogActions>
                            </Grid>
                        </FormikForm>
                    )}
                </Formik>
            </ThreadWrapper>
        </Modal>
    )

}

export default StartThreadModal
