import React from 'react'

import Header from './Header'
import Footer from './Footer'

const Layout = ({ children, noAuth, noFooter, childWrapperStyle, authscreen, showAddOrder, ...rest }) => {
    const { showLargeHeader } = rest
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                background: '#fff'
            }}
        >
            <Header authscreen={authscreen} noAuth={noAuth} showAddOrder={showAddOrder} {...rest}/>
            <div
                style={{
                    width: '100%',
                    height: showLargeHeader ? '72%' : noFooter ? '88%' : '80%',
                    marginTop: authscreen ? '0': '-5%'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: noFooter ? '100%' : showLargeHeader ? '100%' : '100%',
                        borderRadius: authscreen? '0': '22px 22px 0 0',
                        overflowY: 'auto',
                        overflowX: 'auto',
                        backgroundColor: '#fff',
                        ...childWrapperStyle
                    }}
                >
                    {children}
                </div>
            </div>
            {(!noAuth && !noFooter) ? <Footer/> : <></>}
        </div>
    )
}

export default Layout
