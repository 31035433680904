import { useState, useRef } from "react";
import { SketchPicker } from "react-color";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import CancelIcon from '@mui/icons-material/Cancel';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from '@mui/material/Modal';
import { Checkbox } from 'antd'
import ImageEditor from "../../common/ImageCropModal";

import { translate } from '../../helper';
import LocalStorage from '../../defined/localStorage';
import { useSelector } from "react-redux";
import { Select, Space } from 'antd';

const brand = JSON.parse(LocalStorage.getItem('branding'))

function TextField({ label, value, name, onChange }) {
    return (
        <fieldset
            style={{
                display: "flex",
                flexDirection: "column"
            }}
            key={label}
        >
            <label
                style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000",
                    marginBottom: "5px",
                    textTransform: "capitalize"
                }}
            >
                {translate(label)}
            </label>
            <input
                type='text'
                style={{
                    borderRadius: "8px",
                    border: "0.5px solid #C8C8C8",
                    backgroundColor: "#F8F8F8",
                    height: "100%"
                }}
                name={name}
                value={value}
                onChange={onChange}
            />
        </fieldset>
    )
}

function SubHeader(props) {
    const {
        label,
        button,
        hyperlinkText,
        onClick
    } = props
    return (
        <div
            style={{
                display: "inline-grid",
                alignItems: "center",
                gridTemplateColumns: "auto 1fr auto",
                width: "100%"
            }}
        >
            {label && <span
                style={{
                    fontSize: "18px",
                    width: "fit-content",
                    lineHeight: "27px",
                    fontWeight: "600",
                    padding: "10px 20px 10px 0",
                }}
            >
                {translate(label)}
            </span>}
            <div style={{ height: "1px", backgroundColor: "rgba(229, 231, 235, 1)" }} />
            {button && <button
                style={{
                    borderRadius: "8px",
                    padding: "10px 20px",
                    marginLeft: "20px",
                    border: "none",
                    background: "rgba(248, 178, 24, 1)",
                    boxShadow: "0px 4px 4px 0px rgba(185, 131, 9, 0.09)",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#fff",
                    textTransform: "uppercase",
                    width: "fit-content",
                }}
                onClick={onClick}
            >
                {translate(button)}
            </button>}
            {hyperlinkText && <button
                style={{
                    borderRadius: "8px",
                    padding: "10px 20px",
                    marginLeft: "20px",
                    border: "none",
                    background: "#fff",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "rgba(248, 178, 24, 1)",
                    textTransform: "capitalize",
                    width: "fit-content",
                }}
                onClick={onClick}
            >
                {translate(hyperlinkText)}
            </button>}
        </div>
    )
}

function TemplateDesign({
    emailLogo,
    setEmailLogo,
    emailTemplate,
    setEmailTemplate,
    emailBackgroundColor,
    setEmailBackgroundColor,
    orderBackgroundColor,
    setOrderBackgroundColor,
    onSubmit,
    onCancel,
    orderForm,
    setOrderForm,
    addFIeld,
    onLabelChange,
    orderType,
    setOrderType,
    currencyType,
    setCurrencyType,
    currencies,
    deleteLabel,
    tab,
    onPreview,
    mandatoryFieldChange
}) {
    const whiteLabel = useSelector((state) => state.User?.branding);
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [logoImage, setLogoImage] = useState(null);
    const fileInputRef = useRef(null);
    const { r, g, b, a } = emailBackgroundColor
    const { r: r1, g: g1, b: b1, a: a1 } = orderBackgroundColor

    const onFileUpload = (e) => {
        const file = e.target.files[0];
        setLogoImage(file);
        setIsEditorOpen(true)
    }

    function imageUrl(url) {
        if (url?.type) {
            return URL.createObjectURL(url)
        }
        return url
    }

    function templateValueChange({ target: { name, value } }) {
        setEmailTemplate({
            ...emailTemplate,
            [name]: value
        })
    }

    function complement(r, g, b) {
        const m = 255;
        return `${Math.abs(m - r)}, ${Math.abs(m - g)}, ${Math.abs(m - b)}`;
    }

    const changeOrderType = ({ target: { value: val } = {} }) => {
        setOrderType(val)
    }

    const handleCloseEditor = () => {
        setIsEditorOpen(false)
        let input = document.getElementById('myFile');
        if (input) {
            input.value = ''
        }
        fileInputRef.current.value = '';

        setLogoImage(null)
    }

    const handleCroppedImage = (img) => {
        let arr = img.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        let file = new File([u8arr], logoImage.name, { type: mime });
        setEmailLogo(file)
        handleCloseEditor()
    }

    return (
        <div style={{ width: "100%" }}>
            {
                tab === "emailtemplate" && <>
                    <SubHeader
                        label="emailTemplate"
                    />
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            margin: "20px 0"
                        }}
                    >
                        <div
                            style={{
                                width: "120px",
                                height: "120px",
                                borderRadius: "6px",
                                border: "1px solid #DADADA",
                                marginBottom: "20px"
                            }}
                        >
                            {
                                emailLogo && <img
                                    src={imageUrl(emailLogo)}
                                    alt="logo"
                                    style={{
                                        borderRadius: "6px",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                />
                            }
                        </div>
                        <div
                            style={{
                                display: "flex"
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: "4px",
                                    background: `linear-gradient(0deg, ${whiteLabel?.web_button_color || brand?.web_button_color || '#F8B218'}, ${whiteLabel?.web_button_color || brand?.web_button_color || '#F8B218'}), linear-gradient(0deg, rgba(255, 255, 255, 0.52), rgba(255, 255, 255, 0.52))`,
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    color: "#fff",
                                    textTransform: "capitalize",
                                    textAlign: "center",
                                    width: "142px",
                                    cursor: "pointer",
                                    position: "relative",
                                    marginRight: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                {translate('update')}
                                <input onChange={onFileUpload} style={{ position: "absolute", left: 0, width: "100%", opacity: 0, padding: 0, cursor: "pointer" }} type="file" id="myFile" name="filename"></input>
                            </div>
                            <button
                                style={{
                                    borderRadius: "4px",
                                    border: "none",
                                    background: "linear-gradient(0deg, #E44D29, #E44D29), linear-gradient(0deg, rgba(255, 255, 255, 0.52), rgba(255, 255, 255, 0.52))",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    color: "#fff",
                                    textTransform: "capitalize",
                                    textAlign: "center",
                                    width: "142px",
                                    cursor: "pointer"
                                }}
                                onClick={() => setEmailLogo()}
                            >
                                {translate('delete')}
                            </button>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                marginTop: "20px",
                                display: "grid",
                                gridTemplateColumns: "48% 48%",
                                gridAutoRows: "75px",
                                columnGap: "4%",
                                rowGap: "20px"
                            }}
                        >
                            {
                                Object.keys(emailTemplate).map(template => (
                                    <TextField
                                        label={template.split("_").join(" ")}
                                        value={emailTemplate[template]}
                                        name={template}
                                        onChange={templateValueChange}
                                    />
                                ))
                            }
                        </div>
                        <div
                            style={{
                                margin: "20px 0",
                                display: "flex",
                                flexDirection: "column",
                                paddingTop: "30px"
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: "600",
                                    color: "#4F4F4F",
                                    fontSize: "14px",
                                    lineHeight: "21px"
                                }}
                            >
                                {translate('chooseBackgroundColor')}
                            </span>
                            <div
                                style={{
                                    margin: "30px 0 10px 0",
                                    display: "flex",
                                }}
                            >
                                <div
                                    style={{
                                        height: "310px",
                                        width: "220px"
                                    }}
                                >
                                    <SketchPicker
                                        onChange={(color) => {
                                            setEmailBackgroundColor(color.rgb);
                                        }}
                                        color={emailBackgroundColor}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "300px",
                                            width: "450px",
                                            backgroundColor: `rgba(${r}, ${g}, ${b}, ${a})`,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontWeight: "700",
                                            fontSize: "16px",
                                            lineHeight: "21px",
                                            border: `1px solid rgba(${complement(r, g, b)}, 1)`,
                                            color: `rgba(${complement(r, g, b)}, 1)`
                                        }}
                                    >
                                        {translate('example')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

            {
                tab === "orderForm" && <>
                    <SubHeader
                        label="orderForm"
                        hyperlinkText="addField"
                        onClick={addFIeld}
                    />
                    <div
                        style={{
                            marginTop: "30px",
                            width: "100%",
                            marginTop: "20px",
                            display: "grid",
                            gridTemplateColumns: "48% 48%",
                            gridTemplateRows: "50px 50px 50px auto",
                            columnGap: "4%",
                            rowGap: "20px",
                        }}
                    >
                        {
                            orderForm.map(order => (
                                order.rows > 1 ? (
                                    <textarea
                                        value={order.label}
                                        rows={order.rows}
                                        readOnly
                                        style={{
                                            borderRadius: "8px",
                                            border: "0.5px solid #C8C8C8",
                                            backgroundColor: "#F8F8F8",
                                            height: "100%",
                                            resize: "none",
                                            gridColumn: "1/3",
                                            padding: "10px",
                                            height: "50px"
                                            // marginTop: "20px"
                                        }}

                                    />
                                ) : (
                                    <div style={{ 
                                        position: "relative", 
                                        width: "100%", 
                                        height: "fit-content", 
                                        display: "flex",
                                        flexDirection: "column",
                                        ...(order.id && {lineHeight: "70px"})
                                     }}>
                                        <input
                                            value={order.label}
                                            readOnly={order.default}
                                            style={{
                                                borderRadius: "8px",
                                                border: "0.5px solid #C8C8C8",
                                                backgroundColor: "#F8F8F8",
                                                height: "100%",
                                                width: "100%",
                                                height: "50px"
                                            }}
                                            onChange={({ target }) => onLabelChange(order.id, target.value)}
                                        />
                                        {
                                            order.id && <CancelIcon
                                                style={{
                                                    position: "absolute",
                                                    right: 0,
                                                    top: 0,
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => deleteLabel(order.id)}
                                            />
                                        }
                                        {
                                            order.id && <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "right",
                                                    marginTop: "5px"
                                                }}
                                            >
                                                <Checkbox className="custom-checkbox" checked={order.mandatory} onChange={(e) => mandatoryFieldChange(order.id)}>{translate('mandatoryMessage')}</Checkbox>
                                            </div>
                                        }
                                    </div>
                                )
                            ))
                        }
                    </div>
                    <div
                        style={{
                            margin: "20px 0",
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: "30px"
                        }}
                    >
                        <span
                            style={{
                                fontWeight: "600",
                                color: "#4F4F4F",
                                fontSize: "14px",
                                lineHeight: "21px",
                                marginBottom: "10px"
                            }}
                        >
                            {translate('selectCurrency')}
                        </span>
                        <Select
                            onChange={e => setCurrencyType(e)}
                            value={currencyType}
                            options={[{ value: 'default', label: translate('selectCurrency') }, currencies.filter(e => e.currency&&e.countryCode==='US').map(e => ({ value: e.currency, label: `${e.currencyName || e.name} - ${e.currency}` }))[0], ...currencies.filter(e => e.currency&&e.countryCode!=='US').sort((a, b) => a?.name?.localeCompare(b?.name)).map(e => ({ value: e.currency, label: `${e.currencyName || e.name} - ${e.currency}` }))]}
                            style={{
                                width: "300px",
                                flex: 1,
                            }}
                            varient="filled"
                        />
                    </div>
                </>
            }
            {/*  */}

            <div
                style={{
                    margin: "100px 0 20px 0",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >

                <button
                    style={{
                        borderRadius: "8px",
                        background: "#fff",
                        border: "1px solid #6B7280",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#404040",
                        textTransform: "uppercase",
                        textAlign: "center",
                        width: "45%",
                        cursor: "pointer",
                        padding: "15px"
                    }}
                    onClick={onCancel}
                >
                    {translate('cancel')}
                </button>
                <button
                    style={{
                        borderRadius: "8px",
                        background: `${whiteLabel?.web_button_color || brand?.web_button_color || 'rgba(248, 178, 24, 1)'}`,
                        border: "1px solid rgba(255, 255, 255, 0.52)",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#fff",
                        textTransform: "uppercase",
                        textAlign: "center",
                        width: "45%",
                        cursor: "pointer",
                        padding: "15px"
                    }}
                    onClick={onPreview}
                >
                    {translate('preview')}
                </button>
            </div>
            <Modal open={isEditorOpen} onClose={handleCloseEditor}>
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ width: "60%", minHeight: "400px", position: "relative", background: "#000", padding: "10px" }}>
                        <ImageEditor img={logoImage} setCroppedImage={handleCroppedImage} handleCloseEditor={handleCloseEditor} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default TemplateDesign