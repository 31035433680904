import Utils from '../../../utils'
import 'firebase/compat/firestore'

export const sendMessageApiCall = (values, setSubmitting, refetch) => {
    Utils.api.postApiCall(
        `chats/firestore/order-rooms/detail/${values?.order_id}/send-message/${values?.vendor_id}/`,
        values,
        () => {
            Utils.showAlert(1, 'Successfully sent!')
            setSubmitting(false)
            refetch()
        },
        (error) => {
            //
            const { data } = error
            Utils.showAlert(2, data.message || data?.non_field_errors[0])
            setSubmitting(false)
        }
    )
}
