import { Dialog, DialogContent, DialogTitle, MenuItem, Select, styled } from '@mui/material';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { translate } from '../../helper';
import i18n from '../../I18';

const LanguageSelectionWrapper = styled('div')(({ theme }) => ({
    '& .MuiInputBase-root': {
        'width': '100%'
    }
}));

const LanguageSelectionModal = ({ isOpen, onClose }) => {

    const [language, setLanguage] = React.useState('');

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
        >
            <DialogTitle>{translate('chooseLanguage')}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent style={{width: "300px"}}>
                <LanguageSelectionWrapper>
                    <Select
                        displayEmpty
                        onChange={(evt) => {
                            setTimeout(() => {
                                i18n.changeLanguage(evt.target.value, () => {
                                    window.location.reload();
                                });
                                setLanguage(evt.target.value);
                            }, 500);
                        }}
                        value={language}
                        id="languages"
                        renderValue={
                            language !== ''
                                ? undefined
                                : () => <span style={{ color: '#ababab' }}>{translate('selectLanguage')}</span>
                        }
                    >
                        <MenuItem value={'en'}>English</MenuItem>
                        <MenuItem value={'es'}>Spanish</MenuItem>
                    </Select>
                </LanguageSelectionWrapper>
            </DialogContent>
        </Dialog>
    );
};
export default LanguageSelectionModal;