import {RightOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom"


function Nav(props) {
    const {
        label,
        path,
        onClick,
        style={}
    } = props
    return (
        path ? <Link to={path} style={{...style}} className='setting-tab'>
            <span>{label}</span>
            <RightOutlined />
        </Link> : <div onClick={onClick} style={{...style}} className='setting-tab'>
            <span>{label}</span>
            <RightOutlined />
        </div>
    )
}

export default Nav