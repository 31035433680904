import { useEffect, useState } from 'react'
import { CircularProgress, Container, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Card from '../../components/Card'
import Layout from '../../components/Layout'
import { useLocation, useNavigate } from 'react-router-dom'
import Utils from '../../../utils'
import { useGetBusinessAccounts, useGetPaymentMethods, usePayQuote } from '../../container/hooks/usePayment'
import LocalStorage from '../../../defined/localStorage'
import Button from '../../common/Button'
import OrderPlacedModal from '../../components/OrderPlacedModal'
import { translate } from '../../../helper'
import { useSelector } from 'react-redux'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PageBox from './components'

const brand = JSON.parse(LocalStorage.getItem('branding'))
const getCurrencySymbol = (currency = 'USD', locale = 'en-US') => (0).toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
}).replace(/\d/g, '').trim()

const endpoint = 'https://api.exchangerate-api.com/v4/latest'

function PaymentMethod() {
    const whiteLabel = useSelector((state) => state.User?.branding)
    const [loading, setLoading] = useState(true)
    const paymentConfig = useSelector((state) => state.User?.order_config)
    const [cards, setCards] = useState([])
    const [businessCards, setBusinessCards] = useState([])
    const [selectedCard, setSelectedCard] = useState({})
    const [isOrderPlaced, setIsOrderPlaced] = useState(false)
    const [orderResponse, setOrderResponse] = useState('')
    const [rsponse, setResponse] = useState('')
    const [payments, setPayments] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const { payQuote, isPayingQuotation } = usePayQuote(
        onSuccessPayment,
        onErrorPayment
    )

    async function onSuccessPayment(data) {
        setIsOrderPlaced(true)
        setOrderResponse('success')
    }

    async function onErrorPayment(data) {
        setIsOrderPlaced(true)
        setOrderResponse('error')
        if (data?.data?.quote?.[0]) {
            setResponse(data?.data?.quote?.[0] || '')
        }
    }

    const navigate = useNavigate()
    const location = useLocation()

    if (location?.state) {
        LocalStorage.setItem('quote_data', JSON.stringify(location.state))
    }
    const quote = JSON.parse(LocalStorage.getItem('quote_data') || '{}') || location?.state || {}
    const [successData, setSuccessData] = useState({
        po: quote?.order?.id,
        order: quote?.order?.name,
        dealer: quote?.vendor?.business_name,
        amount: quote?.quote,
    })

    const { getPymentData } = useGetPaymentMethods(getPaymentMethodData)

    const { getBusinessAccounts } = useGetBusinessAccounts(getBusinessPaymentMethodData)

    function getPaymentMethodData(data) {
        let def = data?.filter(e => e?.is_default)[0] || selectedCard
        setSelectedCard(def)
        setCards([...(data?.map(e => e?.is_default ? { ...e, selected: true } : e) || [])])
    }

    function getBusinessPaymentMethodData(data) {
        let def = data?.filter(e => e?.default)[0] || selectedCard
        setSelectedCard(def)
        setBusinessCards([...(data.map(e => e?.default ? { ...e, selected: true } : e) || [])])
    }

    const handlePay = () => {
        const id = selectedCard?.type === 'card' ? selectedCard?.djstripe_id : selectedCard?.id
        setSuccessData({
            po: quote?.order?.id,
            order: quote?.order?.name,
            dealer: quote?.vendor?.business_name,
            amount: quote?.quote,
        })
        const payload =
            selectedCard?.type === 'card'
                ? {
                    quote: quote?.id,
                    payment_method: id,
                    type: selectedCard?.type
                }
                : {
                    quote_id: quote?.id,
                    payment_method_id: id
                }
        payQuote(payload)
        LocalStorage.removeItem('quote_data')
    }

    const handleSelectcard = (type, id) => {

        if (type === 'business_card') {
            let selected = businessCards.filter(e => e?.id === id)[0]
            setSelectedCard(selected)
            setBusinessCards([...businessCards.map(e => e?.id === id ? { ...e, selected: true } : {
                ...e,
                selected: false
            })])
            if (!quote?.id) {
                navigate(`/customer/edit-business-card/${selected.id}`)
            }
        } else {
            setBusinessCards([...businessCards.map(e => ({ ...e, selected: false }))])
        }
        if (type === 'card') {
            let selected = cards.filter(e => e?.djstripe_id === id)[0]
            setSelectedCard(selected)
            setCards([...cards.map(e => e?.djstripe_id === id ? { ...e, selected: true } : { ...e, selected: false })])
            if (!quote?.id) {
                navigate(`/customer/edit-card/${selected.djstripe_id}`)
            }
        } else {
            setCards([...cards.map(e => ({ ...e, selected: false }))])
        }
    }

    useEffect(() => {
        (async () => {
            await getPymentData()
            await getBusinessAccounts()
            await fetchRates()
        })()
    }, [])

    async function fetchRates(base = 'USD') {
        const res = await fetch(`${endpoint}/${base}`)
        const rates = await res.json()
        setPayments(rates?.rates || {})
        setLoading(false)
    }

    return (
        <Layout showNavigation showCentreProfile withoutname showNotification>
            <PageBox>
                <div style={{ backgroundColor: '#fff', height: 'unset' }} className="title">
                    {translate('paymentMethod')}
                </div>
                <div
                    className="payment-method"
                    style={{
                        width: '100%',
                        height: quote?.id && selectedCard && Object.keys(selectedCard).length > 0 ? '80%' : '90%',
                        display: 'flex',
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                        flexDirection: 'column',
                        position: 'relative',
                        overflowY: 'auto'
                    }}
                >

                    <Container>
                        {
                            loading ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                    <CircularProgress/>
                                </div>
                                :
                                <>
                                    {
                                        cards.map((cardData, i) => (
                                            <Card
                                                key={cardData.id}
                                                cardData={cardData}
                                                className="card-data"
                                                type="card"
                                                selected={selectedCard?.djstripe_id === cardData?.djstripe_id}
                                                selectCard={handleSelectcard}
                                            />
                                        ))
                                    }
                                    {
                                        businessCards?.map((cardData, i) => (
                                            <Card
                                                key={cardData.id}
                                                cardData={cardData}
                                                className="card-data"
                                                selected={selectedCard?.id === cardData?.id}
                                                type="business_card"
                                                selectCard={handleSelectcard}
                                            />
                                        ))
                                    }
                                    {
                                        !(cards.length > 0 || businessCards?.length > 0)
                                            ? <>
                                                <Card
                                                    className="add-card"
                                                    mode="add"
                                                    navigate={() => navigate(Utils.EndPoint.userMobileEndpoints.addNewCard)}
                                                />
                                                <Card
                                                    className="add-business-card"
                                                    type="business-card"
                                                    mode="add"
                                                    navigate={() => navigate(Utils.EndPoint.userMobileEndpoints.addNewBusinessCard)}
                                                />
                                            </>
                                            :
                                            <Typography id="basic-button" style={{
                                                position: 'fixed',
                                                color: whiteLabel?.mobile_button_color || brand?.mobile_button_color || '#F8B218',
                                                bottom: '150px',
                                                right: '5px',
                                                fontSize: '28px'
                                            }}>
                                                <AddCircleIcon onClick={handleClick} style={{ fontSize: '50px' }}/>
                                            </Typography>
                                    }
                                </>
                        }
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem
                                onClick={() => navigate(Utils.EndPoint.userMobileEndpoints.addNewCard)}>{translate('addNewCard')}</MenuItem>
                            <MenuItem
                                onClick={() => navigate(Utils.EndPoint.userMobileEndpoints.addNewBusinessCard)}>{translate('addNewBusinessCard')}</MenuItem>
                        </Menu>
                    </Container>
                </div>
                {
                    quote?.id && selectedCard && Object.keys(selectedCard).length > 0 &&
                    <div style={{ height: '10%', padding: '0 16px', display: 'flex', alignItems: 'center' }}><Button
                        style={{ padding: '10px', margin: 0 }} onClick={handlePay}>{isPayingQuotation &&
                        <CircularProgress style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '10px'
                        }}/>} PAY {paymentConfig?.[0]?.currency_type && paymentConfig?.[0]?.currency_type !== 'default' ? getCurrencySymbol(paymentConfig?.[0]?.currency_type) : '$'} {payments[paymentConfig?.[0]?.currency_type] && payments['USD'] && paymentConfig?.[0]?.currency_type !== 'default' ? ((payments[paymentConfig?.[0]?.currency_type] / payments['USD']) * quote?.quote).toFixed(2) : quote?.quote}</Button>
                    </div>
                }
                <OrderPlacedModal
                    open={isOrderPlaced}
                    onClose={(mode) => {
                        setIsOrderPlaced(false)
                        setOrderResponse('')
                        if (mode === 'err') {
                            handlePay()
                        } else {
                            navigate(Utils.EndPoint.userMobileEndpoints.createOrder, {})
                            LocalStorage.removeItem('quote_data')
                        }
                    }}
                    context={rsponse}
                    mode={orderResponse}
                    data={{
                        ...successData,
                        paid: ''
                    }}
                    type="card"
                />
            </PageBox>
        </Layout>
    )
}

export default PaymentMethod
