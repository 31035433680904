import { useSelector } from "react-redux";
import LocalStorage from "../../../defined/localStorage";

const brand = JSON.parse(LocalStorage.getItem('branding'))

function Button(props) {
    const {
        children,
        onClick,
        disabled=false,
        style={},
    } = props
    const whiteLabel = useSelector((state) => state.User?.branding);
    let buttonStyle= {
        width: "100%",
        marginTop: "3rem",
        borderRadius: '7px',
        background: `linear-gradient(222.6deg, ${whiteLabel?.mobile_button_color||brand?.mobile_button_color||'#FBC916'} 0%, ${whiteLabel?.mobile_button_color||brand?.mobile_button_color||'#FBB516'} 100%)`,
        boxShadow: '2px 1px 2px 0px rgba(0, 0, 0, 0.10)',
        color: '#FFF',
        textAlign: 'center',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        height: '48px',
        border: 'none',
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
    // 
    return (
        <button
            style={{...buttonStyle, ...style}}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

export default Button