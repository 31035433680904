import React from "react"
import { StyleSheet, css } from "aphrodite"
import { useTranslation } from "react-i18next"
import i18next from 'i18next';
import { Colors } from "../../themes/Colors"
import { FONT_KEY } from "../../utils/defined"

const styles = StyleSheet.create({
  main: {
    letterSpacing: "0.4px",
    whiteSpace: "pre-line"
  }
})

export default function TextBase(props) {
  const { t, i18n } = useTranslation()
  const {
    children,
    className,
    color = Colors.white,
    size = "1rem",
    fontWeight = 400,
    style = {},
    underline = false,
    pointer = false,
    fontFamily = FONT_KEY.regular,
    ...otherProps
  } = props
  let textStyle = {
    color: color,
    fontSize: size,
    fontWeight,
    fontFamily,
    ...style
  }
  if (underline) {
    textStyle.textDecoration = "underline"
  }
  if (pointer) {
    textStyle.cursor = "pointer"
  }
  // 
  return (
    <div
      style={textStyle}
      {...otherProps}
      className={className ? className : css(styles.main)}
    >
      {typeof children === 'string'|| typeof children === 'number'?t(children):children?.map(child=>typeof child==='string' ? t(child): child)}
    </div>
  )
}
