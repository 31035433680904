import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment/moment'
import {
    DATE_TIME_FORMAT,
    DATE_TIME_FORMAT_SERVER,
    SHOW_MONTH_NAME_FORMAT
} from '../../../../modules/order-detail/helper'

const initialState = {
    loading: true,
    data: {
        orderDetail: null,
        warrantyStatus: null,
        quotesData: null
    },
}

const ordersDetailSlice = createSlice({
    name: 'ordersDetail',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading
        },
        successOrdersDetailReducer: (state, action) => {
            state.loading = action.payload.loading
            state.data = action.payload.data
        },
        failedOrdersDetailReducer: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        setOrderDetailsSlice: (state, action) => {
            state.data[action.payload.key] = action.payload.data
        },
        setOrderDetailsSliceData: (state, action) => {
            if(action.payload === null){
                state.data = initialState.data
                return
            }
            const { orderDetail } = action.payload
            let warrantyStatus = {
                status: orderDetail.warranty || false,
                name: orderDetail?.warranty_user_name
            }

            const vendors = orderDetail?.vendors
            const vendorId = vendors ? (vendors[0]?.id || vendors[0]) : null
            const quotes = orderDetail?.quotes || []
            const quoteItem = quotes.find((x) => x?.vendor?.id === vendorId)
            let quotesData = {
                id: quoteItem?.id,
                quote: quoteItem?.quote || undefined,
                date: quoteItem?.order_fullfill_date
                    ? moment(quoteItem?.order_fullfill_date, DATE_TIME_FORMAT_SERVER).format(DATE_TIME_FORMAT)
                    : '',
                desc: quoteItem?.description || '',
                name: quoteItem?.name || '',
                updatedAt: quoteItem?.updated_at
                    ? moment(quoteItem?.updated_at, DATE_TIME_FORMAT_SERVER).format(SHOW_MONTH_NAME_FORMAT)
                    : ''
            }

            state.data = {
                orderDetail,
                warrantyStatus,
                quotesData
            }
        }
    }
})

export const {
    successOrdersDetailReducer,
    failedOrdersDetailReducer,
    loaderListener,
    setOrderDetailsSliceData,
    setOrderDetailsSlice
} = ordersDetailSlice.actions

export default ordersDetailSlice.reducer
