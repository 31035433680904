import React from 'react';
import BasePage from '../base';
import { css, StyleSheet } from 'aphrodite';
import { convertPxToRem, filterQuoteStatus, filterQuoteStatusColor, handleStatus, getValueFromObjectByKeys, myLog } from '../../helper';
import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import { heightOfHeader, sizeOfBaseButton } from '../../utils/defined';
import { connect } from 'react-redux';
import withNavigate from '../../component/WithNavigate';
import { Col, Dropdown, Row, Spin, Table, DatePicker, Radio, Button, AutoComplete, Menu, Checkbox, Typography } from 'antd';
import { SVGImageComponentReact } from '../../themes/Images';
import { DownOutlined } from '@ant-design/icons';
import './CustomDropdown.css'; // Import the custom CSS
import { Line, Column } from '@ant-design/plots';
import { UserAction } from '../../redux/action';
import moment from 'moment/moment';
import { ROUTER_NAME } from '../../router/routers';
import APIManager from '../../connection/ApiManager';
import { translate } from '../../helper'
import LocalStorage from "../../defined/localStorage";
import LoginInput from '../../common/login-input';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import './CustomDropdown.css';
import iconCal from '../../assets/image/png/calender.png'
import dollarIcon from "../../assets/image/png/dollar.png"
import increaseIcon from "../../assets/image/png/increase.png"
import decreaseIcon from "../../assets/image/png/decrease.png"
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import { formatCurrency } from '../../utils/CommonFunction';


dayjs.extend(customParseFormat);
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
const dateFormat = 'YYYY-MM-DD';
const US_DATE_FORMAT = 'MM/DD/YYYY';
const { RangePicker } = DatePicker;
const brand = JSON.parse(LocalStorage.getItem('branding'))

const heightOfBottomBlackAlpha = convertPxToRem(284);

const styles = StyleSheet.create({
  container: {
    textAlign: 'left',
    whiteSpace: 'pre-line',
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: `calc(100vh - ${heightOfHeader})`,
    paddingTop: convertPxToRem(20),
    paddingLeft: convertPxToRem(32),
    paddingRight: convertPxToRem(32),
    paddingBottom: convertPxToRem(10)
  },
  dashboardTitle: {
    marginBottom: convertPxToRem(52),
    marginTop: convertPxToRem(50)
  },
  bottomBlackAlpha: {
    width: '100%',
    minWidth: '100vw',
    height: heightOfBottomBlackAlpha,
    backgroundColor: Colors.black80,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 1,
    position: 'absolute'
  },
  bgOverlay: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.56) 100%)',
    zIndex: 1,
    overflow: 'hidden'
  },
  wrapText: {
    position: 'relative',
    zIndex: 4,
    maxWidth: '50%',
    height: '100%',
    minHeight: convertPxToRem(400),
    paddingLeft: convertPxToRem(100)
  },
  buttonAction: {
    width: sizeOfBaseButton.width,
    height: sizeOfBaseButton.height,
    marginBottom: convertPxToRem(33)
  }
});

function roundDownToNearest(number, powerOfTen) {
  const factor = Math.pow(10, powerOfTen);
  return Math.floor(number / factor) * factor;
}

class Analytics extends BasePage {
  constructor(props) {
    super(props);
    this.title = 'Analytics';
    this.state = {
      showOrdersMonthSelect: false,
      selectedMonth: moment().format('MMMM'),
      dataChartArea: null,
      quoteSummary: [],
      ordersSent: 0,
      ordersAccepted: 0,
      quotesSent: 0,
      locations: 0,
      selectedId: '',
      radios: [{ name: "Numbers", selected: true }, { name: "Values", selected: false }],
      orders: [],
      loading: false,
      startOptions: [],
      textSearch: '',
      selectedDateRange: [dayjs(), dayjs()],
      showFilterOrder: false,
      filterOrdersCreated: 'monthly',
      filterOrdersCreatedBar: 'month',
      filterOrders: 'monthly',
      filterType: 'Numbers',
      filters: ['orders_received', 'orders_accepted', 'quotes_sent', 'accepted_quotes'],
      value_of_accepted_quotes: null,
      percentage_change: null,
      filter: 'monthly',
      page: 1,
      pageSize: 15,
      totalOrders: 0,
      configChartColumn: {
        data: [],
        isGroup: true,
        xField: 'month',
        yField: 'value',
        seriesField: 'category',
        appendPadding: 10,
        intervalPadding: 50,
        columnWidthRatio: 0.5,
        maxColumnWidth: 30,
        style: {
          inset: 1
        },
        color: ['#2F80ED', '#002555'],
        columnStyle: {
          dodgePadding: 10,
          columnWidthRatio: 0.5,
        },
        label: {
          textBaseline: 'bottom',
          style: {
            rotate: -90,
            textAlign: 'center',
            textBaseline: 'middle',
          },
          layout: [
            {
              type: 'interval-adjust-position'
            },          ]
        },
        yAxis: {
          max: 0
        }
      },
      configChartArea: {
        xField: 'month',
        yField: 'count',
        legend: {
          position: 'bottom',
          marginTop: 10
        },
        seriesField: 'category',
        color: ['#1979C9', '#FAA219', '#ff0066', '#000000'], // Specify colors for each line
        xAxis: {
          range: [0, 1]
        }
      }
    };
    this.columns = [
      {
        title: translate('dateCreated'),
        dataIndex: 'created_at',
        align: 'left',
        key: 'created_at',
        render: (value) => {
          return moment(value, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY hh:mm a');
        }
      },
      {
        title: translate('customerPo'),
        dataIndex: 'id',
        align: 'left',
        key: 'id',
        render: (text) => text || '2343434'
      },
      {
        title: translate('orderName'),
        dataIndex: 'fulfilled_by',
        align: 'left',
        key: 'fulfilled_by',
        render: (_, row) => {
          return row.name || '';
        }
      },
      {
        title: translate('status'),
        dataIndex: 'status',
        align: 'left',
        key: 'status',
        render: (__, row) => {
          let statusColor = filterQuoteStatusColor(handleStatus(row));
          let status = filterQuoteStatus(handleStatus(row));
          const deliverLabelMap = {
            'instore': translate('acceptedInStorePickup'),
            'delivery': translate('acceptedDelivery')
          };
          return <>
            <div style={{ color: statusColor, fontWeight: 600, display: "flex" }}>
              {status}
              {
                row?.status === 'INPROGRESS' && row?.quotes?.length === 0 &&
                <Typography variant="p" fontWeight={'600'} color={'#000'}>
                  {` - ${row?.order_vendor_note_user_name?.trim()}`}
                </Typography>
              }
              {
                status === 'Paid' &&
                <Typography variant="p" fontWeight={'600'} color={'#000'}>
                  {` - ${deliverLabelMap[row?.delivery_method]}`}
                </Typography>
              }
            </div>
          </>;
        }
      },
      {
        title: translate('warrantyStatus'),
        dataIndex: 'status',
        align: 'left',
        key: 'status',
        render: (__, row) => {
          return (
            row?.warranty ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <CheckIcon style={{ color: "#4CE865" }} /> {row.warranty_user_name}
              </div>
            ) : (
              <CheckBoxOutlineBlankIcon className="unchecked" />
            )
          );
        }
      },
      {
        title: translate('fulfilledBy'),
        dataIndex: 'email',
        align: 'left',
        key: 'email',
        render: (__, row) => {
          let statusColor = filterQuoteStatusColor(handleStatus(row));
          let status = filterQuoteStatus(handleStatus(row));
          const deliverLabelMap = {
            'instore': translate('acceptedInStorePickup'),
            'delivery': translate('acceptedDelivery')
          };
          return <>
            {this.handleFullfilledBy(row)}
          </>
        }
      },
      {
        title: translate('orderInfo'),
        key: 'tags',
        dataIndex: 'tags',
        align: 'left',
        render: (__, row) => {

          return (
            <div
              style={{ display: "flex", alignItems: "center", background: "#F3F4F6", borderRadius: "5px", width: 100, padding: "5px 15px 5px 15px", cursor: "pointer" }}
              onClick={() => {
                this.props?.navigate(`${ROUTER_NAME.orderDetail}/${row.id}`)
              }}
            >
              {translate('details')}
              <ArrowForwardIos className="icon" style={{ width: 12, height: 12, marginLeft: "10px" }} />
            </div>
          );
        }
      }
    ];
    this.timeoutId = null
  }

  handleFullfilledBy = (row) => {
    const quote = row.quotes?.filter((q) => q.status === 'ACCEPTED');
    const pending = row.quotes?.find((q) => q.status === 'SENT');
    return quote.length > 0
      ? quote?.[0]?.user?.name
      : pending?.id
        ? pending?.user?.name
        : 'N/A';
  };

  filterQuoteStatus = (status) => {
    switch (status) {
      case 'SENT':
        return 'Sent';
      case 'ACCEPTED':
        return 'Accepted';
      case 'NS':
        return 'Pending';
      default:
        return 'Pending';
    }
  };

  renderMonthSelect = () => {
    const months = [
      {
        month: translate('month.january'),
        value: 1
      },
      {
        month: translate('month.february'),
        value: 2
      },
      {
        month: translate('month.march'),
        value: 3
      },
      {
        month: translate('month.april'),
        value: 4
      },
      {
        month: translate('month.may'),
        value: 5
      },
      {
        month: translate('month.june'),
        value: 6
      },
      {
        month: translate('month.july'),
        value: 7
      },
      {
        month: translate('month.august'),
        value: 8
      },
      {
        month: translate('month.september'),
        value: 9
      },
      {
        month: translate('month.october'),
        value: 10
      },
      {
        month: translate('month.november'),
        value: 11
      },
      {
        month: translate('month.december'),
        value: 12
      }
    ];
    return months.map((e) => (
      <TextBase
        style={{
          paddingTop: convertPxToRem(15),
          paddingBottom: convertPxToRem(8)
        }}
        className="cursor-pointer"
        size={14}
        color={Colors.black}
        onClick={() => {
          this.setState(
            {
              showOrdersMonthSelect: false,
              selectedMonth: moment()
                .month(e.value - 1)
                .format('MMMM')
            },
            () => {
              this.fetchOrders();
            }
          );
        }}>
        {e.month}
      </TextBase>
    ));
  };

  _componentDidMount = () => {
    this.fetchData();
    this.fetchAcceptedOrders();
    this.fetchOrderSummary();
    this.fetchOrders();
    this.fetchOrderList(this.state.selectedId);
  };

  async fetchOrderList(id) {
    this.setState({
      loading: true
    });
    let query = {
      filter_type: this.state.filter,
      page: this.state.page,
      page_size: this.state.pageSize,
      ...(id && { child_account_id: id })
    };
    const resOrder = await APIManager.getInstance().getOrders(query);
    this.setState({
      orders: resOrder?.data?.data || [],
      loading: false,
      totalOrders: resOrder?.data?.page?.total_records || 0
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.page !== this.state.page)|| (prevState.pageSize !== this.state.pageSize) || (prevState.filter !== this.state.filter)) {
      // Clear previous timeout if it exists
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      // Set new timeout for API call
      this.timeoutId = setTimeout(async () => {
        this.fetchOrderList(this.state.selectedId);
      }, 0); // 300ms debounce delay
    }
  }


  getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: 'long' });
  }

  getCurrentYear = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    return currentYear
  }

  fetchData = async () => {
    try {
      const { getAnalyticsCards, getAnalyticsCardsWithoutEndDate } = this.props;
      let resp = ''
      if (this.state.selectedDateRange[0].format(dateFormat) == this.state.selectedDateRange[1].format(dateFormat)) {
        resp = await getAnalyticsCardsWithoutEndDate(this.state.textSearch, this.state.selectedDateRange[0].format(dateFormat));
      } else {
        resp = await getAnalyticsCards(this.state.textSearch, this.state.selectedDateRange[0].format(dateFormat), this.state.selectedDateRange[1].format(dateFormat));
      }
      const { data } = resp
      const ordersSent = data?.order_received;
      const quotesSent = data?.quotes_sent;
      const locations = data?.child_account_count;
      const ordersAccepted = data?.orders_accepted;
      const value_of_accepted_quotes = data?.value_of_accepted_quotes
      const percentage_change = data?.percentage_change
      this.setState({
        ordersSent: ordersSent || 0,
        ordersAccepted: ordersAccepted || 0,
        quotesSent: quotesSent || 0,
        locations: locations || 0,
        value_of_accepted_quotes: value_of_accepted_quotes || 0,
        percentage_change: percentage_change || null
      });
    } catch (e) {

    }
  };

  fetchOrders = async (filter = 'monthly') => {
    try {

      this.setState({
        page: 1,
        filter: filter
      })
    } catch (e) {

    }
  };

  getMonthName = (month) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[month - 1] || 'Invalid month';
  };

  fetchAcceptedOrders = async (filter = 'month') => {
    try {
      let result = []
      const { getAcceptedOrders } = this.props;
      let resp = ''
      if (this.state.selectedId) {
        let child_account_id = this.state.selectedId
        resp = await getAcceptedOrders({ child_account_id, filter });
      } else {
        resp = await getAcceptedOrders({ filter });
      }
      const data = resp?.data

      data?.forEach((e, i) => {
        if (filter == 'month') {
          result.push(
            {
              category: translate('quoteSent'),
              month: translate('month.' + this.getMonthName(e.month).toLowerCase()),
              value: e.quotes_sent
            }
          );
        } else {
          result.push(
            {
              category: translate('quoteSent'),
              year: e.year,
              value: e.quotes_sent
            }
          );
        }
      });

      data?.forEach((e, i) => {
        if (filter == 'month') {
          result.push(
            {
              category: translate('acceptedQuote'),
              month: translate('month.' + this.getMonthName(e.month).toLowerCase()),
              value: e.accepted_quotes
            }
          );
        } else {
          result.push(
            {
              category: translate('acceptedQuote'),
              year: e.year,
              value: e.accepted_quotes
            }
          );
        }

      });
      const columnsChart = this.state.configChartColumn;
      let max = Math.max(...result.map((item) => item.value))
      columnsChart.yAxis.max = max + (10**(String(Math.floor(max)).length - 1))
      columnsChart.data = result;
      this.setState({
        configChartColumn: columnsChart
      });
    } catch (e) {

    }
  };


  fetchOrderSummary = async () => {
    this.setState({
      dataChartArea: []
    });
    try {
      const { getNumberOfQuotes } = this.props;

      let tempFilter = [...this.state.filters]

      let resp = ''
      let result = []
      for (let i = 0; i < tempFilter.length; i++) {
        if (this.state.selectedId) {
          resp = await getNumberOfQuotes({ year: this.getCurrentYear(), child_account_id: this.state.selectedId, group_by: this.state.filterOrdersCreated, filter: tempFilter[i], type: this.state.filterType == 'Numbers' ? 'numbers' : 'values' });
        } else {
          resp = await getNumberOfQuotes({ year: this.getCurrentYear(), group_by: this.state.filterOrdersCreated, filter: tempFilter[i], type: this.state.filterType == 'Numbers' ? 'numbers' : 'values' });
        }
        const { data } = resp
        
        if (this.state.filterOrdersCreated == 'monthly') {
          data?.summary?.map((item) => {
            if (tempFilter[i] == 'accepted_quotes') {
              result.push({ count: item.accepted_quotes_value, month: item.month, category: this.state.filterType == 'Numbers' ? 'Accepted Quotes' : 'Value of Accepted Quotes' })
            } else if (tempFilter[i] == 'orders_accepted') {
              result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Orders Accepted' : 'Value of Orders Accepted' })
            } else if (tempFilter[i] == 'orders_received') {
              result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Orders Received' : 'Value of Orders Received' })
            } else {
              result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Quotes Sent' : 'Value of Quotes Sent' })
            }
          })
        } else {
          data?.summary?.map((item) => {
            if (tempFilter[i] == 'accepted_quotes') {
              result.push({ count: item.accepted_quotes_value, year: item.year, category: this.state.filterType == 'Numbers' ? 'Accepted Quotes' : 'Value of Accepted Quotes' })
            } else if (tempFilter[i] == 'orders_accepted') {
              result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Orders Accepted' : 'Value of Orders Accepted' })
            } else if (tempFilter[i] == 'orders_received') {
              result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Orders Received' : 'Value of Orders Received' })
            } else {
              result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Quotes Sent' : 'Value of Quotes Sent' })
            }
          })
        }
      }

      this.setState({
        dataChartArea: result || []
      });
    } catch (e) {

    }
  };

  fetchSearch = async (text) => {
    try {
      const { getSearchAnalytics } = this.props;

      const { data } = await getSearchAnalytics(text);

      let temp = []
      data?.data?.map(({ location_name, id }) => {
        temp.push({ value: location_name, id: id })
      })

      this.setState({
        startOptions: temp
      })
    } catch (e) {

    }
  };

  handleSearch = (text) => {
    this.setState({
      textSearch: text
    })
    if (text) {
      this.fetchSearch(text)
    } else {
      this.setState({
        startOptions: [],
        selectedId: ''
      })
      setTimeout(() => {
        this.fetchData();
        this.fetchAcceptedOrders();
        this.fetchOrderSummary();
        this.fetchOrders();
      }, 1000)

    }
  }

  handleDateChange = async (dates) => {
    if (!dates || (Array.isArray(dates) && dates.length === 0)) {
      // Perform your tasks when the range is cleared

      this.setState({
        selectedDateRange: [dayjs, dayjs]
      })
    } else {


      this.setState({
        selectedDateRange: dates
      })
      try {
        const { getAnalyticsCards, getAnalyticsCardsWithoutEndDate } = this.props;
        let resp = ''
        if (dates[0].format(dateFormat) == dates[1].format(dateFormat)) {
          resp = await getAnalyticsCardsWithoutEndDate(this.state.selectedId, dates[0].format(dateFormat));
        } else {
          resp = await getAnalyticsCards(this.state.selectedId, dates[0].format(dateFormat), dates[1].format(dateFormat));
        }
        const { data } = resp
        const ordersSent = data?.order_received;
        const quotesSent = data?.quotes_sent;
        const locations = data?.child_account_count;
        const ordersAccepted = data?.orders_accepted;
        const value_of_accepted_quotes = data?.value_of_accepted_quotes
        const percentage_change = data?.percentage_change
        this.setState({
          ordersSent: ordersSent || 0,
          ordersAccepted: ordersAccepted || 0,
          quotesSent: quotesSent || 0,
          locations: locations || 0,
          value_of_accepted_quotes: value_of_accepted_quotes || 0,
          percentage_change: percentage_change || null
        });
      } catch (e) {

      }
    }
  };

  radioChange = async (index, value) => {

    const prevRadio = [...this.state.radios]
    prevRadio.map((item) => {
      item.selected = false
    })
    prevRadio[index].selected = true
    this.setState({
      radios: prevRadio
    })

    try {
      const { getNumberOfQuotes } = this.props;
      let result = []
      let tempFilter = [...this.state.filters]
      let resp = ''
      for (let i = 0; i < tempFilter.length; i++) {
        if (this.state.selectedId) {
          resp = await getNumberOfQuotes({ year: this.getCurrentYear(), child_account_id: this.state.selectedId, group_by: this.state.filterOrdersCreated, filter: tempFilter[i], type: value == 'Numbers' ? 'numbers' : 'values' });
        } else {
          resp = await getNumberOfQuotes({ year: this.getCurrentYear(), group_by: this.state.filterOrdersCreated, filter: tempFilter[i], type: value == 'Numbers' ? 'numbers' : 'values' });
        }

        const { data } = resp

        if (this.state.filterOrdersCreated == 'monthly') {
          data?.summary?.map((item) => {
            if (tempFilter[i] == 'accepted_quotes') {
              result.push({ count: item.accepted_quotes_value, month: item.month, category: value == 'Numbers' ? 'Accepted Quotes' : 'Value of Accepted Quotes' })
            } else if (tempFilter[i] == 'orders_accepted') {
              result.push({ count: item[tempFilter[i]], month: item.month, category: value == 'Numbers' ? 'Orders Accepted' : 'Value of Orders Accepted' })
            } else if (tempFilter[i] == 'orders_received') {
              result.push({ count: item[tempFilter[i]], month: item.month, category: value == 'Numbers' ? 'Orders Received' : 'Value of Orders Received' })
            } else {
              result.push({ count: item[tempFilter[i]], month: item.month, category: value == 'Numbers' ? 'Quotes Sent' : 'Value of Quotes Sent' })
            }
          })
        } else {
          data?.summary?.map((item) => {
            if (tempFilter[i] == 'accepted_quotes') {
              result.push({ count: item.accepted_quotes_value, year: item.year, category: value == 'Numbers' ? 'Accepted Quotes' : 'Value of Accepted Quotes' })
            } else if (tempFilter[i] == 'orders_accepted') {
              result.push({ count: item[tempFilter[i]], year: item.year, category: value == 'Numbers' ? 'Orders Accepted' : 'Value of Orders Accepted' })
            } else if (tempFilter[i] == 'orders_received') {
              result.push({ count: item[tempFilter[i]], year: item.year, category: value == 'Numbers' ? 'Orders Received' : 'Value of Orders Received' })
            } else {
              result.push({ count: item[tempFilter[i]], year: item.year, category: value == 'Numbers' ? 'Quotes Sent' : 'Value of Quotes Sent' })
            }
          })
        }
      }
      this.setState({
        filterType: value,
        dataChartArea: result || []
      });
    } catch (e) {

    }
  }

  handleSelect = async (data) => {
    let search = data
    let temp = []
    try {
      const { getSearchAnalytics } = this.props;
      const { data } = await getSearchAnalytics(search);


      data?.data?.map(({ location_name, id }) => {
        temp.push({ value: location_name, id: id })
      })

      this.setState({
        selectedId: temp[0].id
      }, ()=>this.fetchOrderList(temp[0].id))

      try {
        const { getAnalyticsCards } = this.props;

        const { data } = await getAnalyticsCards(temp[0].id, this.state.selectedDateRange[0].format(dateFormat), this.state.selectedDateRange[1].format(dateFormat));


        const ordersSent = data?.order_received;
        const quotesSent = data?.quotes_sent;
        const locations = data?.child_account_count;
        const ordersAccepted = data?.orders_accepted;
        const value_of_accepted_quotes = data?.value_of_accepted_quotes

        this.setState({
          ordersSent: ordersSent || 0,
          ordersAccepted: ordersAccepted || 0,
          quotesSent: quotesSent || 0,
          locations: locations || 0,
          value_of_accepted_quotes: value_of_accepted_quotes || 0
        });
      } catch (e) {

      }

      try {
        let result = []
        const { getAcceptedOrders } = this.props;
        let filter = this.state.filterOrdersCreatedBar
        let child_account_id = temp[0].id
        const { data } = await getAcceptedOrders({ child_account_id, filter });

        if (this.state.filterOrdersCreatedBar == 'month') {
          data?.forEach((e, i) => {
            result.push(
              {
                name: translate('quoteSent'),
                month: translate('month.' + this.getMonthName(e.month).toLowerCase()),
                value: e.quotes_sent
              }
            );
          });
        } else {
          data?.forEach((e, i) => {
            result.push(
              {
                name: translate('quoteSent'),
                year: e.year,
                value: e.quotes_sent
              }
            );
          });
        }

        if (this.state.filterOrdersCreatedBar == 'month') {
          data?.forEach((e, i) => {
            result.push(
              {
                name: translate('acceptedQuote'),
                month: translate('month.' + this.getMonthName(e.month).toLowerCase()),
                value: e.accepted_quotes
              }
            );
          });
        } else {
          data?.forEach((e, i) => {
            result.push(
              {
                name: translate('acceptedQuote'),
                year: e.year,
                value: e.accepted_quotes
              }
            );
          });
        }

        const columnsChart = this.state.configChartColumn;
        columnsChart.data = result;
        this.setState({
          configChartColumn: columnsChart
        });
      } catch (e) {

      }

      try {
        const { getNumberOfQuotes } = this.props;
        let result = []
        let tempFilter = [...this.state.filters]

        for (let i = 0; i < tempFilter.length; i++) {
          const { data } = await getNumberOfQuotes({ year: this.getCurrentYear(), child_account_id: temp[0].id, group_by: this.state.filterOrdersCreated, filter: tempFilter[i], type: this.state.filterType == 'Numbers' ? 'numbers' : 'values' });

          if (this.state.filterOrdersCreated == 'monthly') {
            data?.summary?.map((item) => {
              if (tempFilter[i] == 'accepted_quotes') {
                result.push({ count: item.accepted_quotes_value, month: item.month, category: this.state.filterType == 'Numbers' ? 'Accepted Quotes' : 'Value of Accepted Quotes' })
              } else if (tempFilter[i] == 'orders_accepted') {
                result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Orders Accepted' : 'Value of Orders Accepted' })
              } else if (tempFilter[i] == 'orders_received') {
                result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Orders Received' : 'Value of Orders Received' })
              } else {
                result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Quotes Sent' : 'Value of Quotes Sent' })
              }
            })
          } else {
            data?.summary?.map((item) => {
              if (tempFilter[i] == 'accepted_quotes') {
                result.push({ count: item.accepted_quotes_value, year: item.year, category: this.state.filterType == 'Numbers' ? 'Accepted Quotes' : 'Value of Accepted Quotes' })
              } else if (tempFilter[i] == 'orders_accepted') {
                result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Orders Accepted' : 'Value of Orders Accepted' })
              } else if (tempFilter[i] == 'orders_received') {
                result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Orders Received' : 'Value of Orders Received' })
              } else {
                result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Quotes Sent' : 'Value of Quotes Sent' })
              }
            })
          }

        }

        this.setState({
          filters: tempFilter,
          dataChartArea: result || []
        });
      } catch (e) {

      }
    } catch (e) {

    }
  };

  handleMenuClick = (e) => {
  };

  handleCheckboxChange = async (e, itemName) => {
    try {
      const { getNumberOfQuotes } = this.props;
      let result = []
      let tempFilter = [...this.state.filters]
      if (tempFilter.includes(itemName)) {
        if (itemName != 'orders_received') {
          const index = tempFilter.indexOf(itemName);
          if (index !== -1) {
            tempFilter.splice(index, 1); // Remove the item from the copied array using splice
          }
        }
      } else {
        tempFilter.push(itemName)
      }

      let resp = ''
      for (let i = 0; i < tempFilter.length; i++) {
        if (this.state.selectedId) {
          resp = await getNumberOfQuotes({ year: this.getCurrentYear(), child_account_id: this.state.selectedId, group_by: this.state.filterOrdersCreated, filter: tempFilter[i], type: this.state.filterType == 'Numbers' ? 'numbers' : 'values' });
        } else {
          resp = await getNumberOfQuotes({ year: this.getCurrentYear(), group_by: this.state.filterOrdersCreated, filter: tempFilter[i], type: this.state.filterType == 'Numbers' ? 'numbers' : 'values' });
        }
        const { data } = resp

        if (this.state.filterOrdersCreated == 'monthly') {
          data?.summary?.map((item) => {
            if (tempFilter[i] == 'accepted_quotes') {
              result.push({ count: item.accepted_quotes_value, month: item.month, category: this.state.filterType == 'Numbers' ? 'Accepted Quotes' : 'Value of Accepted Quotes' })
            } else if (tempFilter[i] == 'orders_accepted') {
              result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Orders Accepted' : 'Value of Orders Accepted' })
            } else if (tempFilter[i] == 'orders_received') {
              result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Orders Received' : 'Value of Orders Received' })
            } else {
              result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Quotes Sent' : 'Value of Quotes Sent' })
            }
          })
        } else {
          data?.summary?.map((item) => {
            if (tempFilter[i] == 'accepted_quotes') {
              result.push({ count: item.accepted_quotes_value, year: item.year, category: this.state.filterType == 'Numbers' ? 'Accepted Quotes' : 'Value of Accepted Quotes' })
            } else if (tempFilter[i] == 'orders_accepted') {
              result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Orders Accepted' : 'Value of Orders Accepted' })
            } else if (tempFilter[i] == 'orders_received') {
              result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Orders Received' : 'Value of Orders Received' })
            } else {
              result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Quotes Sent' : 'Value of Quotes Sent' })
            }
          })
        }
      }


      this.setState({
        filters: tempFilter,
        dataChartArea: result || []
      });
    } catch (e) {

    }
  };

  handleMonthYearChangeLineGraph = async (value) => {
    try {
      const { getNumberOfQuotes } = this.props;
      let result = []
      let tempFilter = [...this.state.filters]
      for (let i = 0; i < tempFilter.length; i++) {
        let resp = ''
        if (this.state.selectedId) {
          resp = await getNumberOfQuotes({ year: this.getCurrentYear(), child_account_id: this.state.selectedId, group_by: value, filter: tempFilter[i], type: this.state.filterType == 'Numbers' ? 'numbers' : 'values' });
        } else {
          resp = await getNumberOfQuotes({ year: this.getCurrentYear(), group_by: value, filter: tempFilter[i], type: this.state.filterType == 'Numbers' ? 'numbers' : 'values' });
        }
        const { data } = resp

        if (value == 'monthly') {
          data?.summary?.map((item) => {
            if (tempFilter[i] == 'accepted_quotes') {
              result.push({ count: item.accepted_quotes_value, month: item.month, category: this.state.filterType == 'Numbers' ? 'Accepted Quotes' : 'Value of Accepted Quotes' })
            } else if (tempFilter[i] == 'orders_accepted') {
              result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Orders Accepted' : 'Value of Orders Accepted' })
            } else if (tempFilter[i] == 'orders_received') {
              result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Orders Received' : 'Value of Orders Received' })
            } else {
              result.push({ count: item[tempFilter[i]], month: item.month, category: this.state.filterType == 'Numbers' ? 'Quotes Sent' : 'Value of Quotes Sent' })
            }
          })
        } else {
          data?.summary?.map((item) => {
            if (tempFilter[i] == 'accepted_quotes') {
              result.push({ count: item.accepted_quotes_value, year: item.year, category: this.state.filterType == 'Numbers' ? 'Accepted Quotes' : 'Value of Accepted Quotes' })
            } else if (tempFilter[i] == 'orders_accepted') {
              result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Orders Accepted' : 'Value of Orders Accepted' })
            } else if (tempFilter[i] == 'orders_received') {
              result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Orders Received' : 'Value of Orders Received' })
            } else {
              result.push({ count: item[tempFilter[i]], year: item.year, category: this.state.filterType == 'Numbers' ? 'Quotes Sent' : 'Value of Quotes Sent' })
            }
          })
        }
      }


      this.setState({
        dataChartArea: result || []
      });
    } catch (e) {

    }
  }

  filterNames = () => {
    let value = ''
    if (this.state.filterType == "Numbers") {
      if (this.state.filters.length == 1 && this.state.filters[0] == 'orders_received') {
        value = 'Choose'
        return value
      } else {
        this.state.filters.map((item) => {
          if (item == 'accepted_quotes') {
            value = value + ' v/s Accepted Quotes'
          } else if (item == 'orders_accepted') {
            value = value + ' v/s Orders Accepted'
          } else if (item == 'orders_received') {
            value = value + ' v/s Orders Received'
          } else {
            value = value + ' v/s Quotes Sent'
          }
        })
        return value.substring(4)
      }
    } else {
      if (this.state.filters.length == 1 && this.state.filters[0] == 'orders_received') {
        value = 'Choose'
        return value
      } else {
        this.state.filters.map((item) => {
          if (item == 'accepted_quotes') {
            value = value + ' v/s Value of Accepted Quotes'
          } else if (item == 'orders_accepted') {
            value = value + ' v/s Value of Orders Accepted'
          } else if (item == 'orders_received') {
            value = value + ' v/s Value of Orders Received'
          } else {
            value = value + ' v/s Value of Quotes Sent'
          }
        })
        return value.substring(4)
      }
    }
  }

  checkDateisToday = () => {
    const today = new Date();
    const todayStr = today.toISOString().split('T')[0]; // Format YYYY-MM-DD
    if (this.state.selectedDateRange[0].format(dateFormat) === todayStr && this.state.selectedDateRange[1].format(dateFormat) === todayStr) {
      return true
    } else {
      return false
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      page: pagination.current,
      pageSize: pagination.pageSize
    })
  }

  renderContent() {
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">
          <Checkbox className="custom-checkbox"
            checked
          >Orders Received</Checkbox>
        </Menu.Item>
        <Menu.Item key="2">
          <Checkbox checked={this.state.filters.includes('orders_accepted')} className="custom-checkbox" onChange={(e) => this.handleCheckboxChange(e, 'orders_accepted')}>Orders Accepted</Checkbox>
        </Menu.Item>
        <Menu.Item key="3">
          <Checkbox checked={this.state.filters.includes('quotes_sent')} className="custom-checkbox" onChange={(e) => this.handleCheckboxChange(e, 'quotes_sent')}>Quote Sent</Checkbox>
        </Menu.Item>
        <Menu.Item key="3">
          <Checkbox checked={this.state.filters.includes('accepted_quotes')} className="custom-checkbox" onChange={(e) => this.handleCheckboxChange(e, 'accepted_quotes')}>Accepted Quotes</Checkbox>
        </Menu.Item>
      </Menu>
    );

    const {
      dataChartArea,
      ordersSent,
      ordersAccepted,
      quotesSent,
      showFilterOrder,
      filterOrdersCreated,
      filterOrdersCreatedBar,
      selectedMonth,
      showOrdersMonthSelect,
      textSearch,
      selectedDateRange,
      radios,
      startOptions,
      locations,
      percentage_change,
      filterOrders
    } = this.state;
    return (
      <div style={{ backgroundColor: "#fff" }} className={css(styles.container)}>
        <div className="flex-row-center-between width-100" style={{ marginBottom: convertPxToRem(30) }}>
          <div>
            <TextBase
              fontWeight={700}
              size={convertPxToRem(25)}
              color={Colors.titlePage}
            >
              Analytics
            </TextBase>
          </div>
          <div className='flex-row-center-between' style={{ width: 580 }}>
            <div style={{ border: "1px solid #D9D9D9", borderRadius: "5px", height: "49px", display: "flex", alignItems: "center", background: "white", paddingRight: "5px" }}>
              <AutoComplete
                style={{ width: 250, height: "25px", fontSize: "18px" }}
                value={textSearch}
                onSelect={this.handleSelect}
                options={startOptions?.map((option) => ({
                  value: option.value,
                }))}
                onChange={this.handleSearch}
              >
                <input
                  placeholder={"Your account"}
                  style={{
                    border: 'none',
                    outline: 'none',
                    marginTop: "-10px"
                  }}
                />
              </AutoComplete>
              <SVGImageComponentReact.IconSearch className="search-icon" style={{ marginRight: "10px" }} />
            </div>
            <RangePicker suffixIcon={<img src={iconCal} style={{ width: 26, height: 26 }} />} style={{ width: 290, height: 49, borderRadius: 5 }}
              value={selectedDateRange}
              onChange={this.handleDateChange}
              format={US_DATE_FORMAT}
              allowClear={false}
            />
          </div>
        </div>

        <Row style={{ marginBottom: convertPxToRem(32) }}>
          <div className="flex-row-center width-100"
            style={{
              backgroundColor: Colors.white,
              borderRadius: convertPxToRem(10),
              paddingTop: convertPxToRem(20),
              paddingBottom: convertPxToRem(20),
              paddingLeft: convertPxToRem(15),
              position: 'relative',
              boxShadow: '0px 1px 2px 1px #0000000D'
            }}
          >
            {percentage_change ?
              <div className="flex-row-center" style={{ borderRight: '1px solid #C8C8C8', paddingRight: convertPxToRem(28) }}>
                <img src={dollarIcon} />
                <span style={{ color: '#383938', fontSize: convertPxToRem(16), display: 'inline-block', paddingRight: convertPxToRem(20), paddingLeft: convertPxToRem(20) }}>Value of Accepted Quotes</span>
                <span style={{ fontWeight: '600', fontSize: convertPxToRem(24) }}>{formatCurrency(this.state.value_of_accepted_quotes)}</span>
              </div>
              :
              <div className="flex-row-center" style={{ paddingRight: convertPxToRem(28) }}>
                <img src={dollarIcon} />
                <span style={{ color: '#383938', fontSize: convertPxToRem(16), display: 'inline-block', paddingRight: convertPxToRem(20), paddingLeft: convertPxToRem(20) }}>Value of Accepted Quotes</span>
                <span style={{ fontWeight: '600', fontSize: convertPxToRem(24) }}>{formatCurrency(this.state.value_of_accepted_quotes)}</span>
              </div>
            }

            {(this.checkDateisToday() && percentage_change) && <div className="flex-row-center" style={{ fontSize: convertPxToRem(16), paddingLeft: convertPxToRem(28) }}>
              <img src={percentage_change > 0 ? increaseIcon : decreaseIcon} />
              <span style={{ marginRight: convertPxToRem(14), fontWeight: '600', display: 'inline-block', paddingLeft: convertPxToRem(20) }}>{percentage_change.toFixed(2)} %</span>
              <span style={{ display: 'inline-block' }}>From yesterday</span>
            </div>}
          </div>
        </Row>
        <Row gutter={[24, 24, 24]} style={{ marginBottom: convertPxToRem(24) }}>
          <Col span={6} xs={24} sm={24} md={6}>
            <div
              style={{
                backgroundColor: Colors.quote_send_14,
                borderRadius: convertPxToRem(10),
                paddingTop: convertPxToRem(39),
                paddingBottom: convertPxToRem(49),
                paddingLeft: convertPxToRem(24),
                position: 'relative',
                boxShadow: '0px 1px 2px 1px #0000000D'

              }}>
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <div style={{ position: 'relative' }}>
                  <SVGImageComponentReact.BgItemCardAnalytic
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0
                    }}
                  />
                </div>
              </div>
              <TextBase color={Colors.gray2} size={convertPxToRem(14)} style={{}}>
                numberOfLocations
              </TextBase>
              <TextBase color={Colors.gray2} fontWeight={600} size={convertPxToRem(24)} style={{}}>
                {locations}
              </TextBase>
            </div>
          </Col>
          <Col span={6} xs={24} sm={24} md={6}>
            <div
              style={{
                backgroundColor: '#F9EFD9',
                borderRadius: convertPxToRem(10),
                paddingTop: convertPxToRem(39),
                paddingBottom: convertPxToRem(49),
                paddingLeft: convertPxToRem(24),
                position: 'relative',
                boxShadow: '0px 1px 2px 1px #0000000D'
              }}>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0
                }}>
                <div style={{ position: 'relative' }}>
                  <SVGImageComponentReact.BgItemCardAnalytic
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0
                    }}
                  />
                </div>
              </div>
              <TextBase color={Colors.gray2} size={convertPxToRem(14)} style={{}}>
                numberOfOrdersReceived
              </TextBase>
              <TextBase color={Colors.gray2} fontWeight={600} size={convertPxToRem(24)} style={{}}>
                {ordersSent}
              </TextBase>
            </div>
          </Col>
          <Col span={6} xs={24} sm={24} md={6}>
            <div
              style={{
                backgroundColor: Colors.white,
                borderRadius: convertPxToRem(10),
                paddingTop: convertPxToRem(39),
                paddingBottom: convertPxToRem(49),
                paddingLeft: convertPxToRem(24),
                position: 'relative',
                boxShadow: '0px 1px 2px 1px #0000000D'
              }}>
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <div style={{ position: 'relative' }}>
                  <SVGImageComponentReact.BgItemCardAnalytic
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0
                    }}
                  />
                </div>
              </div>
              <TextBase color={Colors.gray2} size={convertPxToRem(14)} style={{}}>
                numberOfOrdersAccepted
              </TextBase>
              <TextBase color={Colors.gray2} fontWeight={600} size={convertPxToRem(24)} style={{}}>
                {ordersAccepted}
              </TextBase>
            </div>
          </Col>
          <Col span={6} xs={24} sm={24} md={6}>
            <div
              style={{
                backgroundColor: '#FAF0C6',
                borderRadius: convertPxToRem(10),
                paddingTop: convertPxToRem(39),
                paddingBottom: convertPxToRem(49),
                paddingLeft: convertPxToRem(24),
                position: 'relative',
                boxShadow: '0px 1px 2px 1px #0000000D'

              }}>
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <div style={{ position: 'relative' }}>
                  <SVGImageComponentReact.BgItemCardAnalytic
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0
                    }}
                  />
                </div>
              </div>
              <TextBase color={Colors.gray2} size={convertPxToRem(14)} style={{}}>
                numberOfQuotesSent
              </TextBase>
              <TextBase color={Colors.gray2} fontWeight={600} size={convertPxToRem(24)} style={{}}>
                {quotesSent}
              </TextBase>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            backgroundColor: Colors.white,
            borderRadius: convertPxToRem(10)
          }}>
          <div className="width-100" style={{ padding: convertPxToRem(34) }}>
            <div style={{ marginBottom: convertPxToRem(41), display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div className='flex-row-center-between' >
                <div style={{ marginRight: 20 }}>
                  <TextBase color={Colors.black} fontWeight={500} size={convertPxToRem(20)}>
                    Orders and Quotes
                  </TextBase>
                </div>
                <div>
                  <Dropdown overlay={menu} className="custom-menu">
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                      {this.filterNames()} <SVGImageComponentReact.IconArrowDown style={{ marginLeft: "10px" }} />
                    </a>
                  </Dropdown>
                </div>
              </div>

              <div className='flex-row-center-between'>
                <div style={{ marginRight: 20, display: "flex", alignItems: "center", justifyContent: "space-between", width: 230 }}>
                  {radios.map((item, index) => {
                    return (
                      <div style={{ display: "flex", width: 100, alignItems: "center", justifyContent: "flex-start" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: 20, height: 20, border: "2px solid orange", borderRadius: "50%", cursor: "pointer" }} onClick={() => this.radioChange(index, item.name)}>
                          {item.selected && <div style={{ width: 10, height: 10, backgroundColor: "orange", borderRadius: "50%", marginLeft: -0.7 }}></div>}
                        </div>
                        <div style={{ marginLeft: 10 }}>{item.name}</div>
                      </div>
                    )
                  })}
                </div>
                <div>
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <div
                        className="bg-white"
                        style={{
                          borderRadius: convertPxToRem(5),
                          paddingInline: convertPxToRem(10)
                        }}>
                        <TextBase
                          style={{
                            paddingTop: convertPxToRem(15),
                            paddingBottom: convertPxToRem(8)
                          }}
                          className="cursor-pointer"
                          size={14}
                          color={Colors.black}
                          onClick={() => {
                            const chartConfig = this.state.configChartArea;
                            chartConfig.xField = 'month';
                            this.setState(
                              {
                                showFilterOrder: false,
                                filterOrdersCreated: 'monthly',
                                configChartArea: chartConfig
                              },
                            );
                            this.handleMonthYearChangeLineGraph('monthly')
                          }}>
                          monthly
                        </TextBase>
                        <TextBase
                          style={{
                            paddingTop: convertPxToRem(8),
                            paddingBottom: convertPxToRem(15)
                          }}
                          className="cursor-pointer"
                          size={14}
                          color={Colors.black}
                          onClick={() => {
                            const chartConfig = this.state.configChartArea;
                            chartConfig.xField = 'year';
                            this.setState(
                              {
                                showFilterOrder: false,
                                filterOrdersCreated: 'yearly',
                                configChartArea: chartConfig
                              },
                            );
                            this.handleMonthYearChangeLineGraph('yearly')
                          }}>
                          yearly
                        </TextBase>
                      </div>
                    }
                    visible={showFilterOrder}>
                    <div
                      className="flex-row-center-between filter-box cursor-pointer"
                      onClick={() =>
                        this.setState({
                          showFilterOrder: !showFilterOrder
                        })
                      }>
                      <TextBase
                        style={{
                          marginRight: convertPxToRem(13),
                          padding: 14
                        }}
                        size={convertPxToRem(14)}
                        color={Colors.grey}
                        fontWeight={400}>
                        {filterOrdersCreated === 'yearly' ? 'yearly' : 'monthly'}
                      </TextBase>
                      <div style={{ padding: 12 }}>
                        <SVGImageComponentReact.IconArrowDown />
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
            {dataChartArea ? (
              <Line
                style={{ width: '100%' }}
                {...this.state.configChartArea}
                data={dataChartArea}
              />
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin />
              </div>
            )}
          </div>
        </Row>
        <Row style={{ marginTop: convertPxToRem(48) }} gutter={[14, 14]}>
          <Col span={24} xs={24} sm={24} md={24}>
            <div
              className="width-100"
              style={{
                border: `solid 1px ${Colors.borderBoxChart}`,
                borderRadius: convertPxToRem(8),
                backgroundColor: Colors.white,
                paddingInline: convertPxToRem(25),
                paddingBlock: convertPxToRem(18)
              }}>
              <div className="flex-row-center-between width-100" style={{ marginBottom: convertPxToRem(27) }}>
                <TextBase color={Colors.black} fontWeight={500} size={convertPxToRem(20)}>
                  quoteSentvsAcceptedQuote
                </TextBase>
                <div className="flex-row-center-between">
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <div
                        className="bg-white"
                        style={{
                          borderRadius: convertPxToRem(5),
                          paddingInline: convertPxToRem(10)
                        }}>
                        <TextBase
                          style={{
                            paddingTop: convertPxToRem(15),
                            paddingBottom: convertPxToRem(8)
                          }}
                          className="cursor-pointer"
                          size={14}
                          color={Colors.black}
                          onClick={() => {
                            const chartConfig = this.state.configChartColumn;
                            chartConfig.xField = 'month';
                            this.setState(
                              {
                                showFilterOrder: false,
                                filterOrdersCreatedBar: 'month',
                                configChartColumn: chartConfig
                              },
                            );
                            this.fetchAcceptedOrders('month')
                          }}>
                          monthly
                        </TextBase>
                        <TextBase
                          style={{
                            paddingTop: convertPxToRem(8),
                            paddingBottom: convertPxToRem(15)
                          }}
                          className="cursor-pointer"
                          size={14}
                          color={Colors.black}
                          onClick={() => {
                            const chartConfig = this.state.configChartColumn;
                            chartConfig.xField = 'year';
                            this.setState(
                              {
                                showFilterOrder: false,
                                filterOrdersCreatedBar: 'yearly',
                                configChartColumn: chartConfig
                              },
                            );
                            this.fetchAcceptedOrders('yearly')
                          }}>
                          yearly
                        </TextBase>
                      </div>
                    }
                    visible={showFilterOrder}>
                    <div
                      className="flex-row-center-between filter-box cursor-pointer"
                      onClick={() =>
                        this.setState({
                          showFilterOrder: !showFilterOrder
                        })
                      }>
                      <TextBase
                        style={{
                          marginRight: convertPxToRem(13),
                          padding: 14
                        }}
                        size={convertPxToRem(14)}
                        color={Colors.grey}
                        fontWeight={400}>
                        {filterOrdersCreatedBar === 'yearly' ? 'yearly' : 'monthly'}
                      </TextBase>
                      <div style={{ padding: 12 }}>
                        <SVGImageComponentReact.IconArrowDown />
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
              <Column legend={{ position: 'bottom-right' }} {...this.state.configChartColumn} />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: convertPxToRem(48) }} gutter={[14, 14]}>
          <Col span={24} xs={24} sm={24} md={24}>
            <div
              className="width-100"
              style={{
                border: `solid 1px ${Colors.borderBoxChart}`,
                borderRadius: convertPxToRem(8),
                backgroundColor: Colors.white
              }}>
              <div
                className="flex-row-center-between width-100"
                style={{
                  paddingInline: convertPxToRem(25),
                  paddingBlock: convertPxToRem(18)
                }}>
                <div>
                  <TextBase color={Colors.black} fontWeight={500} size={convertPxToRem(20)}>
                  allorders
                  </TextBase>
                </div>
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <div
                      className="bg-white"
                      style={{
                        borderRadius: convertPxToRem(5),
                        paddingInline: convertPxToRem(10)
                      }}>
                      <TextBase
                        style={{
                          paddingTop: convertPxToRem(15),
                          paddingBottom: convertPxToRem(8)
                        }}
                        className="cursor-pointer"
                        size={14}
                        color={Colors.black}
                        onClick={() => {
                          this.setState(
                            {
                              showFilterOrder: false,
                              filterOrders: 'monthly',
                            },
                          );
                          this.fetchOrders('monthly')
                        }}>
                        monthly
                      </TextBase>
                      <TextBase
                        style={{
                          paddingTop: convertPxToRem(8),
                          paddingBottom: convertPxToRem(15)
                        }}
                        className="cursor-pointer"
                        size={14}
                        color={Colors.black}
                        onClick={() => {
                          this.setState(
                            {
                              showFilterOrder: false,
                              filterOrders: 'yearly'
                            },
                          );
                          this.fetchOrders('yearly')
                        }}>
                        yearly
                      </TextBase>
                    </div>
                  }
                  visible={showFilterOrder}
                >
                  <div
                    className="flex-row-center-between filter-box cursor-pointer"
                    onClick={() =>
                      this.setState({
                        showFilterOrder: !showFilterOrder
                      })
                    }
                  >
                    <TextBase
                      style={{
                        marginRight: convertPxToRem(13),
                        padding: 14
                      }}
                      size={convertPxToRem(14)}
                      color={Colors.grey}
                      fontWeight={400}>
                      {filterOrders === 'yearly' ? 'yearly' : 'monthly'}
                    </TextBase>
                    <div style={{ padding: 12 }}>
                      <SVGImageComponentReact.IconArrowDown />
                    </div>
                  </div>
                </Dropdown>
              </div>
              <Table
                style={{ width: '100%' }}
                columns={this.columns}
                dataSource={this.state.orders}
                loading={this.state.loading}
                pagination={{
                  current: this.state.page,
                  pageSize: this.state.pageSize,
                  total: this.state.totalOrders,
                }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      this.props?.navigate(`${ROUTER_NAME.orderDetail}/${record.id}`)
                    },
                  };
                }}
                onChange={this.handleTableChange}

              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetail: getValueFromObjectByKeys(state, ['User', 'userDetail']),
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
    listNotification: getValueFromObjectByKeys(state, ['User', 'notification']),
    vendor: getValueFromObjectByKeys(state, ['User', 'vendor']),
    whiteLabel: getValueFromObjectByKeys(state, ['User', 'branding']),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardTotal: (query) => {
      return dispatch(UserAction.getDashboardTotal(query));
    },
    getAcceptedOrders: (query) => {
      return dispatch(UserAction.getAcceptedOrders(query));
    },
    getNumberOfQuotes: (query) => {
      return dispatch(UserAction.getNumberOfQuotes(query));
    },
    getSearchAnalytics: (param) => {
      return dispatch(UserAction.getSearchAnalytics(param));
    },
    getAnalyticsCards: (param1, param2, param3) => {
      return dispatch(UserAction.getAnalyticsCards(param1, param2, param3));
    },
    getAnalyticsCardsWithoutEndDate: (param1, param2) => {
      return dispatch(UserAction.getAnalyticsCardsWithoutEndDate(param1, param2));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Analytics));
