import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useSelector } from 'react-redux'
import { Dropdown, Pagination, Table } from 'antd'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import ReconnectingWebSocket from 'reconnecting-websocket'

import { convertPxToRem, filterQuoteStatus, filterQuoteStatusColor, getValueFromObjectByKeys, handleStatus, translate } from '../../helper'
import TextBase from '../../common/text'
import { Colors } from '../../themes/Colors'
import { heightOfHeader, sizeOfBaseButton } from '../../utils/defined'
import { SVGImageComponentReact } from '../../themes/Images'
import APIManager from '../../connection/ApiManager'
import LoginInput from '../../common/login-input'
import Link from '../../common/link'
import { ROUTER_NAME } from '../../router/routers'
import { useNavigate } from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Utils from '../../utils'

const heightOfBottomBlackAlpha = convertPxToRem(284)

const styles = StyleSheet.create({
  container: {
    textAlign: 'left',
    whiteSpace: 'pre-line',
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: `calc(100vh - ${heightOfHeader})`,
    paddingTop: convertPxToRem(30),
    paddingLeft: convertPxToRem(32),
    paddingRight: convertPxToRem(32),
    paddingBottom: convertPxToRem(10)
  },
  dashboardTitle: {
    marginBottom: convertPxToRem(52),
    marginTop: convertPxToRem(50)
  },
  bottomBlackAlpha: {
    width: "100%",
    minWidth: "100vw",
    height: heightOfBottomBlackAlpha,
    backgroundColor: Colors.black80,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 1,
    position: "absolute"
  },
  bgOverlay: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background:
      "linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.56) 100%)",
    zIndex: 1,
    overflow: "hidden"
  },
  wrapText: {
    position: "relative",
    zIndex: 4,
    maxWidth: "50%",
    height: "100%",
    minHeight: convertPxToRem(400),
    paddingLeft: convertPxToRem(100)
  },
  buttonAction: {
    width: sizeOfBaseButton.width,
    height: sizeOfBaseButton.height,
    marginBottom: convertPxToRem(33)
  }
})

function CustomTabPanel (props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}
                     style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '0px' }}>
                    <Typography style={{
                        width: '100%',
                        padding: '0px',
                        backgroundColor: 'transparent',
                        borderRadius: '10px',
                        boxShadow: 'none'
                    }}>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps (index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const quoteStatusName = (status) => {
    switch (status) {
        case 'INPROGRESS':
            return 'In Progress'
        case 'SENT':
            return 'Quote sent'
        case 'ACCEPTED':
            return 'Paid'
        case 'NS':
            return 'Open order'
        default:
            return 'Open order'
    }
}

const quoteStatusColor = (status) => {
    switch (status) {
        case 'SENT':
            return Colors.primary
        case 'ACCEPTED':
            return Colors.open_order
        case 'NS':
            return Colors.red
        case 'INPROGRESS':
            return Colors.mainColor
        default:
            return Colors.red
    }
}
  
const orderStatus = (status) => {
  switch (status) {
    case 'NS':
      return 'No Status';
    case 'INPROGRESS':
      return 'In Progress';
    default:
      return 'No Status';
  }
};


let socket = null

function Dashboard () {
    const navigate = useNavigate()
    const whiteLabel = useSelector((state) => state.User?.branding)
    const { token, user, profile } = useSelector((state) => state.User?.userInfo)

    const isUserServiceWriter = user?.role === 'SW'
    const isUserTeamMember = profile?.team_member

    const [value, setValue] = useState(0)
    const [childAccounts, setChildAccounts] = useState([])
    const [childAccountPage, setChileAccountPage] = useState(1)
    const [childAccountPageSize, setChileAccountPageSize] = useState(15)
    const [sortBy, setSortBy] = useState('-created_at')
    const [textSearch, setTextSearch] = useState('')
    const [totalChildAccounts, setTotalChildAccounts] = useState(0)
    const [loading, setLoading] = useState(true)

    const [orders, setOrders] = useState([])
    const [orderPage, setOrdertPage] = useState(1)
    const [orderPageSize, setOrderPageSize] = useState(15)
    const [orderSortBy, setOrderSortBy] = useState('-created_at')
    const [orderSearch, setOrderSearch] = useState('')
    const [totalParentOrders, setTotalParentOrders] = useState(0)

    const [visibleDropdownSort, setVisibleDropdownSort] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const handleSortChange = (sortOption) => {
        setSortBy(sortOption) // Update sortBy state with the selected sorting option
    }

    const dropdownMenu = (
        <div className="bg-white" style={{ borderRadius: convertPxToRem(5), paddingInline: convertPxToRem(10) }}>
            <TextBase
                style={{ paddingTop: convertPxToRem(15), paddingBottom: convertPxToRem(8), textTransform: 'uppercase' }}
                className="cursor-pointer"
                size={14}
                color={(sortBy === '-created_at') ? Colors.mainColor : Colors.black}
                onClick={() => {
                    handleSortChange('-created_at')
                    setVisibleDropdownSort(!visibleDropdownSort)
                }}
            >
                newestToOldest
            </TextBase>
            <TextBase
                style={{ paddingTop: convertPxToRem(8), paddingBottom: convertPxToRem(15), textTransform: 'uppercase' }}
                className="cursor-pointer"
                size={14}
                color={(sortBy === 'created_at') ? Colors.mainColor : Colors.black}
                onClick={() => {
                    handleSortChange('created_at')
                    setVisibleDropdownSort(!visibleDropdownSort)
                }}
            >
                oldestToNewest
            </TextBase>
        </div>
    )

    const childAccountColumns = [
        {
            title: translate('location'),
            dataIndex: 'location',
            align: 'left',
            key: 'location',
            render: (order, row) => {
                return row?.vendors?.[0]?.business_name || ''
            }
        },
        {
            title: translate('dateCreated'),
            dataIndex: 'created_at',
            align: 'left',
            key: 'created_at',
            render: (value) => {
                return value ? moment(value, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY hh:mm a') : ''
            }
        },
        {
            title: translate('customerPo'),
            dataIndex: 'id',
            align: 'left',
            key: 'id',
            render: (text, row) => {
                return (
                    <Typography
                        color={'rgb(81, 125, 152)'}
                        fontSize={'14px'}
                        sx={{
                            cursor: 'pointer',
                            textDecorationLine: 'underline'
                        }}
                        onClick={() => {
                            navigate(`${ROUTER_NAME.orderDetail}/${row.id}`)
                        }}
                    >
                        {row.id}
                    </Typography>
                )
            }
        },
        {
            title: translate('orderName'),
            dataIndex: 'name',
            align: 'left',
            key: 'name',
            render: (order, row) => {
                return order || ''
            }
        },
        {
            title: translate('status'),
            dataIndex: 'id',
            align: 'left',
            key: 'status',
            render: (value, record) => {
                const { quotes, status } = record
                const singleQuote = quotes && quotes[0]
                const quoteStatus = singleQuote?.status

                const deliverLabelMap = {
                    'instore': translate('acceptedInStorePickup'),
                    'delivery': translate('acceptedDelivery')
                }

                const statusLabel = handleStatus(record, isUserServiceWriter, isUserTeamMember)
                const statusColor = filterQuoteStatusColor(statusLabel, isUserServiceWriter, isUserTeamMember)
                const statusName = filterQuoteStatus(statusLabel, isUserServiceWriter, isUserTeamMember)
                return (
                    <>
                        <Typography fontSize={'14px'} color={quoteStatusColor(quoteStatus || record?.status) || '#000'}>
                            {statusName}
                        </Typography>
                        {
                            record?.status === 'INPROGRESS' && !quoteStatus && <Typography
                                color={'#000'}>{` - ${record?.order_vendor_note_user_name?.trim()}`}</Typography>
                        }
                        {
                            statusName === 'Paid' &&
                            <Typography fontSize={'14px'} variant="p" fontWeight={'400'} color={'#000'}>
                                {` - ${deliverLabelMap[record?.delivery_method]}`}
                            </Typography>
                        }
                        {
                            statusName === 'SERVICE ACCEPTED' &&
                            <Typography fontSize={'14px'} display={'block'} variant="p" fontWeight={'400'} color={'#000'}>
                                {deliverLabelMap[record?.delivery_method]}
                            </Typography>
                        }
                    </>
                )
            }
        },
        {
            title: translate('warrantyStatus'),
            key: 'tags',
            dataIndex: 'tags',
            align: 'left',
            render: (value, item) => {
                return (
                    <>
                        {
                            item.warranty ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <CheckIcon sx={{
                                        background: 'white',
                                        borderRadius: '50px',
                                        padding: '5px',
                                        fontSize: '30px',
                                        color: 'green'
                                    }}/> {item.warranty_user_name}
                                </div>
                            ) : (
                                <CheckBoxOutlineBlankIcon sx={{
                                    background: 'white',
                                    borderRadius: '50px',
                                    padding: '5px',
                                    fontSize: '30px',
                                }}/>
                            )
                        }
                    </>
                )
            }
        },
        {
            title: translate('fulfilledBy'),
            dataIndex: 'email',
            align: 'left',
            key: 'email',
            render: (text, record) => {
                const quote = record.quotes?.filter((q) => q.status === 'ACCEPTED')
                const pending = record.quotes?.find((q) => q.status === 'SENT')
                return quote.length > 0
                    ? quote?.[0]?.user?.name
                    : pending?.id
                        ? pending?.user?.name
                        : 'N/A'
            }
        },
        {
            title: translate('paymentInfo'),
            key: 'tags',
            dataIndex: 'tags',
            align: 'left',
            render: (value, item) => {
                return (
                    <>
                        <Typography variant="span" sx={{ textTransform: 'capitalize' }}>
                            {item?.quotes?.[0] ? item?.quotes?.[0]?.stripe_charge_id !== 'NS' ? translate('stripe') : translate('accountBilling') : ''}
                        </Typography>
                    </>
                )
            }
        },
        {
            title: translate('orderInfo'),
            dataIndex: '',
            align: 'left',
            key: 'id',
            render: (text, item) => {
                return (
                    <div className="flex-centser-all">
                        <div
                            style={{
                                backgroundColor: Colors.greyBg,
                                paddingInline: convertPxToRem(8),
                                paddingRight: convertPxToRem(14),
                                paddingBlock: convertPxToRem(8),
                                borderRadius: convertPxToRem(5)
                            }}
                            className="flex-row-center width-fit-content cursor-pointer"
                            onClick={() => navigate(`${ROUTER_NAME.orderDetail}/${item?.id}`)}>
                            <TextBase size={convertPxToRem(14)} color={Colors.grey}>
                                details
                            </TextBase>
                            <SVGImageComponentReact.IconChevronRight className="chevron-right"/>
                        </div>
                    </div>
                )
            }
        },
    ]

    const parentAccountColumns = [
        {
            title: translate('dateCreated'),
            dataIndex: 'created_at',
            align: 'left',
            key: 'created_at',
            render: (value) => {
                return value ? moment(value, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY hh:mm a') : ''
            }
        },
        {
            title: translate('customerPo'),
            dataIndex: 'id',
            align: 'left',
            key: 'id',
            render: (text, row) => {
                return (
                    <Typography
                        color={'rgb(81, 125, 152)'}
                        fontSize={'14px'}
                        sx={{
                            cursor: 'pointer',
                            textDecorationLine: 'underline'
                        }}
                        onClick={() => {
                            navigate(`${ROUTER_NAME.orderDetail}/${row.id}`)
                        }}
                    >
                        {row.id}
                    </Typography>
                )
            }
        },
        {
            title: translate('orderName'),
            dataIndex: 'name',
            align: 'left',
            key: 'name',
            render: (order, row) => {
                return order || ''
            }
        },
        {
            title: translate('status'),
            dataIndex: 'id',
            align: 'left',
            key: 'status',
            render: (value, record) => {
                const { quotes, status } = record
                const singleQuote = quotes && quotes[0]
                const quoteStatus = singleQuote?.status

                const deliverLabelMap = {
                    'instore': translate('acceptedInStorePickup'),
                    'delivery': translate('acceptedDelivery')
                }

                const statusLabel = handleStatus(record, isUserServiceWriter, isUserTeamMember)
                const statusColor = filterQuoteStatusColor(statusLabel, isUserServiceWriter, isUserTeamMember)
                const statusName = filterQuoteStatus(statusLabel, isUserServiceWriter, isUserTeamMember)
                return (
                    <>
                        <Typography fontSize={'14px'} color={quoteStatusColor(quoteStatus || record?.status) || '#000'}>
                            {statusName}
                        </Typography>
                        {
                            record?.status === 'INPROGRESS' && !quoteStatus && <Typography
                                color={'#000'}>{` - ${record?.order_vendor_note_user_name?.trim()}`}</Typography>
                        }
                        {
                            statusName === 'Paid' &&
                            <Typography fontSize={'14px'} variant="p" fontWeight={'400'} color={'#000'}>
                                {` - ${deliverLabelMap[record?.delivery_method]}`}
                            </Typography>
                        }
                        {
                            statusName === 'SERVICE ACCEPTED' &&
                            <Typography fontSize={'14px'} display={'block'} variant="p" fontWeight={'400'} color={'#000'}>
                                {deliverLabelMap[record?.delivery_method]}
                            </Typography>
                        }
                    </>
                )
            }
        },
        {
            title: translate('warrantyStatus'),
            key: 'tags',
            dataIndex: 'tags',
            align: 'left',
            render: (value, item) => {
                return (
                    <div className="flex-centser-all">
                        <>
                            {
                                item.warranty ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <CheckIcon sx={{
                                            background: 'white',
                                            borderRadius: '50px',
                                            padding: '5px',
                                            fontSize: '30px',
                                            color: 'green'
                                        }}/> {item.warranty_user_name}
                                    </div>
                                ) : (
                                    <CheckBoxOutlineBlankIcon sx={{
                                        background: 'white',
                                        borderRadius: '50px',
                                        padding: '5px',
                                        fontSize: '30px',
                                    }}/>
                                )
                            }
                        </>
                    </div>
                )
            }
        },
        {
            title: translate('fulfilledBy'),
            dataIndex: 'email',
            align: 'left',
            key: 'email',
            render: (text, record) => {
                const quote = record.quotes?.filter((q) => q.status === 'ACCEPTED')
                const pending = record.quotes?.find((q) => q.status === 'SENT')
                return quote.length > 0
                    ? quote?.[0]?.user?.name
                    : pending?.id
                        ? pending?.user?.name
                        : 'N/A'
            }
        },
        {
            title: translate('paymentInfo'),
            key: 'tags',
            dataIndex: 'tags',
            align: 'left',
            render: (value, item) => {
                return (
                    <>
                        <Typography variant="span" sx={{ textTransform: 'capitalize' }}>
                            {item?.quotes?.[0] ? item?.quotes?.[0]?.stripe_charge_id !== 'NS' ? translate('stripe') : translate('accountBilling') : ''}
                        </Typography>
                    </>
                )
            }
        },
        {
            title: translate('orderInfo'),
            dataIndex: 'status',
            align: 'left',
            key: 'id',
            render: (text, item) => {
                return (
                    <div className="flex-centser-all">
                        <div
                            style={{
                                backgroundColor: Colors.greyBg,
                                paddingInline: convertPxToRem(8),
                                paddingRight: convertPxToRem(14),
                                paddingBlock: convertPxToRem(8),
                                borderRadius: convertPxToRem(5)
                            }}
                            className="flex-row-center width-fit-content cursor-pointer"
                            onClick={() => {
                                navigate(`${ROUTER_NAME.orderDetail}/${item?.id}`)
                            }}>
                            <TextBase size={convertPxToRem(14)} color={Colors.grey}>
                                details
                            </TextBase>
                            <SVGImageComponentReact.IconChevronRight className="chevron-right"/>
                        </div>
                    </div>
                )
            }
        },
    ]

    const getChildAcountData = async () => {

        let { data } = await APIManager.getInstance().getChildAccounts({
            page: childAccountPage,
            page_size: childAccountPageSize,
            sort_by: sortBy, ...(textSearch.length > 0 ? { search: textSearch } : {})
        })
        setChildAccounts(data?.data || [])
        if (data?.page?.total_records !== totalChildAccounts) {
            setTotalChildAccounts(data?.page?.total_records || 0)
        }
        setLoading(false)
    }

    const getParentOrderData = async () => {
        let { data } = await APIManager.getInstance().getParentOrders({
            sort_by: sortBy, ...(orderSearch.length > 0 ? { search: orderSearch } : {}),
            page: orderPage,
            page_size: orderPageSize
        })
        setOrders(data?.data || [])
        if (data?.page?.total_records !== totalParentOrders) {
            setTotalParentOrders(data?.page?.total_records || 0)
        }
        setLoading(false)
    }

    const handleSearch = (text) => {
        if (value === 0) setTextSearch(text)
        if (value === 1) setOrderSearch(text)
    }
    const fetchData = () => {
        if (value === 0) getChildAcountData()
        if (value === 1) getParentOrderData()
    }

    const getUpdates = () => {

        socket = new ReconnectingWebSocket(
            `wss://${Utils.constants.API_URL.replace('https://', '')}ws/orders/?token=${token
            }`
        )
        socket.onmessage = (event) => {
            if (window.location.pathname.includes('dashboard')) {
                fetchData()
            }
        }
    }

    const onPageChange = (pageNumber) => {
        setChileAccountPage(pageNumber ? pageNumber : pageNumber + 1)
    }

    const onOrderPageChange = (pageNumber) => {
        setOrdertPage(pageNumber)
    }

    useEffect(() => {
        let title = window.location.hostname.toUpperCase()
        if (whiteLabel?.location_name) {
            title = whiteLabel?.location_name
        }
        document.title = title
    }, [whiteLabel])

    useEffect(() => {
        return () => {
            if (socket) {
                socket.close()
            }
        }
    },[])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setLoading(true)
            if (textSearch.length > 0 || orderSearch.length > 0) {
                fetchData()
            } else {
                fetchData()
            }
        }, 800)
        getUpdates()
        return () => clearTimeout(delayDebounceFn)
    }, [textSearch, orderSearch, sortBy, orderSortBy, childAccountPage, orderPage, value, childAccountPageSize, orderPageSize])

    const placeholder = value === 0 ? 'searchChildAccount' : 'searchOrders'

    return (
        <div style={{ backgroundColor: '#fff' }} className={css(styles.container)}>
            <div className="flex-row-center">
                <TextBase
                    style={{ marginBottom: 20 }}
                    fontWeight={600}
                    size={convertPxToRem(24)}
                    color={Colors.titlePage}
                >
                    dashboard
                </TextBase>
            </div>
            <Box sx={{}}>
                <Tabs TabIndicatorProps={{ style: { background: '#F8B218', textTransform: 'capitalize' } }}
                      value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab TabIndicatorProps={{ style: { background: '#F8B218', textTransform: 'capitalize' } }} style={{
                        borderBottom: value === 0 ? '1px solid #F8B218' : '1px solid #C8C8C8',
                        color: value === 0 ? '#F8B218' : '#C8C8C8',
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: '21px',
                        textTransform: 'capitalize'
                    }} label={translate('allChildAccount')} {...a11yProps(0)} />
                    <Tab TabIndicatorProps={{ style: { background: '#F8B218', textTransform: 'capitalize' } }} style={{
                        borderBottom: value === 1 ? '1px solid #F8B218' : '1px solid #C8C8C8',
                        color: value === 1 ? '#F8B218' : '#C8C8C8',
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: '21px',
                        textTransform: 'capitalize'
                    }} label={translate('myAccount')} {...a11yProps(1)} />
                </Tabs>
                <div
                    style={{
                        padding: '20px',
                    }}
                >
                    <div className="flex-row-center-between width-100">
                        <div>
                            <LoginInput
                                value={value === 0 ? textSearch : orderSearch}
                                onChange={(text) => {
                                    handleSearch(text)
                                }}
                                style={{
                                    fontSize: convertPxToRem(16),
                                    color: Colors.grey,
                                    width: 350
                                }}
                                className="search"
                                placeholder={translate(placeholder)}
                                rightElement={<SVGImageComponentReact.IconSearch className="search-icon"/>}
                            />
                        </div>
                        <div className="flex-row-center">
                            <Dropdown
                                trigger={['click']} overlay={dropdownMenu} visible={visibleDropdownSort}
                            >
                                <div
                                    className="flex-row-center-between filter-box cursor-pointer"
                                    onClick={() =>
                                        setVisibleDropdownSort(!visibleDropdownSort)
                                    }>
                                    <TextBase
                                        style={{
                                            marginRight: convertPxToRem(13),
                                            padding: 14
                                        }}
                                        size={convertPxToRem(14)}
                                        color={Colors.grey}
                                        fontWeight={400}>
                                        sortBy{' '}
                                        <Link
                                            style={{
                                                color: 'black',
                                                fontSize: 14,
                                                fontWeight: 400,
                                                textTransform: 'uppercase',
                                            }}>
                                            {sortBy === '-created_at' ? 'newestToOldest' : 'oldestToNewest'}
                                        </Link>
                                    </TextBase>
                                    <div className="filter-divider"></div>
                                    <div style={{ padding: 12 }}>
                                        <SVGImageComponentReact.IconArrowDown/>
                                    </div>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <CustomTabPanel value={value} index={0}>
                    <Table
                        style={{ width: '100%' }}
                        columns={childAccountColumns}
                        dataSource={childAccounts}
                        className="table"
                        bordered={false}
                        loading={loading}
                        total={totalChildAccounts}
                        pagination={false}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    navigate(`${ROUTER_NAME.orderDetail}/${record.id}`)
                                },
                            }
                        }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '20px'
                        }}
                    >
                        <TextBase
                            style={{
                                color: '#000'
                            }}
                        >
                            {translate('showing')}
                            <b>{(childAccountPage - 1) * childAccountPageSize + 1}</b> {translate('to')}
                            <b>{Math.min(childAccountPage * childAccountPageSize, totalChildAccounts)}</b> {translate('of')}
                            <b>{totalChildAccounts}</b> {translate('results')}
                        </TextBase>
                        <Pagination
                            defaultCurrent={childAccountPage}
                            defaultPageSize={childAccountPageSize}
                            total={totalChildAccounts}
                            onChange={onPageChange}
                            onShowSizeChange={(cur, size) => {setChileAccountPageSize(size)}}
                        />
                    </div>
                </CustomTabPanel>
            </Box>
            <CustomTabPanel value={value} index={1}>
                <Table
                    style={{ width: '100%' }}
                    columns={parentAccountColumns}
                    dataSource={orders}
                    className="table"
                    bordered={false}
                    loading={loading}
                    total={totalParentOrders}
                    pagination={false}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                                navigate(`${ROUTER_NAME.orderDetail}/${record.id}`)
                            },
                        }
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '20px'
                    }}
                >
                    <TextBase
                        style={{
                            color: '#000'
                        }}
                    >
                        {translate('showing')} <b>{(orderPage - 1) * orderPageSize + 1}</b> {translate('to')}
                        <b>{Math.min(orderPage * orderPageSize, totalParentOrders)}</b> {translate('of')}
                        <b>{totalParentOrders}</b> {translate('results')}
                    </TextBase>
                    <Pagination
                        defaultCurrent={orderPage}
                        defaultPageSize={orderPageSize}
                        total={totalParentOrders}
                        onChange={onOrderPageChange}
                    />
                </div>
            </CustomTabPanel>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userDetail: getValueFromObjectByKeys(state, ['User', 'userDetail']),
        userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
        listNotification: getValueFromObjectByKeys(state, ['User', 'notification'])
    }
}
const mapDispatchToProps = dispatch => {
    return {}
}
export default Dashboard
