import { useMutation, useQuery } from "@tanstack/react-query"
import { dealersAPIService } from "../services/dealersAPIService"
import Utils from "../../../../src/utils"

let a = {
  results: [
    {
      id: 1,
      logo: '',
      full_name: 'Jones Junction',
      description: 'Today, Jones Junction has facilities for ten (10) dealerships: ',
      address: '1508 Belair Rd, Bel Air, MD 21014, USA - 7 miles away'
    },
    {
      id: 2,
      logo: '',
      full_name: 'Jones Junction',
      description: 'Today, Jones Junction has facilities for ten (10) dealerships: ',
      address: '1508 Belair Rd, Bel Air, MD 21014, USA - 7 miles away'
    },
    {
      id: 3,
      logo: '',
      full_name: 'Jones Junction',
      description: 'Today, Jones Junction has facilities for ten (10) dealerships: ',
      address: '1508 Belair Rd, Bel Air, MD 21014, USA - 7 miles away'
    }
  ]
}

let dealerData = {
  "id": 2,
  "created_at": "2023-08-19 05:04:42",
  "updated_at": "2023-08-19 05:04:42",
  "user": 3,
  "vendor": {
      "id": 1,
      "phone_number": {
          "country_code": "1",
          "national_number": "6462224280"
      },
      "uuid": "b8e4598d-eac7-448d-9307-30bf12a9c679",
      "created_at": "2023-06-22 20:55:28",
      "updated_at": "2023-08-14 23:28:13",
      "business_email": "borhan@crowdbotics.com",
      "business_name": "Crowdbotics.com",
      "full_name": "BORHAN UDDIN TIPU",
      "tax_id": null,
      "country": "US",
      "state": "CA",
      "city": "Berkley",
      "zip": "9001",
      "address": "Mipur 12/A, Pallabi",
      "description": "",
      "date_joined": "2023-06-22 20:54:45",
      "account_status": "A",
      "denied_reason": "",
      "logo": null,
      "notification_email": false,
      "notification_sms": false,
      "phone_verified": false,
      "dealer_code": 936965,
      "is_dealer_code_locked": true,
      "user": 1
  }
}

export const useGetDealers = (onSuccess = () => {}, onError) => {
    // 
    // let a = await dealersAPIService.getDealers(payload)
    // 
     const { mutateAsync, isLoading, isSuccess, data, isError, error, reset } =
        useMutation(payload => dealersAPIService.getDealers(payload), {
          onSuccess: data => {
            onSuccess(data)
          },
          onError: e => {
            onError(e?.response?.data)
            // console.error(e)
          }
        })
  return {
    getDealers: mutateAsync,
    dealers: data,
    isLoading,
    error,
    reset,
    isSuccess,
    isError,
  }
}

export const useAddDealer = (onSuccess = () => {}, onError) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error, reset } =
    useMutation(payload => dealersAPIService.addDealer(JSON.stringify(payload)), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        onError(e?.response?.data)
        // console.error(e)
      }
    })
  // Utils.api.postApiCall(
  //   'vendors/customer-vendors/',
  //   payload,

  // )

  return {
    addDealer: mutateAsync,
    reset,
    isAddingDealer: isLoading,
    isSuccess,
    data,
    isError,
    addDealerError: error
  }
}

export const useDeleteDealer = (onSuccess = () => {}, onError) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(payload => dealersAPIService.deleteDealer(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        onError(e?.response?.data)
        console.error(e)
      }
    })

  return {
    deleteDealer: mutateAsync,
    isDeletingDealer: isLoading,
    isSuccess,
    data,
    isError,
    deleteDealerError: error
  }
}

