import './notifications.style.css'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserAction as ApiCall } from '../../redux/action'
import { SVGImageComponentReact } from '../../themes/Images'
import TextBase from '../../common/text'
import { convertPxToRem, translate } from '../../helper'
import { Colors } from '../../themes/Colors'
import Loader from '../../common/Loader'
import NotificationCard from './NotificationCard'

const NotificationsScreen = (props) => {
    const dispatch = useDispatch()
    const { notifications } = useSelector((state) => state.User)
    const [lastElement, setLastElement] = useState(null)
    const [isFetching, setIsFetching] = useState(true)
    const label = translate(props.isVendorNotes ? 'Vendor Notes Notifications' : 'Notifications')
    const [page, setPage] = useState(1)
    const query = {
        vendor_notes: props.isVendorNotes,
        page,
        page_size: 20
    }

    const callbackFn = (entries) => {
        if (entries[0].isIntersecting && notifications.page?.has_next) {
            setPage((currentPage) => {
                getData({ ...query, page: currentPage + 1, page_size: 20 }, true)
                return currentPage + 1
            })
        }
    }

    const getData = (queryParams, append = false, onThen) => {
        dispatch(ApiCall.getNotifications(queryParams, append))
            .then(onThen)
            .finally(() => {
                setIsFetching(false)
            })
    }

    const observer = useRef(new IntersectionObserver(callbackFn))

    useEffect(() => {
        getData(query)
    }, [])

    useEffect(() => {
        const currentElement = lastElement
        const currentObserver = observer.current

        if (currentElement) {
            currentObserver.observe(currentElement)
        }

        if (notifications?.page?.current !== page) {
            setPage(notifications?.page?.current||page)
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement)
            }
        }
    }, [lastElement, notifications])

    const markAsReadAll = () => {
        dispatch(
            ApiCall.markAsReadAll({
                vendor_notes: props.isVendorNotes
            })
        )
    }

    return (
        <div className="notifications-wrapper">
            {!isFetching ? (
                notifications?.data?.length > 0 ? (
                    <>
                        <div className="mark-all-wrapper">
                            <h3 className="main-title">{label}</h3>
                            <div className="mark-all" onClick={markAsReadAll}>
                                <h3>{translate('markAllAsRead')}</h3>
                                <div className="mark-all-svg">
                                    <SVGImageComponentReact.IconChecked/>
                                </div>
                            </div>
                        </div>

                        <div className="scrollable-div">
                            {notifications?.data?.map((notification, index) => (
                                <NotificationCard
                                    index={index}
                                    key={notification.id}
                                    notification={notification}
                                    setLastElement={
                                        index === notifications.data.length - 1 ? setLastElement : () => null
                                    }
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            position: 'absolute',
                            top: '55%',
                            left: '45%',
                            transform: translate('-50%', '-50%')
                        }}>
                        <TextBase
                            size={convertPxToRem(16)}
                            color={Colors.grey2}
                            fontWeight={500}
                            style={{ marginBottom: 20 }}>
                            noNotifications
                        </TextBase>
                    </div>
                )
            ) : (
                <Loader/>
            )}
        </div>
    )
}

const Notifications = (props) => {
    return <NotificationsScreen {...props} isVendorNotes={false}/>
}

export const VendorNotesNotifications = (props) => {
    return <NotificationsScreen {...props} isVendorNotes={true}/>
}

export default Notifications

NotificationsScreen.defaultProps = {
    isVendorNotes: false
}
