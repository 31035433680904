import React from "react"
import BasePage from "../base"
import { css, StyleSheet } from "aphrodite"
import {
  convertPxToRem,
  getValueFromObjectByKeys
} from "../../helper"
import TextBase from "../../common/text"
import { Colors } from "../../themes/Colors"
import {animate, heightOfHeader, sizeOfBaseButton} from "../../utils/defined"

import { connect } from "react-redux"
import withNavigate from "../../component/WithNavigate"
import { Col, Row } from "antd"
import { SVGImageComponentReact } from "../../themes/Images"
import {motion} from "framer-motion";
import APIManager from "../../connection/ApiManager";

const heightOfBottomBlackAlpha = convertPxToRem(284)

const styles = StyleSheet.create({
  container: {
    textAlign: "left",
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
    minHeight: `calc(100vh - ${heightOfHeader})`,
    paddingTop: convertPxToRem(40),
    paddingLeft: convertPxToRem(32),
    paddingRight: convertPxToRem(32),
    backgroundColor: Colors.content_page,
    paddingBottom: convertPxToRem(82),
    overflow: 'none'
  },
  dashboardTitle: {
    marginBottom: convertPxToRem(52),
    marginTop: convertPxToRem(50)
  },
  bottomBlackAlpha: {
    width: "100%",
    minWidth: "100vw",
    height: heightOfBottomBlackAlpha,
    backgroundColor: Colors.black80,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 1,
    position: "absolute"
  },
  bgOverlay: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // height: `calc(100vh - ${heightOfHeader} - ${heightOfBottomBlackAlpha})`,
    background:
        "linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.56) 100%)",
    zIndex: 1,
    overflow: "hidden"
  },
  wrapText: {
    position: "relative",
    zIndex: 4,
    maxWidth: "50%",
    height: "100%",
    minHeight: convertPxToRem(400),
    // paddingBottom: convertPxToRem(99),
    paddingLeft: convertPxToRem(100)
  },
  buttonAction: {
    width: sizeOfBaseButton.width,
    height: sizeOfBaseButton.height,
    marginBottom: convertPxToRem(33)
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
})

class Privacy extends BasePage {
  constructor(props) {
    super(props)
    this.title = "Settings"
    this.state = {
      terms: ''
    }
  }

  componentDidMount() {
    this.fetchTerms()
  }

  async fetchTerms () {
    const { data } = await APIManager.getInstance().getPrivacy()
    const terms = data[0].body
    this.setState({
      privacy: terms
    })
  }

  createMarkup() {
    return { __html: this.state.privacy };
  }

  renderContent() {
    const { userInfo, navigate } = this.props
    return (
        <motion.div
            initial={animate.initial}
            animate={animate.animate}
            transition={animate.transition}
        >
          <div className={css(styles.container)}>
            <div className={css(styles.flex)}>
              <div className="flex-row-center-center cursor-pointer" onClick={() => navigate(-1)}>
                <SVGImageComponentReact.IconArrowBack />
              </div>

              <TextBase size={convertPxToRem(25)} color={Colors.black} fontWeight={600}>
                Privacy Policy
              </TextBase>
              <div></div>
            </div>
            <Row style={{ marginTop: convertPxToRem(10) }}>
              <div style={{ padding: 30, height: '80vh', overflow: 'auto' }}>
                <div dangerouslySetInnerHTML={this.createMarkup()}>

                </div>
                {/*<TextBase size={convertPxToRem(14)} color={Colors.gray3}>*/}
                {/*  */}
                {/*</TextBase>*/}
              </div>
            </Row>
          </div>

        </motion.div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userDetail: getValueFromObjectByKeys(state, ["User", "userDetail"]),
    userInfo: getValueFromObjectByKeys(state, ["User", "userInfo"]),
    listNotification: getValueFromObjectByKeys(state, ["User", "notification"])
  }
}
const mapDispatchToProps = dispatch => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Privacy))
