import React from 'react'
import { Box, Typography } from '@mui/material'
import { getValueFromObjectByKeys, translate } from '../../helper'
import { ReplyButton } from './components'
import { connect } from 'react-redux'
import { setOrderDetailsSliceData } from '../../mobile/modules/Orders/Slice/ordersDetailSlice'
import APIManager from '../../connection/ApiManager'
import { signInWithCustomToken } from 'firebase/auth'
import { auth } from '../../firebase'
import firebase from 'firebase/compat/app'

const MessagesList = (props) => {
    const {
        orderDetail,
        vendorId,
        messages,
        setMessages,
        messagesLoading,
        setMessagesLoading,
    } = props

    const { userInfo: { profile: { user, vendor } = {} } = {} } = props
    const isWhiteLabelPartner = user?.role === "White Label Partner Admin";

    React.useEffect(() => {
        getChats()

        return () => {
            props.clearOrderInViewDetails()
        }
    }, [])

    const getChats = async () => {
        try {
            const res1 = await APIManager.getInstance().getFirebaseAuthToken()
            const resAuth = await signInWithCustomToken(auth, res1?.data?.token)
            let docID = `order_${orderDetail.id}_vendor_${vendorId}`

            firebase
                .firestore()
                .collection(process.env.REACT_APP_CHATS || 'order_vendor_chats')
                .doc(docID)
                .collection('messages')
                .onSnapshot((documentSnapshot) => {
                    let mess = []
                    documentSnapshot.forEach((doc) => mess.push(doc.data()))
                    setMessages(
                        mess
                            ?.sort(function (a, b) {
                                return new Date(b.created_at) - new Date(a.created_at)
                            })
                            ?.reverse()
                    )
                    setMessagesLoading(false)
                })
        } catch (error) {
            setMessagesLoading(false)
        }
    }
    const showTextButtons = orderDetail?.vendors?.includes(vendor?.id)
    return (
        <Box className="messages_box">
            <Box display={'flex'} alignItems={'center'} marginTop={'10px'}>
                <Typography
                    variant="body1"
                    fontWeight={'600'}>
                    {translate('messages')}
                </Typography>
            </Box>
            {
                <>
                    {
                        messagesLoading ? (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '50px',
                                    margin: 0
                                }}
                            >
                                <div className="msg-loader-container">
                                    <div className="msg-loader bouncing-dots"></div>
                                </div>
                            </div>
                        ) : messages?.length > 0 ? (
                            <>
                                <Box className="chat_box">
                                    {
                                        messages.map((message, i) => (
                                            <Box key={i} sx={{ display: 'flex' }}>
                                                <Typography variant="body1"
                                                            fontSize={'16px'}
                                                            fontWeight="600">
                                                    {
                                                        user?.id === message?.message_sent_by_user_id
                                                            ? 'Me'
                                                            : message?.message_sent_by_user_first_name || 'Dealer'
                                                    }
                                                </Typography>
                                                <Typography variant="body1"
                                                            fontSize={'16px'}>
                                                    : {message.body}
                                                </Typography>
                                            </Box>
                                        ))
                                    }
                                </Box>
                                <Box textAlign={'end'}>
                                    {showTextButtons && <ReplyButton
                                        onClick={() => props.setOpenReply(true)}>{translate('reply')}</ReplyButton>}
                                </Box>
                            </>
                        ) : (
                            <Typography
                                variant="body2"
                                mt={2}>
                                {translate('noMessage')}
                            </Typography>
                        )
                    }
                </>
            }
        </Box>
    )
}
const mapStateToProps = (state) => {
    return {
        userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
        vendor: getValueFromObjectByKeys(state, ['User', 'vendor']),
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        clearOrderInViewDetails: () => dispatch(setOrderDetailsSliceData(null))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesList)
