import { useMutation, useQuery } from "@tanstack/react-query";
import {ordersAPIService} from "../services/orderAPIService";

export const useCreateOrder = (onSuccess = () => {}, onError) => {
    const { mutateAsync, isLoading, isSuccess, data, isError, error, reset } =
      useMutation(payload => ordersAPIService.createOrder(payload), {
        onSuccess: data => {
          onSuccess(data)
        },
        onError: e => {
          onError(e?.response?.data)
          // 
          console.error(e)
        }
      })
  
    return {
      createOrder: mutateAsync,
      isCreatingOrder: isLoading,
      isSuccess,
      data,
      isOrderError: isError,
      orderError: error,
      resetOrder: reset
    }
}

export const useGetOrders = (onSuccess, onError) => {
    const { mutateAsync, data, isLoading, error, refetch } = 
    useMutation(payload => ordersAPIService.getOrders(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        onError(e?.response?.data)
        // 
        console.error(e)
      }
    })
  
    return {
      getOrders: mutateAsync,
      orders: data,
      isLoading,
      refetch,
      error
    }
}

export const useGetOrder = (onSuccess, onError) => {
  const { mutateAsync, data, isLoading, error, refetch } =
    useMutation(payload => ordersAPIService.getOrder(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        onError(e?.response?.data)
        console.error(e)
      }
    })

  return {
    getOrderDetails: mutateAsync,
    order: data,
    isLoading,
    refetch,
    error
  }
}

export const useDeleteQuote = (onSuccess = () => {}, onError) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(payload => ordersAPIService.deleteQuote(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        onError()
        console.error(e)
      }
    })

  return {
    deleteQuote: mutateAsync,
    isDeletingQuote: isLoading,
    isSuccess,
    data,
    isError,
    error
  }
}

export const useDeleteOrder = (onSuccess = () => {}, onError = () => {}) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(payload => ordersAPIService.deleteOrder(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        onError()
        console.error(e)
      }
    })

  return {
    deleteOrder: mutateAsync,
    isDeletingOrder: isLoading,
    isSuccess,
    data,
    isError,
    error
  }
}

export const useUpdateOrder = (onSuccess = () => {}, onError) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(payload => ordersAPIService.updateOrder(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        onError()
        console.error(e)
      }
    })

  return {
    updateOrder: mutateAsync,
    isUpdatingOrder: isLoading,
    isSuccess,
    data,
    isError,
    error
  }
}

export const useGetAttributes = (onSuccess = () => {}, onError) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(payload => ordersAPIService.getAttributes(), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        onError()
        console.error(e)
      }
    })

  return {
    getAttributes: mutateAsync,
    isGettingAttributes: isLoading,
    isSuccess,
    data,
    isError,
    error
  }
}