import { Col, Row } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { convertPxToRem } from '../../helper';
import { Colors } from '../../themes/Colors';
import TextBase from '../text';
import { DealerCopyCode } from '../dealer-code-copy';

export const ActiveSubscription = ({ subscription = {}, code = '', status = '', isParentSubscription }) => {
  const isCancelled = subscription?.subscription?.cancel_at_period_end;
  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0px 30px', marginBottom: "20px" }}>
        <InputContainer>
          <TextBase
            style={{ opacity: 1 }}
            fontWeight={400}
            size={convertPxToRem(16)}
            color={Colors.black}>
            status{' '}
          </TextBase>
          <Input>
            <TextBase
              style={{ opacity: 1, textTransform: 'capitalize', minWidth: '300px' }}
              fontWeight={400}
              size={convertPxToRem(16)}
              color={isCancelled ? Colors.red : Colors.black}>
              {isCancelled
                ? 'cancelled'
                : subscription?.status === 'trialing'
                ? 'trial'
                : subscription?.status ? subscription?.status: status}
            </TextBase>
          </Input>
        </InputContainer>

        <InputContainer>
          <TextBase
            style={{ opacity: 1 }}
            fontWeight={400}
            size={convertPxToRem(16)}
            color={Colors.black}>
            activeTill{' '}
          </TextBase>
          <Input>
            <TextBase
              style={{ opacity: 1, minWidth: '300px' }}
              fontWeight={400}
              size={convertPxToRem(16)}
              color={Colors.black}>
              {subscription?.subscription?.current_period_end ? dayjs(subscription?.subscription?.current_period_end).format('MM/DD/YYYY'): ''}
            </TextBase>
          </Input>
        </InputContainer>
      </div>
      {
        isParentSubscription && (
          <InputContainer>
            <TextBase
              style={{ opacity: 1 }}
              fontWeight={400}
              size={convertPxToRem(16)}
              color={Colors.black}>
              subscriptionType{' '}
            </TextBase>
            <Input>
              <TextBase
                style={{ opacity: 1, minWidth: '300px' }}
                fontWeight={400}
                size={convertPxToRem(16)}
                color={Colors.black}>
                masterSubscribed
              </TextBase>
            </Input>
          </InputContainer>
        )
      }
      {
        code&&<>
          <TextBase
            style={{ marginTop: '20px', marginBottom: '10px' }}
            fontWeight={400}
            size={convertPxToRem(16)}
            color={Colors.black}>
            dealerUniqueCode{' '}
          </TextBase>
          <DealerCopyCode value={code} btnText={'Copy'} onClick={() => {}} />{' '}
        </>
      }
    </div>
  );
};

const InputContainer = styled.div`
  min-width: 200px;
  flex: 1;
`;
const Input = styled.div`
  min-width: 200px;
  flex: 1;
  border: 0.3px solid #e5e4e4;
  height: 50px;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  border-radius: 8px;
  margin-top: 10px;
`;
