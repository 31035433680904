import axios from "axios"
// import { store } from "../store"
// import { logoutAction } from "../container/auth/redux/actions"
export const baseURL = "https://www.move2vendor.com"
export const stagingURL = "https://move2-llc-move-3295-staging.botics.co"

const url = baseURL + "/api/v1/"
const urlStaging = stagingURL + "/api/v1/"
export const urlRest = baseURL + "/rest-auth/"

export const request = axios.create({
  baseURL: urlStaging
})

// request.interceptors.request.use(function (config) {
//   const token = store.getState().authState.token
//   const tempToken = store.getState().authState.tempToken

//   if (token) {
//     config.headers.Authorization = "Token " + token
//   }

//   if (tempToken) {
//     config.headers.Authorization = "Token " + tempToken
//   }

//   return config
// })

// request.interceptors.response.use(
//   response => {
//     return response
//   },
//   async function (error) {
//     if (error.response.status === 401) {
//       store.dispatch(logoutAction())
//     }
//     return Promise.reject(error)
//   }
// )

// export const requestRest = axios.create({
//   baseURL: urlRest
// })

// requestRest.interceptors.request.use(function (config) {
//   const tempToken = store.getState().authState.tempToken

//   if (tempToken) {
//     config.headers.Authorization = "Token " + tempToken
//   }

//   return config
// })

// requestRest.interceptors.response.use(
//   response => {
//     return response
//   },
//   async function (error) {
//     if (error.response.status === 401) {
//       store.dispatch(logoutAction())
//     }
//     return Promise.reject(error)
//   }
// )
