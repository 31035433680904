import { REQUEST_SUBTYPE } from "./ActionType";

const { REQUEST, SUCCESS, ERROR } = REQUEST_SUBTYPE;

class RAction {
  constructor({ actionListApi = [], actionName = "", actionListNormal = [] }) {
    this.actionListApi = actionListApi;
    this.actionListNormal = actionListNormal;
    this.actionName = actionName || "";
    this._init();
  }

  _init() {
    this.actionListNormal.forEach((key) => {
      const actionKey = key;
      this[key] = (data) => {
        return {
          type: actionKey,
          key: actionKey,
          data,
        };
      };
    });
    this.actionListApi.forEach((key) => {
      const actionKey = key;
      this[key + REQUEST] = (data) => {
        let action = {
          type: actionKey + REQUEST,
          key: actionKey,
          subType: REQUEST,
        };
        if (data) {
          action.data = data;
        }
        return action;
      };
      this[key + SUCCESS] = (data) => {
        let action = {
          type: actionKey + SUCCESS,
          key: actionKey,
          subType: SUCCESS,
        };
        if (data) {
          action.data = data;
        }
        return action;
      };
      this[key + ERROR] = (data) => {
        let action = {
          type: actionKey + ERROR,
          key: actionKey,
          subType: ERROR,
        };
        if (data) {
          action.data = data;
        }
        return action;
      };
    });
  }
}

export const createrAction = ({
  actionListApi = [],
  actionName = "",
  actionListNormal = [],
}) => {
  return new RAction({ actionListApi, actionName, actionListNormal });
};

export default RAction;
