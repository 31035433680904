import TextBase from "../text";
import {convertPxToRem} from "../../helper";
import {Colors} from "../../themes/Colors";
import React from "react";


const InputError = (props) => {
    return (
        <TextBase
            style={{textAlign: "left", marginTop: 4}}
            size={convertPxToRem(14)}
            color={Colors.red}
        >
            {props.error}
        </TextBase>
    )
}


export default InputError
