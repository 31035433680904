import moment from 'moment';

export const buildQueryString = (dataToSend) => {
  // Create an array of key-value pairs
  const queryStringArray = Object.entries(dataToSend)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`);

  // Join the array with "&" to form the final query string
  const queryString = queryStringArray.join('&');

  return queryString;
};

export const convertDateFormat = (timestamp) => {
  const dateTime = new Date(timestamp);

  // Convert to local time zone and format as a string
  const formattedDate = dateTime.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });

  return formattedDate;
};
export const formatCurrency = (amount) => {
  if (amount === null || amount === undefined) {
    return 'N/A';
  }
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}

export const formatDate = (timestamp) => {
  if (!timestamp) {
    return 'N/A';
  }

  return moment(timestamp, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY hh:mm a');


  // const date = new Date(timestamp);

  // const day = date.getDate().toString().padStart(2, '0');
  // const month = (date.getMonth() + 1).toString().padStart(2, '0');
  // const year = date.getFullYear();

  // const formattedDate = `${month}/${day}/${year}`;

  // return formattedDate;

};

export const formatTime = (time) => {
  const now = moment();
  const notificationDate = moment(time);

  const isToday = now.isSame(notificationDate, 'day');
  const isYesterday = now.clone().subtract(1, 'day').isSame(notificationDate, 'day');

  let formattedDate;
  if (isToday) {
    formattedDate = `Today at ${notificationDate.format('h:mm A')}`;
  } else if (isYesterday) {
    formattedDate = `Yesterday at ${notificationDate.format('h:mm A')}`;
  } else {
    formattedDate = notificationDate.format('MMM D, YYYY h:mm A');
  }

  return formattedDate;
};

export const copyToClipboard = (inputValue) => {
  const textarea = document.createElement('textarea')
  textarea.value = inputValue
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
}
