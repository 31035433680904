import { request } from '../connection/ApiDefine';

function getVendorSubscriptionProducts() {
  return request
    .get(`subscriptions/products/vendor/`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      
      throw error;
    });
}

function getVendorSubscriptionDetails() {
  return request
    .get(`subscriptions/vendor/`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      
      throw error;
    });
}

function getTrialSubscriptionSettings() {
  return request
    .get(`subscriptions/vendor-free-trial-settings/`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      
      throw error;
    });
}

function createVendorSubscription(payload) {
  return request
    .post('subscriptions/vendor/create/', payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      
      throw error;
    });
}

function cancelVendorSubscription(payload) {
  return request
    .post('subscriptions/vendor/cancel/', payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      
      throw error;
    });
}

function cancelChildSubscription(payload) {
  return request
    .post('subscriptions/vendor/child-subscription-cancel/', payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      
      throw error;
    });
}

function reactivateVendorSubscription(payload) {
  return request
    .post('subscriptions/vendor/reactivate/')
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      
      throw error;
    });
}

function reactivateChildSubscription(payload) {
  return request
    .post('subscriptions/vendor/child-reactivate/', payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      
      throw error;
    });
}

function createVendorChildSubscription(payload) {
  
  return request
    .post('subscriptions/vendor/child-create/', payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      
      throw error;
    });
}

export const subscriptionAPIService = {
  getVendorSubscriptionProducts,
  createVendorSubscription,
  getVendorSubscriptionDetails,
  getTrialSubscriptionSettings,
  cancelVendorSubscription,
  reactivateVendorSubscription,
  cancelChildSubscription,
  reactivateChildSubscription,
  createVendorChildSubscription
};
