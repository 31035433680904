import React from 'react'
import BasePage from '../base'
import { css } from 'aphrodite'
import {
    convertPxToRem,
    deepCopyObject, getRoleLabel, getRoleValue,
    getUserId,
    getUserNameShow,
    getValueFromObjectByKeys,
    MESSAGE_TYPE,
    messageManager,
    myLog,
    renderCountries,
    renderCountriesPhoneCode,
    renderStates
} from '../../helper'
import TextBase from '../../common/text'
import { Colors } from '../../themes/Colors'
import { userKey } from '../../utils/defined'
import { connect } from 'react-redux'
import withNavigate from '../../component/WithNavigate'
import { Col, Popover, Row, Switch } from 'antd'
import { SVGImageComponentReact } from '../../themes/Images'
import LoginInput from '../../common/login-input'
import ButtonGradient from '../../common/button-gradient'
import { UserAction } from '../../redux/action'
import SelectCustom from '../../common/select-custom'
import DefaultAvatar from '../../assets/image/default-avatar.png'
import FormArea from '../../common/form-area'
import OTPModal from '../../component/OTPModal'
import { styles } from './style'
import ImageZoom from '../../common/image-zoom'
import 'react-image-crop/dist/ReactCrop.css'
import Modal from '@mui/material/Modal'
import ImageEditor from '../../common/ImageCropModal'

const menuKey = {
    legal: 'legal',
    policy: 'policy',
    agreement: 'agreement',
    note: 'note'
}

class AccountSettings extends BasePage {
    constructor(props) {
        super(props)
        this.title = 'Settings'
        this.state = {
            isTeamMember: true,
            userPhoneFormatValid: false,
            showOTP: false,
            showOTPUser: false,
            showOTPPopOver: false,
            showOTPUserPopOver: false,
            menuSelected: menuKey.policy,
            firstName: '',
            lastName: '',
            [userKey.firstName]: '',
            [userKey.lastName]: '',
            [userKey.email]: '',

            name: '',
            avatar: '',
            loadingUpdateData: false,
            profileImageFile: null,
            previewImage: '',
            description: '',
            businessEmail: '',
            phoneNumber: '',
            phoneNumberPrev: '',
            businessName: '',
            taxID: '',
            country: '',
            userPhoneCode: '',
            userPhoneCodePrev: '',
            phoneCode: '',
            phoneCodePrev: '',
            state: '',
            city: '',
            zip: '',
            address: '',
            notificationEmail: false,
            notificationSms: false,
            notificationThread: false,
            dealerImageFile: null,
            previewDealerImage: '',
            dealerAvatar: '',
            phoneVerified: false,
            phoneFormatValid: false,
            email: '',
            role: '',
            userPhone: '',
            userPhonePrev: '',
            userPhoneVerified: false,
            domainName: '',
            manageBranding: false,
            customDomain: false,
            logoImage: null,
            isEditorOpen: false,
            logoImage2: null,
            isEditorOpen2: false,
        }
        this.cacheUserInfo = {}
    }

    _componentDidMount = async () => {
        this.fetchData()
    }

    handleCancel = () => {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            this.setState({
                loadingUpdateData: true
            })
            const { getUserProfile, getVendorProfile, userInfo, getVendor } = this.props

            const userId = getValueFromObjectByKeys(userInfo, ['user', 'id'])

            try {
                const resProfile = await getUserProfile(getUserId(userInfo))
                const resVendorProfile = await getVendorProfile(getUserId(userInfo))

                const userData = getValueFromObjectByKeys(resProfile, ['data'])
                const vendorData = getValueFromObjectByKeys(resVendorProfile, ['data'])
                this.saveDataUser(userData)
                this.setUserData({ ...userData })

                this.setVendorData(vendorData)
                this.setState({
                    loadingUpdateData: false
                })
            } catch (e) {
                this.setState({
                    loading: false,
                    loadingUpdateData: false
                })

            }
        } catch (error) {
            myLog('_componentDidMount Profile', error)
        }
    }

    onPhoneCodeSelect = (key, value) => {

        this.setState({
            [key]: value
        })
    }

    onCountrySelect = (value) => {
        if (value !== 'US' || value !== 'CA') {
            this.setState({ state: undefined })
        }
        this.setState({
            country: value
        })
    }
    handleState = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    onStateSelect = (value) => {
        this.setState({
            state: value
        })
    }

    setVendorData = (dealerData) => {
        this.setState({
            vendorId: dealerData.id,
            description: dealerData.description,
            businessEmail: dealerData.business_email,
            phoneNumber: dealerData.phone_number.national_number,
            phoneCode: dealerData.phone_number.country_code || '1',
            phoneNumberPrev: dealerData.phone_number.national_number,
            phoneCodePrev: dealerData.phone_number.country_code || '1',
            businessName: dealerData.business_name,
            taxID: dealerData.tax_id,
            country: dealerData.country,
            state: dealerData.state,
            city: dealerData.city,
            zip: dealerData.zip,
            address: dealerData.address,
            notificationThread: dealerData?.notification_thread,
            phoneVerified: dealerData.phone_verified,
            dealerAvatar: dealerData?.logo
        })
    }

    setUserData = (userData) => {
        this.setState({
            isTeamMember: userData?.team_member,
            firstName: userData?.user?.first_name,
            lastName: userData?.user?.last_name,
            role: userData?.role,
            email: userData?.user?.email,
            userPhone: userData?.phone_number?.national_number,
            userPhoneCode: userData?.phone_number?.country_code || '1',
            userPhonePrev: userData?.phone_number?.national_number,
            userPhoneCodePrev: userData?.phone_number?.country_code || '1',
            userPhoneVerified: userData?.phone_verified,
            notificationEmail: userData?.notification_email,
            notificationSms: userData?.notification_sms,
            notificationThread: userData?.notification_thread,
            domainName: userData?.domain_configuration?.domain_name,
            manageBranding: userData?.manage_branding,
            customDomain: userData?.custom_domain,
        })
    }

    saveDataUser = (userData = {}) => {
        const { userInfo } = this.props
        this.cacheUserInfo = {
            avatar: userData[userKey.profile_image],
            name: getUserNameShow(userInfo)
        }
        this.setState({
            userInfo: deepCopyObject(this.cacheUserInfo),
            avatar: userData[userKey.profile_image],
            name: getUserNameShow(userInfo),
            profileImageFile: null,
            previewImage: ''
        })
    }

    updateProfile = async () => {
        const {
            firstName,
            lastName,
            userPhone,
            userPhoneCode,
            phoneCode,
            email,
            role,
            description,
            businessEmail,
            phoneNumber,
            businessName,
            taxID,
            country,
            state,
            city,
            zip,
            address,
            notificationEmail,
            notificationSms,
            notificationThread,
            isTeamMember,
        } = this.state

        const { updateProfile, userInfo, vendor, updateVendor } = this.props
        const {
            avatar,
            previewImage,
            profileImageFile,
            dealerImageFile,
            previewDealerImage,
            dealerAvatar
        } = this.state

        if (previewImage && profileImageFile) {
            let formData = new FormData()
            formData.append([userKey.profile_image], profileImageFile)
            const resNewData = await updateProfile(formData, getUserId(userInfo))
        }

        if (previewDealerImage && dealerImageFile) {
            let formData = new FormData()
            formData.append('logo', dealerImageFile)
            const resUpdateVendor = await updateVendor(formData, this.state.vendorId)
        }

        if (true) {
            this.setState({
                loadingUpdateData: true
            })

            try {
                let formData = {
                    notification_email: notificationEmail,
                    notification_sms: notificationSms,
                    notification_thread: notificationThread,
                    role: getRoleValue(role),
                    user: {
                        first_name: this.state.firstName,
                        last_name: this.state.lastName,
                        email,
                    },
                    domain: {
                        domain_name: this.state.domainName,
                    },
                }
                if (userPhone) {
                    formData = {
                        ...formData,
                        phone_number: { national_number: userPhone, country_code: userPhoneCode }
                    }
                }

                let vendorFormData = {
                    full_name: `${firstName} ${lastName}`,
                    description,
                    business_email: businessEmail,
                    business_name: businessName,
                    tax_id: taxID,
                    country,
                    city,
                    zip,
                    address,
                    notification_thread: notificationThread,
                }

                if (phoneNumber) {
                    vendorFormData = {
                        ...vendorFormData,
                        phone_number: { national_number: phoneNumber, country_code: phoneCode }
                    }
                }
                if (state) {
                    vendorFormData.state = state
                }

                const resNewData = await updateProfile(formData, getUserId(userInfo), false)
                if (resNewData) {
                    messageManager({
                        type: MESSAGE_TYPE.success,
                        messageShow: 'Updated Successfully.'
                    })
                    this.setState({
                        loadingUpdateData: false
                    })
                    const userData = getValueFromObjectByKeys(resNewData, ['data'])
                    this.saveDataUser(userData)
                    this.setUserData({ ...userData })
                }
                if (!isTeamMember) {
                    const resUpdateVendor = await updateVendor(vendorFormData, this.state.vendorId)
                    const vendorData = getValueFromObjectByKeys(resUpdateVendor, ['data'])
                    this.setVendorData(vendorData)
                }

                const userData = getValueFromObjectByKeys(resNewData, ['data'])

            } catch (error) {
                messageManager({
                    type: MESSAGE_TYPE.error,
                    messageShow: error?.error || 'Update Failed.'
                })
                this.setState({
                    loadingUpdateData: false
                })
            }
        }
    }

    onReceiveByEmail = () => {
        this.setState({ notificationEmail: !this.state.notificationEmail })
    }

    onReceiveBySMS = () => {
        if (this.state.userPhoneVerified) {
            this.setState({ notificationSms: !this.state.notificationSms })
        } else {
            this.setState({ notificationSms: false })
            messageManager({
                messageShow: 'Please verify your mobile number',
                type: MESSAGE_TYPE.warning
            })
        }
    }

    onReceiveByThread = () => {
        this.setState({ notificationThread: !this.state.notificationThread })
    }

    getSMSCodeForUser = async () => {
        const { sendSMSUser } = this.props
        const { userPhoneCode, userPhone, userPhonePrev } = this.state
        try {

            if (userPhonePrev !== userPhone) {
                this.setState({
                    showOTPUserPopOver: true
                })
                setTimeout(() => {
                    this.setState({
                        showOTPUserPopOver: false
                    })
                }, 3000)
                return
            }

            await sendSMSUser({
                phone_number: `+${userPhoneCode}${userPhone}`
            })
            this.setState({
                showOTPUser: true
            })
        } catch (error) {

            alert(error?.error)
            this.setState({
                showOTPUser: false
            })
        }
    }

    getSMSCode = async () => {
        const { sendSMS } = this.props

        const { phoneNumber, phoneNumberPrev, phoneCode } = this.state

        if (phoneNumberPrev !== phoneNumber) {
            this.setState({
                showOTPPopOver: true
            })
            setTimeout(() => {
                this.setState({
                    showOTPPopOver: false
                })
            }, 3000)
            return
        }

        try {
            await sendSMS({
                phone_number: `+${phoneCode}${phoneNumber}`
            })
            this.setState({
                showOTP: true
            })
        } catch (error) {

            this.setState({
                showOTP: false
            })
        }
    }

    closeModal = () => {
        this.setState({
            showOTP: false,
            showOTPUser: false
        })
    }

    onFileUpload = (e) => {
        const file = e.target.files[0]
        this.setState({ logoImage: file, isEditorOpen: true })
    }
    onFileUpload2 = (e) => {
        const file = e.target.files[0]
        this.setState({ logoImage2: file, isEditorOpen2: true })
    }

    imageUrl(url) {
        if (url?.type) {
            return URL.createObjectURL(url)
        }
        return url
    }

    handleCloseEditor = () => {
        let input = document.getElementById('myFile')
        if (input) {
            input.value = ''
        }
        this.setState({ logoImage: null, isEditorOpen: false })
    }

    handleCloseEditor2 = () => {
        let input = document.getElementById('myFile2')
        if (input) {
            input.value = ''
        }
        this.setState({ logoImage2: null, isEditorOpen2: false })
    }

    handleCroppedImage = (img) => {
        let arr = img.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        let file = new File([u8arr], this.state.logoImage.name, { type: mime })
        this.setState({ isEditorOpen: false, profileImageFile: file, previewImage: file })
        this.handleCloseEditor()
    }

    handleCroppedImage2 = (img) => {
        let arr = img.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        let file = new File([u8arr], this.state.logoImage2.name, { type: mime })
        this.setState({ isEditorOpen2: false, dealerImageFile: file, previewDealerImage: file })
        this.handleCloseEditor2()
    }

    renderContent() {
        const { navigate, userInfo } = this.props
        const {
            firstName,
            lastName,
            description,
            businessEmail,
            phoneNumber,
            businessName,
            taxID,
            country,
            phoneCode,
            userPhoneCode,
            state,
            city,
            zip,
            address,
            notificationEmail,
            notificationSms,
            notificationThread,
            name,
            avatar,
            loadingUpdateData,
            previewImage,
            profileImageFile,
            previewDealerImage,
            dealerImageFile,
            dealerAvatar,
            phoneFormatValid,
            role,
            userPhone,
            domainName,
            manageBranding,
            customDomain
        } = this.state

        return (
            <div className={css(styles.container)}>
                <div className="flex-row-center">
                    <TextBase fontWeight={600} size={convertPxToRem(24)} color={Colors.grey2}>
                        myProfile
                    </TextBase>
                </div>
                <Row
                    align="center"
                    justify="center"
                    style={{ marginTop: convertPxToRem(38) }}
                    gutter={[25]}>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={18}
                        style={{ paddingRight: convertPxToRem(25) }}
                        span={16}>
                        <div
                            style={{
                                padding: convertPxToRem(32),
                                backgroundColor: Colors.white,
                                borderRadius: convertPxToRem(10),
                                boxShadow: `0px 1px 2px 1px rgba(0, 0, 0, 0.05)`
                            }}>
                            <div className={css(styles.header)}>
                                <TextBase
                                    fontWeight={600}
                                    style={{ marginBottom: convertPxToRem(22) }}
                                    size={convertPxToRem(18)}
                                    color={Colors.gray2}>
                                    personalInformation
                                </TextBase>
                                <div className={css(styles.divider)}/>
                            </div>

                            <Row gutter={[30]}>
                                {/* First Name */}
                                <Col span={24}>
                                    <div
                                        style={{
                                            width: convertPxToRem(120),
                                            height: convertPxToRem(120),
                                            borderRadius: convertPxToRem(139),
                                            backgroundColor: Colors.white,
                                            marginBottom: convertPxToRem(21),
                                            border: `solid 2px ${Colors.white}`,
                                            position: 'relative'
                                        }}>
                                        {previewImage || avatar ? (
                                            <ImageZoom
                                                imageSrc={previewImage && profileImageFile ? previewImage : avatar}
                                                altSrc={previewImage && profileImageFile ? previewImage : avatar}
                                            >
                                                <img
                                                    src={previewImage && profileImageFile ? this.imageUrl(previewImage) : avatar}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: convertPxToRem(139)
                                                    }}
                                                />
                                            </ImageZoom>
                                        ) : (
                                            <ImageZoom
                                                imageSrc={DefaultAvatar}
                                                altSrc={DefaultAvatar}
                                            >
                                                <img
                                                    src={DefaultAvatar}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: convertPxToRem(139)
                                                    }}
                                                />
                                            </ImageZoom>
                                        )}

                                        <div
                                            className="flex-center-all cursor-pointer"
                                            style={{
                                                position: 'absolute',
                                                right: convertPxToRem(-10),
                                                top: convertPxToRem(70),
                                                width: convertPxToRem(30),
                                                height: convertPxToRem(30),
                                                borderRadius: convertPxToRem(50),
                                                border: `solid 0.5px ${Colors.white}`,
                                                boxShadow: '0px 9.49091px 47.4545px rgba(2, 17, 69, 0.09)',
                                                backgroundColor: Colors.white
                                            }}>
                                            <div>
                                                <SVGImageComponentReact.IconEditPen
                                                    style={{ marginTop: 7, width: 15 }}/>
                                                <input onChange={this.onFileUpload} style={{
                                                    position: 'absolute',
                                                    left: 0,
                                                    width: '100%',
                                                    opacity: 0,
                                                    padding: 0,
                                                    cursor: 'pointer'
                                                }} type="file" id="myFile" name="filename"></input>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div>
                                        <TextBase size={16} fontWeight={400} color={Colors.black}>
                                            firstName
                                        </TextBase>
                                        <LoginInput
                                            value={firstName}
                                            placeholder="enterFirstName"
                                            className="width-100"
                                            style={{
                                                width: '100%',
                                                marginTop: convertPxToRem(6),
                                                fontSize: convertPxToRem(16),
                                                paddingLeft: convertPxToRem(16)
                                            }}
                                            onChange={(val) => this.handleState('firstName', val)}
                                        />
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div>
                                        <TextBase size={16} fontWeight={400} color={Colors.black}>
                                            lastName
                                        </TextBase>
                                        <LoginInput
                                            value={lastName}
                                            placeholder="enterLastName"
                                            className="width-100"
                                            style={{
                                                width: '100%',
                                                marginTop: convertPxToRem(6),
                                                fontSize: convertPxToRem(16),
                                                paddingLeft: convertPxToRem(16),
                                                color: Colors.black,
                                                backgroundColor: Colors.white
                                            }}
                                            onChange={(val) => this.handleState('lastName', val)}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={30}>
                                <Col xxl={12} span={24} style={{ marginTop: 20 }}>
                                    <div>
                                        <TextBase
                                            style={{ textAlign: 'left' }}
                                            className="ellipsis"
                                            size={convertPxToRem(16)}
                                            color={Colors.black}>
                                            email
                                        </TextBase>
                                        <LoginInput
                                            value={this.state.email}
                                            onChange={(val) => this.handleState('email', val)}
                                            placeholder="enterEmail"
                                            noPaddingRight={true}
                                            rightElement={
                                                <ButtonGradient
                                                    textStyle={{ fontWeight: 600, fontSize: 17 }}
                                                    title="verified"
                                                    backgroundColor="#10B981"
                                                    disabled
                                                    style={{
                                                        padding: 20,
                                                        height: '50px',
                                                        width: '100%',
                                                        borderTopLeftRadius: 0,
                                                        borderBottomLeftRadius: 0,
                                                        boxShadow: '-2px 0px 2px 0px #4040400D'
                                                    }}
                                                    loading={loadingUpdateData}
                                                />
                                            }
                                        />
                                    </div>
                                </Col>

                                <Col xxl={12} span={24} style={{ marginTop: 20 }}>
                                    <div>
                                        <TextBase
                                            style={{ textAlign: 'left' }}
                                            className="ellipsis"
                                            size={convertPxToRem(16)}
                                            color={Colors.black}>
                                            mobileNumber
                                        </TextBase>

                                        <div style={{ display: 'flex' }}>
                                            <div style={{ minWidth: '150px', zIndex: 2200 }}>
                                                <SelectCustom
                                                    className="full-width"
                                                    value={userPhoneCode}
                                                    placeholder="Code"
                                                    items={renderCountriesPhoneCode()}
                                                    onSelect={(val) => {
                                                        this.onPhoneCodeSelect('userPhoneCode', val)
                                                    }}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', width: '100%' }}>
                                                <div style={{ width: '100%', marginLeft: '10px' }}>
                                                    <LoginInput
                                                        value={userPhone}
                                                        onChange={(val) => this.handleState('userPhone', val)}
                                                        placeholder="enterMobile"
                                                        noPaddingRight={true}
                                                        rightElement={
                                                            <ButtonGradient
                                                                disabled={
                                                                    this.state.userPhoneVerified &&
                                                                    this.state.userPhone === this.state.userPhonePrev
                                                                }
                                                                className="verified-button"
                                                                onClick={() => this.getSMSCodeForUser()}
                                                                textStyle={{ fontWeight: 600, fontSize: 17 }}
                                                                title={
                                                                    this.state.userPhoneVerified &&
                                                                    this.state.userPhone === this.state.userPhonePrev
                                                                        ? 'verified'
                                                                        : 'verify'
                                                                }
                                                                backgroundColor={
                                                                    this.state.userPhoneVerified &&
                                                                    this.state.userPhone === this.state.userPhonePrev
                                                                        ? '#10B981'
                                                                        : this.state.userPhonePrev !== this.state.userPhone
                                                                            ? '#C8C8C8'
                                                                            : null
                                                                }
                                                                style={{
                                                                    padding: 20,
                                                                    height: '50px',
                                                                    width: '100%',
                                                                    borderTopLeftRadius: 0,
                                                                    borderBottomLeftRadius: 0,
                                                                    boxShadow: '-2px 0px 2px 0px #4040400D'
                                                                }}
                                                                loading={loadingUpdateData}
                                                            />
                                                        }
                                                    />
                                                </div>
                                                <Popover
                                                    content="Update phone to verify"
                                                    visible={this.state.showOTPUserPopOver}
                                                    placement="topRight"
                                                    style={{ color: 'red' }}
                                                ></Popover>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={30} style={{ marginTop: 20 }}>
                                {/* Role */}
                                <Col span={12}>
                                    <div>
                                        <TextBase size={16} fontWeight={400} color={Colors.black}>
                                            role
                                        </TextBase>
                                        <LoginInput
                                            value={getRoleLabel(role)}
                                            placeholder="enterRole"
                                            className="width-100"
                                            style={{
                                                width: '100%',
                                                marginTop: convertPxToRem(6),
                                                fontSize: convertPxToRem(16),
                                                paddingLeft: convertPxToRem(16),
                                                color: Colors.black,
                                                backgroundColor: Colors.white
                                            }}
                                            onChange={(val) => this.handleState('role', val)}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div className={css(styles.header, styles.spacingMd)}>
                                <TextBase
                                    fontWeight={600}
                                    style={{ marginBottom: convertPxToRem(22) }}
                                    size={convertPxToRem(18)}
                                    color={Colors.gray2}>
                                    notificationSettings
                                </TextBase>
                                <div className={css(styles.divider)}/>
                            </div>

                            <Row>
                                <Col span={12}>
                                    <Row justify="space-between">
                                        <Row>
                                            <TextBase
                                                fontWeight={400}
                                                size={convertPxToRem(16)}
                                                style={{ marginRight: convertPxToRem(16) }}
                                                color={Colors.black}>
                                                receiveByEmail
                                            </TextBase>

                                            {!loadingUpdateData && (
                                                <Switch
                                                    defaultChecked={this.state.notificationEmail}
                                                    className={
                                                        this.state.notificationEmail
                                                            ? css(styles.switchEnabled)
                                                            : css(styles.switchDisabled)
                                                    }
                                                    onChange={this.onReceiveByEmail}
                                                />
                                            )}
                                        </Row>

                                        <Row>
                                            <TextBase
                                                fontWeight={400}
                                                size={convertPxToRem(16)}
                                                style={{ marginRight: convertPxToRem(16) }}
                                                color={Colors.black}>
                                                receiveBySMS
                                            </TextBase>
                                            {!loadingUpdateData && (
                                                <Switch
                                                    checked={this.state.notificationSms}
                                                    className={
                                                        notificationSms ? css(styles.switchEnabled) : css(styles.switchDisabled)
                                                    }
                                                    onChange={this.onReceiveBySMS}
                                                />
                                            )}
                                        </Row>

                                        <Row>
                                            <TextBase
                                                fontWeight={400}
                                                size={convertPxToRem(16)}
                                                style={{ marginRight: convertPxToRem(16) }}
                                                color={Colors.black}>
                                                receiveByNotification
                                            </TextBase>
                                            {!loadingUpdateData && (
                                                <Switch
                                                    checked={this.state.notificationThread}
                                                    className={
                                                        notificationThread ? css(styles.switchEnabled) : css(styles.switchDisabled)
                                                    }
                                                    onChange={this.onReceiveByThread}
                                                />
                                            )}
                                        </Row>
                                    </Row>
                                </Col>
                            </Row>

                            <div className={css(styles.header, styles.spacingMd)}>
                                <TextBase
                                    fontWeight={600}
                                    style={{ marginBottom: convertPxToRem(22) }}
                                    size={convertPxToRem(18)}
                                    color={Colors.gray2}>
                                    businessInformation
                                </TextBase>
                                <div className={css(styles.divider)}/>
                            </div>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={24}>
                                    <div
                                        style={{
                                            width: convertPxToRem(120),
                                            height: convertPxToRem(120),
                                            borderRadius: convertPxToRem(139),
                                            backgroundColor: Colors.white,
                                            marginBottom: convertPxToRem(21),
                                            border: `solid 2px ${Colors.white}`,
                                            position: 'relative'
                                        }}>
                                        {previewDealerImage || dealerAvatar ? (
                                            <ImageZoom
                                                imageSrc={previewDealerImage && dealerImageFile ? previewDealerImage : dealerAvatar}
                                                altSrc={previewDealerImage && dealerImageFile ? previewDealerImage : dealerAvatar}
                                            >
                                                <img
                                                    src={
                                                        previewDealerImage && dealerImageFile ? this.imageUrl(previewDealerImage) : dealerAvatar
                                                    }
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: convertPxToRem(139)
                                                    }}
                                                />
                                            </ImageZoom>
                                        ) : (
                                            <ImageZoom
                                                imageSrc={DefaultAvatar}
                                                altSrc={DefaultAvatar}
                                            >
                                                <img
                                                    src={DefaultAvatar}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: convertPxToRem(139)
                                                    }}
                                                />
                                            </ImageZoom>
                                        )}

                                        {!this.state.isTeamMember && (
                                            <div
                                                className="flex-center-all cursor-pointer"
                                                style={{
                                                    position: 'absolute',
                                                    right: convertPxToRem(-10),
                                                    top: convertPxToRem(70),
                                                    width: convertPxToRem(30),
                                                    height: convertPxToRem(30),
                                                    borderRadius: convertPxToRem(50),
                                                    border: `solid 0.5px ${Colors.white}`,
                                                    boxShadow: '0px 9.49091px 47.4545px rgba(2, 17, 69, 0.09)',
                                                    backgroundColor: Colors.white
                                                }}>
                                                {/* <BaseLayoutDropzone
                          onDrop={this.onDealerImageDrop}
                          accept={{ 'image/*': [] }}>
                          <SVGImageComponentReact.IconEditPen style={{ marginTop: 7, width: 15 }} />
                        </BaseLayoutDropzone> */}

                                                <div>
                                                    <SVGImageComponentReact.IconEditPen
                                                        style={{ marginTop: 7, width: 15 }}/>
                                                    <input onChange={this.onFileUpload2} style={{
                                                        position: 'absolute',
                                                        left: 0,
                                                        width: '100%',
                                                        opacity: 0,
                                                        padding: 0,
                                                        cursor: 'pointer'
                                                    }} type="file" id="myFile2" name="filename"></input>
                                                </div>


                                            </div>
                                        )}
                                    </div>
                                </Col>

                                {/* Description*/}
                                <Col span={24} style={{ marginBottom: 20 }}>
                                    <div>
                                        <TextBase size={16} fontWeight={400} color={Colors.black}>
                                            dealerDescription
                                        </TextBase>
                                        <FormArea
                                            disabled={this.state.isTeamMember}
                                            value={description}
                                            placeholder="Enter description"
                                            className="width-100"
                                            style={{
                                                width: '100%',
                                                marginTop: convertPxToRem(6),
                                                fontSize: convertPxToRem(16),
                                                paddingLeft: convertPxToRem(16),
                                                color: Colors.black,
                                                backgroundColor: Colors.grey4
                                            }}
                                            onChange={(val) => this.handleState('description', val)}
                                        />
                                    </div>
                                </Col>

                                <Col xxl={12} span={24} style={{ marginTop: 20 }}>
                                    <div>
                                        <TextBase
                                            style={{ textAlign: 'left' }}
                                            className="ellipsis"
                                            size={convertPxToRem(16)}
                                            color={Colors.black}>
                                            businessEmail
                                        </TextBase>
                                        <LoginInput
                                            disabled={this.state.isTeamMember}
                                            value={businessEmail}
                                            onChange={(val) => this.handleState('businessEmail', val)}
                                            placeholder="enterEmail"
                                            noPaddingRight={true}
                                        />
                                    </div>
                                </Col>

                                <Col xxl={12} span={24} style={{ marginTop: 20 }}>
                                    <div>
                                        <TextBase
                                            style={{ textAlign: 'left' }}
                                            className="ellipsis"
                                            size={convertPxToRem(16)}
                                            color={Colors.black}>
                                            mobileNumber
                                        </TextBase>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ minWidth: '150px', zIndex: 2200 }}>
                                                <SelectCustom
                                                    className="full-width"
                                                    value={phoneCode}
                                                    placeholder="Code"
                                                    items={renderCountriesPhoneCode()}
                                                    onSelect={(val) => {
                                                        this.onPhoneCodeSelect('phoneCode', val)
                                                    }}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', width: '100%' }}>
                                                <div style={{ width: '100%', marginLeft: '10px' }}>
                                                    <LoginInput
                                                        disabled={this.state.isTeamMember}
                                                        value={phoneNumber}
                                                        onChange={(val) => this.handleState('phoneNumber', val)}
                                                        placeholder="enterMobile"
                                                        noPaddingRight={true}
                                                        rightElement={
                                                            <ButtonGradient
                                                                disabled={
                                                                    this.state.phoneVerified &&
                                                                    this.state.phoneNumber === this.state.phoneNumberPrev
                                                                }
                                                                className="verified-button"
                                                                onClick={() => {
                                                                    return this.state.isTeamMember ? () => {
                                                                    } : this.getSMSCode()
                                                                }}
                                                                textStyle={{ fontWeight: 600, fontSize: 17 }}
                                                                title={
                                                                    this.state.phoneVerified &&
                                                                    this.state.phoneNumber === this.state.phoneNumberPrev
                                                                        ? 'verified'
                                                                        : 'verify'
                                                                }
                                                                backgroundColor={
                                                                    this.state.phoneVerified &&
                                                                    this.state.phoneNumber === this.state.phoneNumberPrev
                                                                        ? '#10B981'
                                                                        : this.state.phoneNumber !== this.state.phoneNumberPrev
                                                                            ? '#C8C8C8'
                                                                            : null
                                                                }
                                                                style={{
                                                                    padding: 20,
                                                                    height: '50px',
                                                                    width: '100%',
                                                                    borderTopLeftRadius: 0,
                                                                    borderBottomLeftRadius: 0,
                                                                    boxShadow: '-2px 0px 2px 0px #4040400D'
                                                                }}
                                                                loading={loadingUpdateData}
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <Popover
                                                    content="Update phone to verify"
                                                    visible={this.state.showOTPPopOver}
                                                    placement="topRight"
                                                    style={{ color: 'red' }}
                                                ></Popover>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
                                <Col span={12}>
                                    <div>
                                        <TextBase
                                            style={{ textAlign: 'left' }}
                                            className="ellipsis"
                                            size={convertPxToRem(16)}
                                            color={Colors.black}>
                                            businessName
                                        </TextBase>
                                        <LoginInput
                                            disabled={this.state.isTeamMember}
                                            value={businessName}
                                            onChange={(val) => this.handleState('businessName', val)}
                                            placeholder="enterBusinessName"
                                            noPaddingRight={true}
                                        />
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div>
                                        <TextBase
                                            style={{ textAlign: 'left' }}
                                            size={convertPxToRem(16)}
                                            color={Colors.black}>
                                            taxId
                                        </TextBase>
                                        <LoginInput
                                            disabled={this.state.isTeamMember}
                                            value={taxID}
                                            onChange={(val) => this.handleState('taxID', val)}
                                            placeholder="enterTaxId"
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
                                <Col span={12}>
                                    <div>
                                        <TextBase
                                            style={{ textAlign: 'left' }}
                                            size={convertPxToRem(16)}
                                            color={Colors.black}>
                                            headquarterCountry
                                        </TextBase>
                                        <SelectCustom
                                            disabled={this.state.isTeamMember}
                                            className="full-width"
                                            value={country}
                                            items={renderCountries()}
                                            onSelect={this.onCountrySelect}
                                        />
                                    </div>
                                </Col>

                                {(country === 'US' || country === 'CA') && (
                                    <Col span={12}>
                                        <div>
                                            <TextBase
                                                style={{ textAlign: 'left' }}
                                                size={convertPxToRem(16)}
                                                color={Colors.black}>
                                                state
                                            </TextBase>
                                            <SelectCustom
                                                disabled={this.state.isTeamMember}
                                                value={state}
                                                items={renderStates(country)}
                                                onSelect={this.onStateSelect}
                                            />
                                        </div>
                                    </Col>
                                )}
                            </Row>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
                                <Col span={24}>
                                    <div>
                                        <TextBase
                                            style={{ textAlign: 'left' }}
                                            size={convertPxToRem(16)}
                                            color={Colors.black}>
                                            address
                                        </TextBase>
                                        <LoginInput
                                            disabled={this.state.isTeamMember}
                                            value={address}
                                            onChange={(val) => this.handleState('address', val)}
                                            placeholder="enterAddress"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
                                <Col span={12}>
                                    <div>
                                        <TextBase
                                            style={{ textAlign: 'left' }}
                                            size={convertPxToRem(16)}
                                            color={Colors.black}>
                                            city
                                        </TextBase>
                                        <LoginInput
                                            disabled={this.state.isTeamMember}
                                            value={city}
                                            onChange={(val) => this.handleState('city', val)}
                                            placeholder="enterCity"
                                        />
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div>
                                        <TextBase
                                            style={{ textAlign: 'left' }}
                                            size={convertPxToRem(16)}
                                            color={Colors.black}>
                                            zip
                                        </TextBase>
                                        <LoginInput
                                            disabled={this.state.isTeamMember}
                                            value={zip}
                                            onChange={(val) => this.handleState('zip', val)}
                                            placeholder="enterZip"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {
                                (
                                    userInfo?.user?.role == 'White Label Partner Admin' ||
                                    (userInfo?.user?.role == 'Child Account' && customDomain)) && (
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
                                        <Col span={24}>
                                            <div>
                                                <TextBase
                                                    style={{ textAlign: 'left' }}
                                                    size={convertPxToRem(16)}
                                                    color={Colors.black}>
                                                    domain
                                                </TextBase>
                                                <LoginInput
                                                    disabled={this.state.isTeamMember}
                                                    value={domainName}
                                                    onChange={(val) => this.handleState('domainName', val)}
                                                    placeholder="enterDomainName"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            }

                            <Row gutter={30}>
                                <Col span={12}>
                                    <div
                                        className="flex-row-center-center cursor-pointer cancel-button"
                                        style={{ marginTop: convertPxToRem(60), height: '3.5rem' }}>
                                        <TextBase
                                            onClick={this.handleCancel}
                                            size={convertPxToRem(16)}
                                            fontWeight={700}
                                            style={{ textAlign: 'center' }}
                                            color={Colors.grey}>
                                            cancel
                                        </TextBase>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="flex-row-center-all" style={{ marginTop: convertPxToRem(60) }}>
                                        <ButtonGradient
                                            onClick={this.updateProfile}
                                            textStyle={{ fontWeight: 700, fontSize: 16 }}
                                            title="update"
                                            style={{ width: '100%' }}
                                            loading={loadingUpdateData}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                {/* User */}
                <OTPModal
                    type="user"
                    fetchData={this.fetchData}
                    getSMSCode={this.getSMSCodeForUser}
                    showOTP={this.state.showOTPUser}
                    phone={this.state.userPhone || ''}
                    phoneCode={this.state.userPhoneCode || ''}
                    closeModal={this.closeModal}
                />

                {/* Dealer */}
                <OTPModal
                    type="dealer"
                    fetchData={this.fetchData}
                    getSMSCode={this.getSMSCode}
                    showOTP={this.state.showOTP}
                    phoneCode={this.state.phoneCode || ''}
                    phone={this.state.phoneNumber || ''}
                    closeModal={this.closeModal}
                />

                <Modal open={this.state.isEditorOpen} onClose={this.handleCloseEditor}>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            width: '60%',
                            minHeight: '400px',
                            position: 'relative',
                            background: '#000',
                            padding: '10px'
                        }}>
                            <ImageEditor img={this.state.logoImage} setCroppedImage={this.handleCroppedImage}
                                         handleCloseEditor={this.handleCloseEditor}/>
                        </div>
                    </div>
                </Modal>

                <Modal open={this.state.isEditorOpen2} onClose={this.handleCloseEditor2}>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            width: '60%',
                            minHeight: '400px',
                            position: 'relative',
                            background: '#000',
                            padding: '10px'
                        }}>
                            <ImageEditor img={this.state.logoImage2} setCroppedImage={this.handleCroppedImage2}
                                         handleCloseEditor={this.handleCloseEditor2}/>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userDetail: getValueFromObjectByKeys(state, ['User', 'userDetail']),
        userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
        vendor: getValueFromObjectByKeys(state, ['User', 'vendor']),
        listNotification: getValueFromObjectByKeys(state, ['User', 'notification'])
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: (id) => {
            return dispatch(UserAction.getUserProfile(id))
        },
        getVendorProfile: (id) => {
            return dispatch(UserAction.getVendorProfile(id))
        },
        getVendor: (query) => {
            return dispatch(UserAction.getVendor(query))
        },
        updateProfile: (params, id, isFormData) => {
            return dispatch(UserAction.updateProfile(params, id, isFormData))
        },
        updateVendor: (params, id) => {
            return dispatch(UserAction.updateVendor(params, id))
        },
        sendSMS: (params, id) => {
            return dispatch(UserAction.sendSMS(params))
        },
        sendSMSUser: (params, id) => {
            return dispatch(UserAction.sendSMSUser(params))
        },
        verifyPhoneNumber: (params, id) => {
            return dispatch(UserAction.updateVendor(params, id))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(AccountSettings))
