import { useMutation, useQuery } from '@tanstack/react-query';
import { profileAPIService } from '../services/profileAPIService';

export const useGetProfile = (onError = () => {}, enabled = true) => {
  const { data, isLoading, error, refetch, isFetched } = useQuery(
    ['useGetProfile'],
    () => profileAPIService.getProfile(),
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: true,
      keepPreviousData: false,
      retry: 1,
      onError: (e) => {
        onError();
      }
    }
  );

  return {
    profile: data,
    isFetched,
    isLoadingProfile: isLoading,
    refetch,
    error
  };
};
