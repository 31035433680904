import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import mastercard from "../../assets/mastercard.png";
import visa from "../../assets/Visa.png";
import addcard from "../../assets/addcard.png";
import deleteIcon from "../../assets/delete.png";
import { translate } from '../../../helper';

function Card(props) {
    const {
        cardData,
        className,
        selected,
        type,
        navigate,
        selectCard,
        mode
    } = props
    let defaultClass = cardData?.selected ? 'payment-default': ''
    // 
    return (
        <div onClick={()=>mode==="add"?navigate():selectCard(type, type==="business_card"? cardData?.id: cardData?.djstripe_id)} className={'payment-card'+ ' ' + className + ' ' + defaultClass}>
            {
                cardData ? (
                    <>
                        <div
                            style={{
                                width: "100%",
                                height: "220px",
                                background:type==="business_card"?  "linear-gradient(180deg, #706262 -55%, #000000 153.33%)": "linear-gradient(222.6deg, #168DFB 0%, #161FFB 100%)",
                                boxShadow: "0px 4px 20px 0px #02114517",
                                borderRadius: "20px",
                                marginBottom: "20px",
                                display: "flex",
                                flexDirection: "column",
                                padding: "20px",
                                border: cardData?.selected?"4px solid #FBC916": "none"
                            }}
                        >
                            {
                                type==="business_card" ? <>
                                    <div
                                        style={{
                                            height: "33%",
                                            // background: "#000",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            color: "#fff"
                                        }}
                                    >
                                        {translate('companyAccount')}
                                    </div>
                                    <div
                                        style={{
                                            height: "33%",
                                            // background: "#000",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            flexDirection: "column",
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            color: "#fff"
                                        }}
                                    >
                                        <div style={{color: "#fff", fontSize: "12px", fontWeight: "400"}}>{translate('companyAccount')}</div>
                                        <div style={{color: "#fff", fontSize: "16px", fontWeight: "700", textTransform: "capitalize"}}>
                                            {
                                                cardData?.business_title
                                            }
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            height: "33%",
                                            // background: "#000",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            flexDirection: "column",
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            color: "#fff"
                                        }}
                                    >
                                        <div style={{color: "#fff", fontSize: "12px", fontWeight: "400"}}>{translate('accountNumber')}</div>
                                        <div style={{color: "#fff", fontSize: "16px", fontWeight: "700", textTransform: "capitalize"}}>
                                            {
                                                cardData?.business_account_number
                                            }
                                        </div>
                                    </div>
                                </>: <>
                                <div
                                    style={{
                                        height: "50%",
                                        // background: "#000",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        // alignItems: "flex-end"
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "25%",
                                            // background: "#000",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            // alignItems: "flex-end"
                                        }}
                                    >
                                        <div>
                                            <CheckCircleIcon 
                                                style={{
                                                    fontSize: "24px",
                                                    color: "#FBC916",
                                                    display: cardData?.is_default?'block':'none'
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <img 
                                                style={{
                                                    width: "60px"
                                                }}
                                                src={cardData?.card?.brand==="visa"?visa:mastercard}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            height: "25%",
                                            // background: "#000",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "flex-end"
                                        }}
                                    >
                                        {
                                            new Array(3).fill(null).map(i=>(
                                                <div>
                                                    {
                                                        new Array(4).fill(null).map(e=>(<span style={{margin:"2px", fontSize: "24px", color: "#fff"}}>{cardData?.id?'*':'_'}</span>))
                                                    }
                                                </div>
                                            ))
                                        }
                                        <div>
                                            {
                                                cardData?.card?.last4.split('').map(e=>(<span style={{margin:"2px", fontSize: "24px", color: "#fff"}}>{e}</span>))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        height: "50%",
                                        // background: "#000",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "flex-end"
                                    }}
                                >
                                    <div style={{width: "50%", display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                        <div style={{color: "#fff", fontSize: "12px", fontWeight: "400"}}>{translate('cardHolderName')}</div>
                                        <div style={{color: "#fff", fontSize: "16px", fontWeight: "700", textTransform: "capitalize"}}>
                                            {
                                                cardData?.id?cardData?.billing_details?.name:new Array(8).fill(null).map(e=>(<span style={{margin:"2px", color: "#fff"}}>_</span>))
                                            }
                                        </div>
                                    </div>
                                    <div style={{width: "50%", display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                        <div style={{color: "#fff", fontSize: "12px", fontWeight: "400"}}>{translate('expiryDate')}</div>
                                        <div style={{color: "#fff", fontSize: "16px", fontWeight: "700"}}>
                                            {
                                                cardData?.id?cardData?.card?.exp_month+"/"+cardData?.card?.exp_year?.toString()?.slice(-2):new Array(4).fill(null).map(e=>(<span style={{margin:"2px", color: "#fff"}}>_</span>))
                                            }
                                        </div>
                                    </div>
                                </div>
                                </>
                            }
                            
                        </div>
                    </>
                ) : (
                    <>
                        <AddCircleIcon style={{color: "#fff", fontSize: "32px"}} className='add-card-icon' />
                        <div className='add-new-card'>
                            {
                                type==='business-card'?<>{translate('addNew')} <br/> {translate('businessAccount')}</>:<>{translate('addNewCard')}</>
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default Card