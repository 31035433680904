import React, { useRef, useState } from "react"
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from '@mui/material/Modal';
import ImageEditor from "../../common/ImageCropModal";

import { translate } from '../../helper';
import lock from "../../assets/image/svg/lock_person.svg";
import ThemeDesign from '../ThemeDesign';
import Switch from '../../common/CustomRadioButton'
import LocalStorage from '../../defined/localStorage';
import { useSelector } from "react-redux";

const brand = JSON.parse(LocalStorage.getItem('branding'))

function SubHeader(props) {
    const {
        label,
        button,
        hyperlinkText,
        onClick
    } = props
    const whiteLabel = useSelector((state) => state.User?.branding);
    return (
        <div
            style={{
                display: "inline-grid",
                alignItems: "center",
                gridTemplateColumns: "auto 1fr auto",
                width: "100%"
            }}
        >
            {label && <span
                style={{
                    fontSize: "18px",
                    width: "fit-content",
                    lineHeight: "27px",
                    fontWeight: "600",
                    padding: "10px 20px 10px 0",
                    // minWidth: "170px"
                }}
            >
                {translate(label)}
            </span>}
            <div style={{ height: "1px", backgroundColor: "rgba(229, 231, 235, 1)" }} />
            {button && <button
                style={{
                    borderRadius: "8px",
                    padding: "10px 20px",
                    marginLeft: "20px",
                    border: "none",
                    background: `${whiteLabel?.web_button_color || brand?.web_button_color || 'rgba(248, 178, 24, 1)'}`,
                    boxShadow: "0px 4px 4px 0px rgba(185, 131, 9, 0.09)",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#fff",
                    // minWwidth: "180px",
                    textTransform: "uppercase",
                    width: "fit-content",
                }}
                onClick={() => onClick && onClick()}
            >
                {translate(button)}
            </button>}
            {hyperlinkText && <button
                style={{
                    borderRadius: "8px",
                    padding: "10px 20px",
                    marginLeft: "20px",
                    border: "none",
                    background: "#fff",
                    // boxShadow: "0px 4px 4px 0px rgba(185, 131, 9, 0.09)",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "rgba(248, 178, 24, 1)",
                    // minWwidth: "180px",
                    textTransform: "capitalize",
                    width: "fit-content",
                }}
            >
                {translate(hyperlinkText)}
            </button>}
        </div>
    )
}

function ScreenDesign({
    onSubmit,
    screenNames,
    setScreenNames,
    screenFiles,
    setScreenFiles,
    topBannerColor,
    backgroundColor,
    buttonColor,
    logo,
    setTopBannerColor,
    setBackgroundColor,
    setButtonColor,
    setLogo,
    onCancel,
    active,
    setActive,
    logoError,
    setLogoError,
    loading,
    send,
    onPreview
}) {
    const whiteLabel = useSelector((state) => state.User?.branding);
    const { user = {} } = useSelector((state) => state.User?.userInfo);
    const [error, setError] = useState({
        screen1: '',
        screen2: '',
        screen3: '',
    });
    const [isEditorOpen1, setIsEditorOpen1] = useState(false);
    const [isEditorOpen2, setIsEditorOpen2] = useState(false);
    const [isEditorOpen3, setIsEditorOpen3] = useState(false);

    const [screenImage1, setScreenImage1] = useState(null);
    const [screenImage2, setScreenImage2] = useState(null);
    const [screenImage3, setScreenImage3] = useState(null);

    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);

    const handleScreenName = ({ target }) => {
        const { name, value } = target
        setScreenNames({
            ...screenNames,
            [name]: value
        })
    }

    const onFileUpload1 = (e, name) => {
        setError({
            ...error,
            [name]: ''
        })
        const file = e.target.files[0];
        // 
        // const { name } = e.target.files[0]
        const re = /(\.jpg|\.jpeg|\.gif|\.png)$/i;
        if (!re.exec(e.target.files[0].name)) {
            setError({
                ...error,
                [name]: 'File extension not supported!'
            })
        } else {
            setScreenImage1(file);
            setIsEditorOpen1(true);
        }
    }

    const onFileUpload2 = (e, name) => {
        setError({
            ...error,
            [name]: ''
        })
        const file = e.target.files[0];
        const re = /(\.jpg|\.jpeg|\.gif|\.png)$/i;
        if (!re.exec(e.target.files[0].name)) {
            setError({
                ...error,
                [name]: 'File extension not supported!'
            })
        } else {
            setScreenImage2(file);
            setIsEditorOpen2(true);
        }
    }

    const onFileUpload3 = (e, name) => {
        setError({
            ...error,
            [name]: ''
        })
        const file = e.target.files[0];
        const re = /(\.jpg|\.jpeg|\.gif|\.png)$/i;
        if (!re.exec(e.target.files[0].name)) {
            setError({
                ...error,
                [name]: 'File extension not supported!'
            })
        } else {
            setScreenImage3(file);
            setIsEditorOpen3(true);
        }
    }


    function imageUrl(url) {
        if (url?.type) {
            return URL.createObjectURL(url)
        }
        return url
    }

    const handleCloseEditor1 = () => {
        setIsEditorOpen1(false)
        let input = document.getElementById('myFile1');
        if (input) {
            input.value = ''
        }
        fileInputRef1.current.value = '';

        setScreenImage1(null);
    }

    const handleCloseEditor2 = () => {
        setIsEditorOpen2(false)
        let input = document.getElementById('myFile2');
        if (input) {
            input.value = ''
        }
        fileInputRef2.current.value = '';

        setScreenImage2(null);
    }

    const handleCloseEditor3 = () => {
        setIsEditorOpen3(false)
        let input = document.getElementById('myFile3');
        if (input) {
            input.value = ''
        }
        fileInputRef3.current.value = '';

        setScreenImage3(null);
    }

    const handleCroppedImage1 = (img) => {
        let arr = img.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        let file = new File([u8arr], screenImage1.name, { type: mime });
        setScreenFiles({
            ...screenFiles,
            screen1: file
        })
        handleCloseEditor1();
    }

    const handleCroppedImage2 = (img) => {
        let arr = img.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        let file = new File([u8arr], screenImage2.name, { type: mime });
        setScreenFiles({
            ...screenFiles,
            screen2: file
        })
        handleCloseEditor2();
    }

    const handleCroppedImage3 = (img) => {
        let arr = img.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        let file = new File([u8arr], screenImage3.name, { type: mime });
        setScreenFiles({
            ...screenFiles,
            screen3: file
        })
        handleCloseEditor3();
    }

    return (
        <div style={{ width: "100%" }}>
            <SubHeader
                label="welcomeScreens"
                button="sendSetupLink"
                onClick={send}
            />
            <div
                style={{
                    width: "100%",
                    margin: "20px 0",
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 0",
                    borderBottom: "1px solid #E5E7EB"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        padding: "10px 0",
                        alignItems: "center"
                    }}
                >
                    <span
                        style={{
                            width: "30%",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "16px"
                        }}
                    >
                        {translate('screen1')} - {translate('maxText')}
                    </span>
                    <input
                        style={{
                            width: "70%",
                            fontWeight: "400",
                            fontSize: "14px !important",
                            border: "1px solid #DADADA",
                            borderRadius: "4px !important",
                            padding: "7px 10px",
                            lineHeight: "16px"
                        }}
                        name="screen1"
                        value={screenNames.screen1}
                        onChange={handleScreenName}
                    />
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        padding: "10px 0",
                        // alignItems: "center"
                    }}
                >
                    <span
                        style={{
                            width: "30%",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "16px"
                        }}
                    >
                        {translate('screen1')} - {translate('maxImage')}
                    </span>
                    <div
                        style={{
                            width: "70%",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                width: "300px",
                                height: "120px",
                                borderRadius: "4px",
                                border: "1px solid #DADADA",
                                margin: "10px 0",
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            {screenFiles?.screen1 && <img
                                src={imageUrl(screenFiles.screen1)}
                                alt="logo"
                                style={{ width: "120px" }}
                                accept="image/png, image/jpg, image/gif"
                            />}
                        </div>
                        {error?.screen1?.length > 0 && <p
                            style={{
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "21px",
                                color: "#f00",
                                margin: "10px 0"
                            }}
                        >
                            {error?.screen1}
                        </p>}
                        <div
                            style={{
                                borderRadius: "4px",
                                background: `${whiteLabel?.web_button_color || brand?.web_button_color || 'rgba(248, 178, 24, 1)'}`,
                                border: "1px solid rgba(255, 255, 255, 0.52)",
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "#fff",
                                textTransform: "capitalize",
                                textAlign: "center",
                                width: "142px",
                                cursor: "pointer",
                                position: "relative",
                                marginTop: "10px",
                                padding: "5px"
                            }}
                        >
                            {translate('upload')}
                            <input ref={fileInputRef1} onChange={(e) => onFileUpload1(e, 'screen1')} style={{ position: "absolute", left: 0, width: "100%", opacity: 0, padding: 0, cursor: "pointer" }} type="file" id="myFile" name="filename"></input>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: "100%",
                    margin: "20px 0",
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 0",
                    borderBottom: "1px solid #E5E7EB"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        padding: "10px 0",
                        alignItems: "center"
                    }}
                >
                    <span
                        style={{
                            width: "30%",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "16px"
                        }}
                    >
                        {translate('screen2')} - {translate('maxText')}
                    </span>
                    <input
                        style={{
                            width: "70%",
                            fontWeight: "400",
                            fontSize: "14px !important",
                            border: "1px solid #DADADA",
                            borderRadius: "4px !important",
                            padding: "7px 10px",
                            lineHeight: "16px"
                        }}
                        name="screen2"
                        value={screenNames.screen2}
                        onChange={handleScreenName}
                    />
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        padding: "10px 0",
                        // alignItems: "center"
                    }}
                >
                    <span
                        style={{
                            width: "30%",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "16px"
                        }}
                    >
                        {translate('screen2')} - {translate('maxImage')}
                    </span>
                    <div
                        style={{
                            width: "70%",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                width: "300px",
                                height: "120px",
                                borderRadius: "4px",
                                border: "1px solid #DADADA",
                                margin: "10px 0",
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            {screenFiles?.screen2 && <img
                                src={imageUrl(screenFiles.screen2)}
                                alt="logo"
                                style={{ width: "120px" }}
                                accept="image/png, image/jpg, image/gif"
                            />}
                        </div>
                        {error?.screen2?.length > 0 && <p
                            style={{
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "21px",
                                color: "#f00",
                                margin: "10px 0"
                            }}
                        >
                            {error?.screen2}
                        </p>}
                        <div
                            style={{
                                borderRadius: "4px",
                                background: `${whiteLabel?.web_button_color || brand?.web_button_color || 'rgba(248, 178, 24, 1)'}`,
                                border: "1px solid rgba(255, 255, 255, 0.52)",
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "#fff",
                                textTransform: "capitalize",
                                textAlign: "center",
                                width: "142px",
                                cursor: "pointer",
                                position: "relative",
                                marginTop: "10px",
                                padding: "5px"
                            }}
                        >
                            {translate('upload')}
                            <input ref={fileInputRef2} onChange={(e) => onFileUpload2(e, 'screen2')} style={{ position: "absolute", left: 0, width: "100%", opacity: 0, padding: 0, cursor: "pointer" }} type="file" id="myFile2" name="filename"></input>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: "100%",
                    margin: "20px 0",
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 0",
                    borderBottom: "1px solid #E5E7EB"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        padding: "10px 0",
                        alignItems: "center"
                    }}
                >
                    <span
                        style={{
                            width: "30%",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "16px"
                        }}
                    >
                        {translate('screen3')} - {translate('maxText')}
                    </span>
                    <input
                        style={{
                            width: "70%",
                            fontWeight: "400",
                            fontSize: "14px !important",
                            border: "1px solid #DADADA",
                            borderRadius: "4px !important",
                            padding: "7px 10px",
                            lineHeight: "16px"
                        }}
                        name="screen3"
                        value={screenNames.screen3}
                        onChange={handleScreenName}
                    />
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        padding: "10px 0",
                        // alignItems: "center"
                    }}
                >
                    <span
                        style={{
                            width: "30%",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "16px"
                        }}
                    >
                        {translate('screen3')} - {translate('maxImage')}
                    </span>
                    <div
                        style={{
                            width: "70%",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                width: "300px",
                                height: "120px",
                                borderRadius: "4px",
                                border: "1px solid #DADADA",
                                margin: "10px 0",
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            {screenFiles?.screen3 && <img
                                src={imageUrl(screenFiles.screen3)}
                                alt="logo"
                                style={{ width: "120px" }}
                                accept="image/png, image/jpg, image/gif"
                            />}
                        </div>
                        {error?.screen3?.length > 0 && <p
                            style={{
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "21px",
                                color: "#f00",
                                margin: "10px 0"
                            }}
                        >
                            {error?.screen3}
                        </p>}
                        <div
                            style={{
                                borderRadius: "4px",
                                background: `${whiteLabel?.web_button_color || brand?.web_button_color || 'rgba(248, 178, 24, 1)'}`,
                                border: "1px solid rgba(255, 255, 255, 0.52)",
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "#fff",
                                textTransform: "capitalize",
                                textAlign: "center",
                                width: "142px",
                                cursor: "pointer",
                                position: "relative",
                                marginTop: "10px",
                                padding: "5px"
                            }}
                        >
                            {translate('upload')}
                            <input ref={fileInputRef3} onChange={(e) => onFileUpload3(e, 'screen3')} style={{ position: "absolute", left: 0, width: "100%", opacity: 0, padding: 0, cursor: "pointer" }} type="file" id="myFile3" name="filename"></input>
                        </div>
                    </div>
                </div>
            </div>
            <ThemeDesign
                header={false}
                topBanner={true}
                // background={true} 
                button={true}
                onSubmit={onSubmit}
                onPreview={onPreview}
                topBannerColor={topBannerColor}
                backgroundColor={backgroundColor}
                buttonColor={buttonColor}
                logo={logo}
                setTopBannerColor={setTopBannerColor}
                setBackgroundColor={setBackgroundColor}
                setButtonColor={setButtonColor}
                setLogo={setLogo}
                onCancel={onCancel}
                logoError={logoError}
                setLogoError={setLogoError}
                loading={loading}
            />
            <Modal open={isEditorOpen1} onClose={handleCloseEditor1}>
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ width: "60%", minHeight: "400px", position: "relative", background: "#000", padding: "10px" }}>
                        <ImageEditor img={screenImage1} setCroppedImage={handleCroppedImage1} handleCloseEditor={handleCloseEditor1} />
                    </div>
                </div>
            </Modal>

            <Modal open={isEditorOpen2} onClose={handleCloseEditor2}>
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ width: "60%", minHeight: "400px", position: "relative", background: "#000", padding: "10px" }}>
                        <ImageEditor img={screenImage2} handleCloseEditor={handleCloseEditor2} setCroppedImage={handleCroppedImage2} />
                    </div>
                </div>
            </Modal>

            <Modal open={isEditorOpen3} onClose={handleCloseEditor3}>
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ width: "60%", minHeight: "400px", position: "relative", background: "#000", padding: "10px" }}>
                        <ImageEditor img={screenImage3} setCroppedImage={handleCroppedImage3} handleCloseEditor={handleCloseEditor3}  />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ScreenDesign