import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    data: {},
    error: '',
};
const orderDetailSlice = createSlice({
    name: 'orderDetail',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successOrderDetailReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
        },
        failedOrderDetailReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successOrderDetailReducer, failedOrderDetailReducer, loaderListener } = orderDetailSlice.actions;

export default orderDetailSlice.reducer;