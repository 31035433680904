import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, styled } from "@mui/material";
import { Close as CloseIcon } from '@mui/icons-material';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Row } from "antd";

const BootstrapDialog = styled(Dialog)(({ }) => ({

}))

const ImageZoom = ({ imageSrc, altSrc, fullScreen, children }) => {
    
    const [showModal, setShowModal] = React.useState(false);

    const onClose = () => {
        setShowModal(false);
    }

    const imageUrl = (url) => {
        if (url?.type) {
            return URL.createObjectURL(url)
        }
        return url
    }

    return (
        <>
            <Box onClick={(evt) => {
                evt.stopPropagation();
                if (imageSrc) {
                    setShowModal(true);
                }
            }}>
                {children}
            </Box>

            <BootstrapDialog fullScreen={fullScreen} fullWidth open={showModal} onClose={onClose}>
                <DialogTitle>
                    {/* {title} */}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: '#000',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Zoom>
                        {imageSrc && (
                            <Row justify="center">
                                <TransformWrapper>
                                    <TransformComponent>
                                        <img
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                maxHeight: '480px',
                                            }}
                                            // onLoad={this.onImgLoad}
                                            src={imageUrl(imageSrc)}
                                            alt={altSrc}
                                        />
                                    </TransformComponent>
                                </TransformWrapper>
                            </Row>
                        )}
                    </Zoom>
                </DialogContent>
            </BootstrapDialog>

        </>
    )

}

export default ImageZoom;