import { Modal } from 'antd';
import React from 'react';
import { Colors } from '../../themes/Colors';
import ButtonGradient from '../button-gradient';
import TextBase from '../text';
import { convertPxToRem } from '../../helper';
import ErrorIcon from '../../assets/image/svg/error.svg';
import WarningIcon from '../../assets/image/svg/warning.svg';
import CheckboxIcon from '../../assets/image/svg/checkbox-white.svg';
import { SVGImageComponentReact } from '../../themes/Images';
import LocalStorage from '../../defined/localStorage';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

const brand = JSON.parse(LocalStorage.getItem('branding'))

export const ConfirmModal = ({
  visible = false,
  isLoading = false,
  handleOk = () => {},
  handleCancel = () => {},
  status = 'danger' || 'warning' || 'success',
  cancelBtnText = 'CANCEL',
  okBtnText = 'OK',
  children,
  showIcon = false,
  width = '550px',
  hideIcon = false,
  showButtons = true,
  isLoadingLeft = false,
  message = '',
  onClose=()=>{}
}) => {
  const whiteLabel = useSelector((state) => state.User?.branding);
  return (
    <Modal visible={visible} footer={<div />} onClose={onClose} centered closeIcon={<CloseIcon onClick={onClose} />} style={{}} width={width}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px'
        }}>
        {hideIcon ? null : (
          <img
            src={
              status === 'danger' ? ErrorIcon : status === 'warning' ? WarningIcon : CheckboxIcon
            }
            style={{ height: '100px', width: '100px' }}
          />
        )}
      </div>
      {message?.length ? (
        <>
          <TextBase
            style={{
              color: status === 'danger' ? Colors.red : Colors.black,
              textAlign: 'center',
              marginBottom: '20px'
            }}
            fontWeight={700}
            size={convertPxToRem(18)}
            color={Colors.titlePage}>
            {message}
          </TextBase>
        </>
      ) : (
        <>{children}</>
      )}
      {showButtons && (
        <div style={{ display: 'flex' }}>
          <ButtonGradient
            onClick={handleCancel}
            textStyle={{
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '25px',
              margin: 0,
              color: Colors.grey
            }}
            title={cancelBtnText}
            iconSize={24}
            backgroundColor={Colors.white}
            style={{
              marginRight: '10px',
              // minWidth: '300px',
              padding: '0px 10px',
              border: `1px solid ${Colors.grey}`,
              color: Colors.grey,
              textTransform: 'uppercase'
            }}
            loading={isLoadingLeft}
          />
          <ButtonGradient
            onClick={handleOk}
            textStyle={{ fontWeight: 700, fontSize: '18px', lineHeight: '20px', margin: 0 }}
            iconStyle={{}}
            icon={showIcon ? SVGImageComponentReact.LockIcon : null}
            title={okBtnText}
            iconSize={24}
            backgroundColor={
              status === 'danger'
                ? Colors.red
                : status === 'warning'
                ? (whiteLabel?.web_button_color||brand?.web_button_color||Colors.primary)
                : status === 'success'
                ? Colors.success
                : Colors.black
            }
            style={{
              marginLeft: '10px',
              textTransform: 'uppercase'
              // minWidth: '220px',
              // padding: '0px 10px'
            }}
            loading={isLoading}
          />
        </div>
      )}
    </Modal>
  );
};
