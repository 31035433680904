import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import WarningIcon from '../../assets/image/svg/warning.svg';
import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import { convertPxToRem, translate } from '../../helper';
import ButtonGradient from '../../common/button-gradient';
import React from 'react';
import LocalStorage from '../../defined/localStorage';
import CloseIcon from '@mui/icons-material/Close';

const brand = JSON.parse(LocalStorage.getItem('branding'));

const LogoutModal = ({
                         visible,
                         width = 350,
                         handleCancel,
                         handleOk
                     }) => {
    const whiteLabel = useSelector((state) => state.User?.branding);
    return (
        <Modal visible={visible} footer={<div />} centered closeIcon={<CloseIcon onClick={handleCancel} />} style={{}} width={width}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px'
                }}>
                <img
                    src={
                        WarningIcon
                    }
                    style={{ height: '100px', width: '100px' }}
                />
            </div>
            <TextBase
                style={{
                    color: Colors.black,
                    textAlign: 'center',
                    marginBottom: '20px'
                }}
                fontWeight={400}
                size={convertPxToRem(18)}
                color={Colors.titlePage}>
                {'logoutConfirmation'}
            </TextBase>
            <TextBase
                style={{
                    color: Colors.black,
                    textAlign: 'center',
                    marginBottom: '20px'
                }}
                fontWeight={700}
                size={convertPxToRem(18)}
                color={Colors.titlePage}>
                {'sureConfirmation'}
            </TextBase>
            <div style={{ display: 'flex' }}>
                <ButtonGradient
                    onClick={handleCancel}
                    textStyle={{
                        fontWeight: 700,
                        fontSize: '18px',
                        lineHeight: '25px',
                        margin: 0,
                        color: Colors.grey
                    }}
                    title={translate('cancel')}
                    iconSize={24}
                    backgroundColor={Colors.white}
                    style={{
                        marginRight: '10px',
                        padding: '0px 10px',
                        border: `1px solid ${Colors.grey}`,
                        color: Colors.grey,
                        textTransform: 'uppercase'
                    }}
                />
                <ButtonGradient
                    onClick={handleOk}
                    textStyle={{ fontWeight: 700, fontSize: '18px', lineHeight: '20px', margin: 0 }}
                    iconStyle={{}}
                    title={translate('ok')}
                    iconSize={24}
                    backgroundColor={whiteLabel?.web_button_color || brand?.web_button_color || Colors.black}
                    style={{
                        marginLeft: '10px',
                        textTransform: 'uppercase'
                    }}
                />
            </div>
        </Modal>
    );
};

export default LogoutModal;