import axios from 'axios'

const domain = window.location.hostname != 'localhost' ? window.location.origin : 'https://www.shoredealsnow.com'
export const API_URL = domain + '/'
export const apiVersion = 'api/v1/'

const apiErrorCode = {
    unauthorized: 401,
    accessDenied: 430,
    sessionExpired: 423,
    validationError: 400,
    emailNotVerified: 403,
    internalError: 500
}
const apiSuccessCode = {
    success: 200
}

const $axios = axios.create({
    baseURL: API_URL + apiVersion,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        platform: 3,
        timezone: 0,
        language: 'en',
        offset: 0,
        api_key: 1234
        // authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
})

$axios.interceptors.request.use(
    (config) => {
        const token = (localStorage.getItem('accessToken') != null)
            ? localStorage.getItem('accessToken')
            : sessionStorage.getItem('accessToken')
        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`
        } else {
            config.headers.Authorization = 'Basic c2xpY2U6c2xpY2VAMTIz'
        }
        return config
    },
    async (error) => {
        return await Promise.reject(error)
    }
)

const constants = {
    apiErrorCode,
    API_URL,
    apiVersion,
    apiSuccessCode,
    axios: $axios,
    pageLimit: 15
}

export default constants
