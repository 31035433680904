import React from 'react';
import Order from '../../modules/order';

const OrderWrapper = () => {

  return (
    <Order/>
  );

}

export default OrderWrapper;