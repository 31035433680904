import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

const withRouter = (WrappedComponent) => (props) => {
  let [searchParams] = useSearchParams();
  const token = searchParams.get('key');
  const { id } = useParams();

  return <WrappedComponent {...props} token={token} id={id} />;
};

export default withRouter;
