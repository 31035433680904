import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    data: {},
    error: '',
};
const teamDetailSlice = createSlice({
    name: 'teamDetail',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successTeamDetailReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
        },
        failedTeamDetailReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successTeamDetailReducer, failedTeamDetailReducer, loaderListener } = teamDetailSlice.actions;

export default teamDetailSlice.reducer;