import React from 'react';
import OrderDetail from '../../modules/order-detail';

const OrderDetailWrapper = () => {

  return (
    <OrderDetail/>
  );

}

export default OrderDetailWrapper;