import { css, StyleSheet } from 'aphrodite';
import { forwardRef } from 'react';

const styleComp = StyleSheet.create({
    rightElement: {
        position: 'absolute',
        right: '1rem',
        height: '100%',
        top: '7px',
        //   paddingTop: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        width: '1.5rem',
        cursor: 'pointer'
    },
    rightElementNoPadding: {
        position: 'absolute',
        right: '0',
        height: '100%',
        top: 0,
        paddingTop: '0.5rem',
        display: 'flex',
        alignItems: 'center'
    }
});


const TextInput = forwardRef(function TextInput(props, compRef) {
    const {
        style,
        outerStyle = {},
        labelStyle,
        rows,
        label = '',
        placeholder,
        className = '',
        rightElement,
        name = '',
        value = '',
        onChange = () => {
        },
        error = '',
        required = false,
        margin = false,
        type,
        disabled,
        onFocus = () => {
        },
        onBlur = () => {
        }
    } = props;

    // 

    let inputStyle = {
        marginTop: label && label.length > 0 ? '15px' : '0px'
    };
    let rightElemStyle = {
        top: label && label.length === 0 ? '0px !important' : '7px !important'
    };
    return (
        <>
            {label && label.length > 0 && <div
                style={{ ...labelStyle }}
                className="text-input"
            >
                {label}{required && '*'}
            </div>}
            {
                rows && rows > 1 ? (
                    <textarea
                        placeholder={placeholder}
                        onChange={(e) => {
                            onChange && onChange(e.target.value, e.target.name);
                        }}
                        value={value}
                        className={'input-area ' + className}
                        style={{ ...inputStyle, ...style, color: '#000', height: 'unset' }}
                        autoComplete="off"
                        rows={Number(rows)}
                        name={name}
                        disabled={disabled}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        ref={compRef ? compRef : null}
                    />
                ) : (
                    <div className={className} style={{ position: 'relative', ...outerStyle }}>
                        <input
                            placeholder={placeholder}
                            onChange={(e) => {
                                onChange && onChange(e.target.value, e.target.name);
                            }}
                            value={value}
                            className={'input-area'}
                            style={{ ...inputStyle, ...style, color: '#000' }}
                            autoComplete="off"
                            rows={Number(rows)}
                            name={name}
                            type={type}
                            disabled={disabled}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            ref={compRef ? compRef : null}
                        />
                        {rightElement ? (<div
                            style={{ ...rightElemStyle, display: 'flex' }}
                            className={css(
                                styleComp.rightElement
                            )}
                        >
                            {rightElement}
                        </div>) : null}
                    </div>
                )
            }
            {
                error && <p className="text-input-error">{error}</p>
            }
        </>
    );
});

export default TextInput;