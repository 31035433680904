export const ROUTER_KEY = {
  assets: 'assets',
  investment: 'investment',
  insurance: 'insurance',
  // realEstate: "real-estate",
  // liabilities: "liabilities",
  creditCard: 'credit-card',
  personalNotes: 'personal-notes',
  loans: 'loans',
  other: 'other',
  incomeExpenses: 'income-expenses',
  payStubs: 'pay-stubs',
  taxReturn: 'tax-returns',
  expenses: 'expenses',
  riskTolerance: 'risk-tolerance',
  productBias: 'product-bias',
  introduction: 'introduction',
  scheduleMeeting: 'schedule-meeting',
  documentUpload: 'document-upload',
  photoUpload: 'photo-upload',
  contactUs: 'contact-us',
  meeting: 'meeting',
  profile: 'profile',
  notification: 'notification',
  vendorNotesNotification: 'vendor-notes-notification',
  user: 'user',
  dashboard: 'dashboard',
  order: 'order',
  orderDetail: 'order-detail',
  analytics: 'analytics',
  subscription: 'subscription',
  addPaymentMethod: 'addPaymentMethod',
  payment: 'payment',
  connectStripe: 'connect-stripe',
  quotes: 'quotes',
  settings: 'settings',
  logout: 'logout',
  createProfile: 'create-profile',
  teamProfile: 'team-profile',
  team: 'team',
  whiteLabel: 'white-label',
  notes: 'notes',
  notesDetail: 'notes-detail',
  childAccounts: 'child-accounts',
  verifyChildAccounts: 'child-account-create',
};
export const ROUTER_NAME = {
  createProfile: '/' + ROUTER_KEY.createProfile,
  home: '/home',
  settings: '/' + ROUTER_KEY.settings,
  analytics: '/' + ROUTER_KEY.analytics,
  order: '/' + ROUTER_KEY.order,
  notes: '/' + ROUTER_KEY.notes,
  notesDetail: '/' + ROUTER_KEY.notesDetail,
  childAccounts: '/' + ROUTER_KEY.childAccounts,
  verifyChildAccounts: '/' + ROUTER_KEY.verifyChildAccounts,
  quotes: '/' + ROUTER_KEY.quotes,
  orderDetail: '/' + ROUTER_KEY.orderDetail,
  login: '/login',
  signUp: '/sign-up',
  signUpWhiteLabelPartner: '/sign-up-white-label-partner',
  signUpStepTwo: '/sign-up-step-two',
  emailVerified: '/email-verified',
  register: '/register',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  accountSetup: '/account-setup',
  dashboard: '/' + ROUTER_KEY.dashboard,
  landingPage: '/landing-page',
  privacy: '/privacy',
  privacyPrivate: '/privacy-policy',
  term: '/terms',
  termsPrivate: '/terms-and-conditions',
  userPlans: '/user-plans',
  connectStripe: '/connect-stripe',
  subscription: '/subscription',
  payment: 'subscription/payment',
  addPaymentMethod: 'subscription/payment/add-card',
  editPaymentMethod: 'subscription/payment/edit-card',
  dataUpload: '/' + ROUTER_KEY.dataUpload,
  profile: '/' + ROUTER_KEY.profile,
  notification: '/' + ROUTER_KEY.notification,
  vendorNotesNotification: '/' + ROUTER_KEY.vendorNotesNotification,
  contactUs: '/' + ROUTER_KEY.contactUs,
  contactUsPrivate: '/' + ROUTER_KEY.user + '/' + ROUTER_KEY.contactUs,
  meeting: '/' + ROUTER_KEY.meeting,
  team: '/team',
  teamProfile: '/' + ROUTER_KEY.teamProfile,
  whiteLabel: '/white-label'
};
