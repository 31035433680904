import React, { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import APIManager from '../../../connection/ApiManager';

const Scanner = (props) => {
    const { handleScannerResponse, setScan, handleClose } = props;
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const captureAndScan = useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();

        const img = new Image();
        img.src = imageSrc;

        img.onload = async () => {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            // Set canvas size to match the image
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Define the crop area dimensions
            const cropWidth = img.width - 40;
            const cropHeight = img.height * 0.10;

            // Calculate the starting point (center the crop area)
            const cropX = 20;
            const cropY = 0.46 * img.height;

            // Get the cropped image data
            const croppedCanvas = document.createElement('canvas');
            const croppedCtx = croppedCanvas.getContext('2d');
            croppedCanvas.width = cropWidth;
            croppedCanvas.height = cropHeight;
            croppedCtx.drawImage(canvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
            // setCroppedImage(croppedCanvas.toDataURL());
            const byteString = atob(croppedCanvas.toDataURL().split(',')[1]);
            const mimeString = croppedCanvas.toDataURL().split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], { type: mimeString });
            const formData = new FormData();
            formData.append('image', blob, 'image.jpg');

            try {
                const res = await APIManager.getInstance().ocrScanner(formData);
                handleScannerResponse(res);
            } catch (error) {
                console.error('Error sending image to backend:', error);
            } finally {
                setScan(false);
            }
        };
    }, [webcamRef]);

    const handleUserMedia = () => {
        setIsLoading(true);
    };

    return (
        <div style={{ position: 'relative', width: 'auto' }}>
            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{
                    width: '100%',
                    maxHeight: '100%'
                }}
                videoConstraints={{
                    facingMode: 'environment'
                }}
                onUserMedia={handleUserMedia}
            />
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
            {isLoading && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            color: 'white',
                            top: 0,
                            right: '15px',
                            fontSize: '1.5rem'
                        }}
                        onClick={handleClose}>
                        x
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: 2,
                            top: '45%',
                            bottom: '45%',
                            left: '20px',
                            right: '20px',
                            border: '2px solid yellow'
                        }}></div>
                    <button
                        style={{
                            position: 'absolute',
                            left: '40%',
                            bottom: '20%',
                            zIndex: 9,
                            borderRadius: '4px',
                            height: '40px',
                            width: '80px',
                            fontSize: '15px'
                        }}
                        onClick={captureAndScan}>
                        Insert
                    </button>
                </>
            )}
        </div>
    );
};

export default Scanner;
